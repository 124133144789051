import React, { useEffect, useState } from 'react';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Typography,
  Button,
  Grid,
  Divider,
  ImageList,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio
} from '@mui/material';
import AttachmentCard from '../../components/AttachmentCard/AttachmentCard';
import AuditService from '../../services/AuditService';
import TaskService from '../../services/TaskService';
import ExtraScoresService from '../../services/ExtraScoresService';
import RulesAndLicensesService from '../../services/RulesAndLicensesService';

const types = {
  T: 'Plano de ação',
  E: 'Pontuação extra',
  R: 'Norma/Licença'
};

const DialogInvalidateTask = ({
  isOpen,
  setClosedDialog = () => {},
  task,
  type,
  handleRefresh,
  setLoading,
  setError
}) => {
  const [reasonsList, setReasonsList] = useState([]);
  const [selectedReason, setSelectedReason] = useState(undefined);

  useEffect(() => {
    handleRefreshReasonList();
  }, []);

  useEffect(() => {
    setSelectedReason(undefined);
  }, [isOpen]);

  const handleRefreshReasonList = () => {
    setLoading(true);

    AuditService.ListReasons(type)
      .then((resp) => {
        const reasons = resp.data;
        setReasonsList(reasons);
      })
      .catch((err) => {
        setError('Não foi possível listar os motivos de invalidação');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!selectedReason) {
      setError('Selecione um motivo para invalidar a tarefa');
      return;
    }

    setLoading(true);

    if (type === 'T') {
      TaskService.InvalidateTask(task.id, selectedReason)
        .then((resp) => {
          setError(`Tarefa invalidada com sucesso`, 'success');
          setClosedDialog();
          handleRefresh();
        })
        .catch((err) => {
          setError('Não foi possível invalidar a tarefa');
        })
        .finally(() => {
          setLoading(false);
        });
    }

    if (type === 'E') {
      ExtraScoresService.AdvisorInvalidateExtraScore(task.id, selectedReason)
        .then((resp) => {
          setError(`Pontuação extra invalidada com sucesso`, 'success');
          setClosedDialog();
          handleRefresh();
        })
        .catch((err) => {
          setError('Não foi possível invalidar a pontuação extra');
        })
        .finally(() => {
          setLoading(false);
        });
    }

    if (type === 'R') {
      RulesAndLicensesService.Invalidate(task.id, selectedReason)
        .then((resp) => {
          setError(`Norma/licença invalidada com sucesso`, 'success');
          setClosedDialog();
          handleRefresh();
        })
        .catch((err) => {
          setError('Não foi possível invalidar a norma/licença');
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <Dialog
      open={isOpen}
      maxWidth="md"
      fullWidth={true}
      onClose={setClosedDialog}
      PaperProps={{
        className: `dialog-default dialog-audit-invalidatetask`
      }}
    >
      <Box component="form" onSubmit={(e) => handleSubmit(e)}>
        <DialogTitle className="dialogtitle-default" sx={{ paddingTop: 4 }}>
          <Typography variant="subtitle1" className="fontweight-600 textalign-center">
            Invalidar {task ? types[type] : ''}
          </Typography>
        </DialogTitle>

        <DialogContent className="dialogcontent-default">
          <Grid container paddingX={2}>
            <Grid item xs={12}>
              <Typography variant="body1" component="span">
                <strong> Descritivo: </strong>
                {task ? task.title : ''}
              </Typography>
            </Grid>

            <Grid item xs={12} marginTop={4}>
              <ImageList variant="quilted" cols={3} rowHeight={121}>
                {(task ? task.attachments : []).map((arquivo, index) => {
                  return <AttachmentCard url={arquivo} name={arquivo} key={index} />;
                })}
              </ImageList>
            </Grid>

            <Grid item xs={12} marginTop={4}>
              <Typography variant="body1" className="fontweight-600">
                Selecione um motivo para a invalidação:
              </Typography>

              <FormControl>
                <RadioGroup
                  row
                  name="motivoInvalidacao"
                  value={selectedReason}
                  onChange={(e) => setSelectedReason(e.target.value)}
                >
                  {reasonsList.map((option, index) => {
                    return (
                      <FormControlLabel
                        value={option.id}
                        key={index}
                        control={<Radio size="small" />}
                        label={option.description}
                      />
                    );
                  })}
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} paddingTop={3}>
              <Divider />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions
          className="dialogactions-default"
          sx={{
            paddingX: 4,
            paddingBottom: 4,
            justifyContent: 'space-between'
          }}
        >
          <Button
            type="button"
            className={`borderradius-default background-grey-3 color-black`}
            size="small"
            onClick={setClosedDialog}
          >
            Fechar
          </Button>

          <Button
            type="submit"
            className="background-red color-white borderradius-default"
            size="small"
          >
            Invalidar
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default DialogInvalidateTask;
