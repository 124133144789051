import React from 'react';
import { Card, CardBody, CardHeader, Row } from 'reactstrap';
import BarChart from '../BarChart';

export default function ConservacaoPinturaInternaChart({ data }) {
  const { progress, chart } = data;

  return (
    <Card className="shadow borderradius-default">
      <CardHeader className="bg-transparent">
        <Row className="align-items-center">
          <div className="col">
            <h6 className="text-uppercase text-light ls-1 mb-1"> PDV </h6>
            <h2 className="mb-0">Conservação da pintura interna</h2>
          </div>
        </Row>
      </CardHeader>

      <CardBody className="p-0 pb-3 pt-3">
        <BarChart data={chart} />
      </CardBody>
    </Card>
  );
}
