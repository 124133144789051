import React from 'react';
import { Card, CardBody, CardHeader, Row } from 'reactstrap';
import PercentageChart from '../../../../../components/Statistics/PercentageChart';

export default function WhatsappChart({ data }) {
  const { progress, chart } = data;

  return (
    <Card className="shadow borderradius-default">
      <CardHeader className="bg-transparent">
        <Row className="align-items-center">
          <div className="col">
            <h6 className="text-uppercase text-light ls-1 mb-1"> Atendimento </h6>
            <h2 className="mb-0">WhatsApp</h2>
          </div>
        </Row>
      </CardHeader>

      <CardBody className="p-0 pb-3">
        <PercentageChart data={progress && progress.whatsappBusiness} title="WhatsApp Business" />

        <PercentageChart data={progress && progress.chatbot} title="Chatbot" />
      </CardBody>
    </Card>
  );
}
