import './percentageChart.scss';

import React from 'react';
import { Box, Typography } from '@mui/material';

const PercentageChart = ({
  containerProps = {
    className: ''
  },
  data = {
    percentage: 0,
    value: 0
  },
  title
}) => {
  const percentage = data.percentage || 0;
  const value = data.value || 0;

  return (
    <Box className={`percentagechart-container ${containerProps.className || ''}`}>
      <Box className="percentage-box">
        <Typography variant="subtitle1" className="percentage-text">
          {percentage}
        </Typography>
        <Typography variant="subtitle2" className="percentage-simbol">
          %
        </Typography>
      </Box>

      <Box className="title-box">
        <Typography variant="body1" className="title-text">
          {title}

          <Typography variant="body1" className="subtitle-text">
            {value}
          </Typography>
        </Typography>

        <Box className="chart-box">
          <Box className="progress-bar" sx={{ width: `${percentage}%` }} />
        </Box>
      </Box>
    </Box>
  );
};

export default PercentageChart;
