import React, { useEffect, useState } from 'react';
import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from 'reactstrap';
import StoreService from '../../services/StoreService';

const StoresModalUnsubscribe = ({
  store,
  isOpen,
  handleClose,
  handleRefresh,
  setLoading,
  setError,
  token
}) => {
  const [categoria, setCategoria] = useState(undefined);

  useEffect(() => {
    if (store) {
      setCategoria(store.categories[0].id);
    }
  }, [store]);

  const handleUnsubscribeAction = () => {
    setLoading(true);

    StoreService.Unsubscribe(token, store.code, categoria)
      .then((resp) => {
        setError('Inscrição da loja cancelada com sucesso', 'success');
        handleClose();
        handleRefresh();
      })
      .catch((err) => {
        setError(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <Modal isOpen={isOpen} toggle={handleClose}>
        <ModalHeader>
          <h3>Cancelar Inscrição</h3>
        </ModalHeader>

        <ModalBody className="pt-0">
          <Row>
            <Col className="col-12">
              <span> Deseja realmente cancelar a inscrição da loja selecionada? </span>
            </Col>

            <Col className="col-12 mt-4">
              <FormGroup>
                <Label for="category">Categoria:</Label>
                <Input
                  type="select"
                  id="category"
                  name="category"
                  required={true}
                  value={categoria}
                  onChange={(e) => setCategoria(e.target.value)}
                >
                  {store &&
                    store.categories.map((category, index) => {
                      return (
                        <option value={category.id} key={index}>
                          {' '}
                          {category.descr_pt}{' '}
                        </option>
                      );
                    })}
                </Input>
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>

        <ModalFooter
          style={{
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <Button className="background-primary color-white" onClick={handleClose}>
            Não
          </Button>

          <Button className="background-secondary color-white" onClick={handleUnsubscribeAction}>
            Sim
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default StoresModalUnsubscribe;
