import React, { useEffect, useState } from 'react';
import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from 'reactstrap';
import axios from 'axios';
import baseURL from '../../variables/api';
import FormHelper from '../../helpers/FormHelper';

const ExtraScoresModalAddEdit = ({
  extraScore,
  categories,
  storeCategories,
  isOpen,
  handleClose,
  handleRefresh,
  setLoading,
  setError
}) => {
  const [titulo, setTitulo] = useState('');
  const [categoria, setCategoria] = useState(0);
  const [descricao, setDescricao] = useState('');
  const [tiposLoja, setTiposLoja] = useState([]);
  const [pontos, setPontos] = useState('');
  const [validade, setValidade] = useState('');
  const [arquivoExemplo, setArquivoExemplo] = useState(undefined);
  const [linkArquivoExemploAtual, setLinkArquivoExemploAtual] = useState('');

  useEffect(() => {
    if (isOpen) {
      setTitulo(extraScore ? extraScore.title : '');
      setCategoria(extraScore ? extraScore.extra_scores_category_id : 0);
      setDescricao(extraScore ? extraScore.description : '');
      setTiposLoja(extraScore ? extraScore.storeCategories.map((store) => store.id) : []);
      setPontos(extraScore ? extraScore.points : '');
      setValidade(extraScore ? new Date(extraScore.validity).toISOString().slice(0, 10) : '');
      setArquivoExemplo(undefined);
      setLinkArquivoExemploAtual(extraScore ? extraScore.image : '');
    }
  }, [isOpen]);

  const handleAddEditAction = (e) => {
    e.preventDefault();

    let formData = FormHelper.GenerateFormData(e, null);
    formData = FormHelper.IncludeKeyValue(formData, `storeCategories`, JSON.stringify(tiposLoja));
    formData = FormHelper.IncludeKeyValue(formData, `image`, linkArquivoExemploAtual);

    setLoading(true);

    let method = 'post';
    let url = `${baseURL}/extra-scores`;

    if (extraScore) {
      method = 'put';
      url = `${baseURL}/extra-scores/${extraScore.id}`;
    }

    axios[method](url, formData, {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('banana')
      }
    })
      .then(() => {
        setError(
          extraScore ? 'Alteração realizada com sucesso' : 'Inclusão realizada com sucesso',
          'success'
        );
        handleClose();
        handleRefresh();
      })
      .catch((err) => {
        setError('Ocorreu um erro ao tentar realizar a operação');
        console.log(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <Modal isOpen={isOpen} toggle={handleClose}>
        <form onSubmit={(e) => handleAddEditAction(e)}>
          <ModalHeader>
            <h3>{extraScore ? 'Editar' : 'Adicionar'}</h3>
          </ModalHeader>

          <ModalBody className="pt-0">
            <Row>
              <Col className="col-md-6 col-12">
                <FormGroup>
                  <Label for="title">Título:</Label>
                  <Input
                    type="text"
                    id="title"
                    name="title"
                    required
                    value={titulo}
                    onChange={(e) => setTitulo(e.target.value)}
                  />
                </FormGroup>
              </Col>

              <Col className="col-md-6 col-12">
                <FormGroup>
                  <Label for="category">Categoria:</Label>
                  <Input
                    type="select"
                    id="category"
                    name="category"
                    value={categoria}
                    onChange={(e) => setCategoria(e.target.value)}>
                    {categories.map((category, index) => {
                      return (
                        <option value={category.id} key={index}>
                          {' '}
                          {category.name}{' '}
                        </option>
                      );
                    })}
                  </Input>
                </FormGroup>
              </Col>

              <Col className="col-12">
                <FormGroup>
                  <Label for="description">Descrição:</Label>
                  <Input
                    type="textarea"
                    id="description"
                    name="description"
                    required
                    value={descricao}
                    onChange={(e) => setDescricao(e.target.value)}
                    style={{ resize: 'none', height: '300px' }}
                  />
                </FormGroup>
              </Col>

              <Col className="col-12">
                <FormGroup>
                  <Label for="storeCategories">Tipos de Loja:</Label>
                  <Input
                    type="select"
                    id="storeCategories"
                    multiple
                    required
                    value={tiposLoja}
                    onChange={(e) =>
                      setTiposLoja(Array.from(e.target.selectedOptions, (option) => option.value))
                    }>
                    {storeCategories.map((category) => {
                      return (
                        <option value={category.id} key={category.id}>
                          {category.descr_pt}
                        </option>
                      );
                    })}
                  </Input>
                </FormGroup>
              </Col>

              <Col className="col-md-6 col-12">
                <FormGroup>
                  <Label for="points">Pontos:</Label>
                  <Input
                    type="number"
                    id="points"
                    name="points"
                    value={pontos}
                    min={0}
                    step={1}
                    required
                    onChange={(e) => setPontos(e.target.value)}
                  />
                </FormGroup>
              </Col>

              <Col className="col-md-6 col-12">
                <FormGroup>
                  <Label for="validity">Validade:</Label>
                  <Input
                    type="date"
                    id="validity"
                    name="validity"
                    value={validade}
                    required
                    onChange={(e) => setValidade(e.target.value)}
                  />
                </FormGroup>
              </Col>

              <Col className="col-12">
                <FormGroup>
                  <Label for="upload">Arquivo de exemplo:</Label>
                  <Input
                    type="file"
                    id="upload"
                    name="upload"
                    label={(arquivoExemplo && arquivoExemplo.name) || 'Escolha um arquivo'}
                    onChange={(e) => setArquivoExemplo(e.target.files[0])}
                  />

                  {linkArquivoExemploAtual.length > 0 && (
                    <Label
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        width: '100%',
                        height: '32px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        fontSize: '12px',
                        marginTop: '8px',
                        position: 'relative'
                      }}>
                      Atual:
                      <a
                        href={linkArquivoExemploAtual}
                        target="_blank"
                        className="ml-1"
                        rel="noreferrer">
                        {linkArquivoExemploAtual.split('/').pop()}
                      </a>
                      <Button
                        type="button"
                        color="primary"
                        style={{
                          position: 'absolute',
                          right: 0,
                          top: '2px',
                          height: '28px',
                          padding: '0px 8px',
                          fontSize: '10px'
                        }}
                        onClick={() => setLinkArquivoExemploAtual('')}>
                        <i className="fas fa-trash" />
                      </Button>
                    </Label>
                  )}
                </FormGroup>
              </Col>
            </Row>
          </ModalBody>

          <ModalFooter
            style={{
              display: 'flex',
              justifyContent: 'space-between'
            }}>
            <Button className="background-primary color-white" onClick={handleClose}>
              Voltar
            </Button>

            <Button type="submit" className="background-secondary color-white">
              Salvar
            </Button>
          </ModalFooter>
        </form>
      </Modal>
    </>
  );
};

export default ExtraScoresModalAddEdit;
