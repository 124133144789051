import './style.scss';

import React, { useContext, useEffect, useState } from 'react';
import { Container, Alert } from 'reactstrap';

import Filters from '../../../components/Filters/Filters';
import Loader from '../../../components/Bridgestone/Loader.jsx';

import { StoreContext } from '../../../components/Contexts/StoreContext';
import { UserContext } from '../../../components/Contexts/UserContext';
import { Box, Button, Grid, IconButton, Menu, Typography } from '@mui/material';
import { Check, Close, KeyboardArrowDown, Restore } from '@mui/icons-material';
import TasksTable from './TasksTable';
import DialogInvalidateTask from './DialogInvalidateTask';
import DialogValidateTask from './DialogValidateTask';
import DefaultTextField from '../../../components/TextField/TextField';
import { taskAuditStatusList } from '../../../data/Lists';
import StoreService from '../../../services/StoreService';
import AuditService from '../../../services/AuditService';
import StringHelper from '../../../helpers/StringHelper';
import FullscreenPreview from './FullscreenPreview';
import moment from 'moment';

const limitRows = 20;

export default function Audit() {
  const { token } = useContext(UserContext);
  const { selectedCategory } = useContext(StoreContext);

  const statusFilterList = taskAuditStatusList;
  const [storeFilterList, setStoreFilterList] = useState([]);
  const [taskTypeFilterList, setTaskTypeFilterList] = useState([]);
  const [taskFilterList, setTaskFilterList] = useState([]);

  const [statusFilter, setStatusFilter] = useState(undefined);
  const [storeFilter, setStoreFilter] = useState(undefined);
  const [taskTypeFilter, setTaskTypeFilter] = useState(undefined);
  const [taskFilter, setTaskFilter] = useState(undefined);

  const [selectedFilter, setSelectedFilter] = useState(undefined);
  const [filterSearch, setFilterSearch] = useState('');

  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  const [selectedStateTask, setSelectedStateTask] = useState(0);
  const [taskList, setTaskList] = useState([]);
  const [selectedTask, setSelectedTask] = useState(undefined);

  const [fullscreenPreviewOpened, setFullscreenPreviewOpened] = useState(false);
  const [dialogValidateOpened, setDialogValidateOpened] = useState(false);
  const [dialogInvalidateOpened, setDialogInvalidateOpened] = useState(false);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    handleRefresh();
  }, [currentPage, statusFilter, storeFilter, taskTypeFilter, taskFilter]);

  useEffect(() => {
    if (currentPage !== 1) {
      setCurrentPage(1);
    } else {
      handleRefresh();
    }

    handleRefreshFilters();
  }, [selectedCategory]);

  useEffect(() => {
    if (currentPage !== 1) {
      setCurrentPage(1);
    } else {
      handleRefresh();
    }

    setStatusFilter(undefined);
  }, [selectedStateTask]);

  useEffect(() => {
    if (taskTypeFilter) {
      handleRefreshTaskFilter();
    } else {
      setTaskFilter(undefined);
      setTaskFilterList([]);
    }
  }, [taskTypeFilter]);

  const handleRefreshFilters = () => {
    setLoading(true);

    setStatusFilter(undefined);
    setStoreFilter(undefined);
    setTaskTypeFilter(undefined);

    Promise.all([
      StoreService.List(token, { category: selectedCategory }),
      AuditService.ListTaskTypes()
    ])
      .then((resp) => {
        const stores = resp[0].data;
        const taskTypes = resp[1].data;

        setStoreFilterList(stores);
        setTaskTypeFilterList(taskTypes);
      })
      .catch((err) => {
        console.error(err);
        handleError('Não foi possível realizar a busca dos filtros');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleRefreshTaskFilter = () => {
    setLoading(true);

    setTaskFilter(undefined);

    AuditService.ListTasksByType(taskTypeFilter.id, selectedCategory)
      .then((resp) => {
        const tasks = resp.data;
        setTaskFilterList(tasks);
      })
      .catch((err) => {
        console.error(err);
        handleError('Não foi possível realizar a busca dos filtros');
        setTaskFilterList([]);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleAction = (task, setter) => {
    setSelectedTask(task);
    setter(true);
  };

  const handleRefresh = () => {
    setLoading(true);

    const params = {};
    params.category = selectedCategory;
    params.page = currentPage;
    params.limit = limitRows;

    if (statusFilter) {
      params.auditStatus = statusFilter.id;
    }

    if (storeFilter) {
      params.client = storeFilter.id;
    }

    if (taskTypeFilter) {
      params.type = taskTypeFilter.id;
    }

    if (taskFilter) {
      params.task = taskFilter.id;
    }

    if (selectedStateTask === 2) {
      params.audited = 'Y';
    } else {
      params.audited = 'N';
      params.validated = selectedStateTask === 0 ? 'N' : 'Y';
    }

    AuditService.ListTasks(params)
      .then((resp) => {
        const tasks = resp.data.items;
        setTaskList(tasks);

        const pages = resp.data.pages;
        setTotalPages(pages);
      })
      .catch((err) => {
        console.error(err);
        handleError('Não foi possível realizar a busca das tarefas');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleError = (message, color = 'danger') => {
    setError({ message, color });

    setTimeout(() => {
      setError(undefined);
    }, 4000);
  };

  const handleChangeSelectedFilter = (target, data, value, setter) => {
    setSelectedFilter({
      anchorEl: target,
      data,
      value,
      setter
    });

    setFilterSearch('');
  };

  const createFiltersRow = () => {
    return (
      <tr>
        {selectedStateTask === 2 && (
          <th scope="col">
            <span
              className="color-primary cursor-pointer"
              onClick={(e) =>
                handleChangeSelectedFilter(
                  e.currentTarget,
                  statusFilterList,
                  statusFilter,
                  setStatusFilter
                )
              }
            >
              {statusFilter
                ? statusFilter.title.length > 10
                  ? `${statusFilter.title.slice(0, 10)}...`
                  : statusFilter.title
                : 'Todos'}
            </span>

            <IconButton
              className="background-primary color-white ml-2"
              size="small"
              onClick={(e) =>
                handleChangeSelectedFilter(
                  e.currentTarget,
                  statusFilterList,
                  statusFilter,
                  setStatusFilter
                )
              }
            >
              <KeyboardArrowDown fontSize="small" />
            </IconButton>
          </th>
        )}

        <th scope="col">
          <span
            className="color-primary cursor-pointer"
            onClick={(e) =>
              handleChangeSelectedFilter(
                e.currentTarget,
                storeFilterList,
                storeFilter,
                setStoreFilter
              )
            }
          >
            {storeFilter
              ? storeFilter.name.length > 10
                ? `${storeFilter.name.slice(0, 10)}...`
                : storeFilter.name
              : 'Todos'}
          </span>

          <IconButton
            className="background-primary color-white ml-2"
            size="small"
            onClick={(e) =>
              handleChangeSelectedFilter(
                e.currentTarget,
                storeFilterList,
                storeFilter,
                setStoreFilter
              )
            }
          >
            <KeyboardArrowDown fontSize="small" />
          </IconButton>
        </th>

        <th scope="col">
          <span
            className="color-primary cursor-pointer"
            onClick={(e) =>
              handleChangeSelectedFilter(
                e.currentTarget,
                taskTypeFilterList,
                taskTypeFilter,
                setTaskTypeFilter
              )
            }
          >
            {taskTypeFilter
              ? taskTypeFilter.title.length > 10
                ? `${taskTypeFilter.title.slice(0, 10)}...`
                : taskTypeFilter.title
              : 'Todos'}
          </span>

          <IconButton
            className="background-primary color-white ml-2"
            size="small"
            onClick={(e) =>
              handleChangeSelectedFilter(
                e.currentTarget,
                taskTypeFilterList,
                taskTypeFilter,
                setTaskTypeFilter
              )
            }
          >
            <KeyboardArrowDown fontSize="small" />
          </IconButton>
        </th>

        {taskTypeFilter ? (
          <th scope="col">
            <span
              className="color-primary cursor-pointer"
              onClick={(e) =>
                handleChangeSelectedFilter(
                  e.currentTarget,
                  taskFilterList,
                  taskFilter,
                  setTaskFilter
                )
              }
            >
              {taskFilter
                ? taskFilter.title.length > 20
                  ? `${taskFilter.title.slice(0, 20)}...`
                  : taskFilter.title
                : 'Todos'}
            </span>

            <IconButton
              className="background-primary color-white ml-2"
              size="small"
              onClick={(e) =>
                handleChangeSelectedFilter(
                  e.currentTarget,
                  taskFilterList,
                  taskFilter,
                  setTaskFilter
                )
              }
            >
              <KeyboardArrowDown fontSize="small" />
            </IconButton>
          </th>
        ) : (
          <th scope="col"> </th>
        )}

        <th scole="col"> </th>
        <th scole="col"> </th>
        <th scole="col"> </th>
      </tr>
    );
  };

  return (
    <>
      {loading && <Loader />}

      {error && (
        <Alert color={error.color} style={{ position: 'fixed', top: '30px', zIndex: 9999 }}>
          {error.message}
        </Alert>
      )}

      {/* Page content */}
      <Container fluid>
        <Filters />

        <Grid container className="categories-container">
          <Grid item lg={6} xs={12} className="box-buttons">
            <Button
              className={`category-button ${selectedStateTask === 0 ? 'selected' : ''}`}
              onClick={() => setSelectedStateTask(0)}
            >
              A VALIDAR
            </Button>

            <Button
              className={`category-button ${selectedStateTask === 1 ? 'selected' : ''}`}
              onClick={() => setSelectedStateTask(1)}
            >
              VALIDADOS
            </Button>

            <Button
              className={`category-button ${selectedStateTask === 2 ? 'selected' : ''}`}
              onClick={() => setSelectedStateTask(2)}
            >
              AUDITADOS
            </Button>
          </Grid>
        </Grid>

        {selectedStateTask === 0 && (
          <TasksTable
            tasks={taskList}
            title={'Tarefas a validar'}
            actionsColumn={(task) => {
              const daysDiff = moment().diff(task.submitted_at, 'days');

              if (daysDiff < 15) {
                return;
              }

              return (
                <>
                  <IconButton
                    size="small"
                    className="background-green color-white"
                    onClick={() => handleAction(task, setDialogValidateOpened)}
                  >
                    <Check fontSize="inherit" />
                  </IconButton>

                  <IconButton
                    size="small"
                    className="background-red color-white ml-2"
                    onClick={() => handleAction(task, setDialogInvalidateOpened)}
                  >
                    <Close fontSize="inherit" />
                  </IconButton>
                </>
              );
            }}
            filtersRow={createFiltersRow()}
            showStatusColumn={false}
            totalPages={totalPages}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            handleFullscreenPreview={(task) => handleAction(task, setFullscreenPreviewOpened)}
          />
        )}

        {selectedStateTask === 1 && (
          <TasksTable
            tasks={taskList}
            title={'Tarefas validadas'}
            actionsColumn={(task) => {
              return (
                <>
                  <IconButton
                    size="small"
                    className="background-green color-white"
                    onClick={() => handleAction(task, setDialogValidateOpened)}
                  >
                    <Check fontSize="inherit" />
                  </IconButton>

                  <IconButton
                    size="small"
                    className="background-red color-white ml-2"
                    onClick={() => handleAction(task, setDialogInvalidateOpened)}
                  >
                    <Close fontSize="inherit" />
                  </IconButton>
                </>
              );
            }}
            filtersRow={createFiltersRow()}
            showStatusColumn={false}
            totalPages={totalPages}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            handleFullscreenPreview={(task) => handleAction(task, setFullscreenPreviewOpened)}
          />
        )}

        {selectedStateTask === 2 && (
          <TasksTable
            tasks={taskList}
            title={'Tarefas auditadas'}
            actionsColumn={(task) => {
              return (
                <>
                  {task.status === 'R' ? (
                    <IconButton
                      size="small"
                      className="background-yellow color-white"
                      onClick={() => handleAction(task, setDialogValidateOpened)}
                    >
                      <Restore fontSize="inherit" />
                    </IconButton>
                  ) : (
                    <IconButton
                      size="small"
                      className="background-red color-white"
                      onClick={() => handleAction(task, setDialogInvalidateOpened)}
                    >
                      <Close fontSize="inherit" />
                    </IconButton>
                  )}
                </>
              );
            }}
            filtersRow={createFiltersRow()}
            showStatusColumn={true}
            totalPages={totalPages}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            handleFullscreenPreview={(task) => handleAction(task, setFullscreenPreviewOpened)}
          />
        )}
      </Container>

      {selectedFilter && (
        <Menu
          anchorEl={selectedFilter.anchorEl}
          open={true}
          onClose={(_) => setSelectedFilter(undefined)}
          PaperProps={{
            className: 'background-primary mt-2',
            sx: {
              paddingY: 1,
              borderRadius: 4,
              width: 320,
              maxHeight: 500
            }
          }}
        >
          <Box paddingX={2} paddingTop={1} paddingBottom={3}>
            <DefaultTextField
              label="Pesquisar"
              value={filterSearch}
              onChange={(e) => setFilterSearch(e.target.value)}
              onKeyDown={(e) => e.stopPropagation()}
              required={false}
              margin="none"
              className="white-theme"
            />
          </Box>

          {(!filterSearch || filterSearch.length === 0) && (
            <Typography
              onClick={() => {
                selectedFilter.setter(undefined);
                setSelectedFilter(undefined);
                setFilterSearch('');
              }}
              style={{
                backgroundColor: selectedFilter.value === undefined ? 'rgba(0, 0, 0, 0.1)' : ''
              }}
              paddingX={2}
              paddingY={1}
              variant="subtitle2"
              className="color-white cursor-pointer"
            >
              Todos
            </Typography>
          )}

          {selectedFilter.data
            .filter((row) => {
              let searchIn = row.title ? row.title : row.code + ' ' + row.comercial_name;
              return (
                !filterSearch ||
                filterSearch.length === 0 ||
                StringHelper.Normalize(searchIn).indexOf(StringHelper.Normalize(filterSearch)) > -1
              );
            })
            .map((row, index) => {
              return (
                <Typography
                  key={index}
                  onClick={() => {
                    selectedFilter.setter(row);
                    setSelectedFilter(undefined);
                    setFilterSearch('');
                  }}
                  style={{
                    backgroundColor: row === selectedFilter.value ? 'rgba(0, 0, 0, 0.1)' : ''
                  }}
                  paddingX={2}
                  paddingY={1}
                  variant="subtitle2"
                  className="color-white cursor-pointer"
                >
                  {row.title ? row.title : `${row.code} - ${row.comercial_name.toUpperCase()}`}
                </Typography>
              );
            })}
        </Menu>
      )}

      <FullscreenPreview
        isOpen={fullscreenPreviewOpened}
        setClose={() => setFullscreenPreviewOpened(false)}
        files={selectedTask ? selectedTask.attachments : []}
      />

      <DialogValidateTask
        isOpen={dialogValidateOpened}
        setClosedDialog={() => setDialogValidateOpened(false)}
        task={selectedTask}
        handleRefresh={handleRefresh}
        setLoading={setLoading}
        setError={handleError}
      />

      <DialogInvalidateTask
        isOpen={dialogInvalidateOpened}
        setClosedDialog={() => setDialogInvalidateOpened(false)}
        task={selectedTask}
        handleRefresh={handleRefresh}
        setLoading={setLoading}
        setError={handleError}
      />
    </>
  );
}
