import Axios from 'axios';
import BaseUrl from '../variables/api';

const List = async () => {
  return Axios.get(`${BaseUrl}/peb/regions`);
};

const RegionsService = {
  List
};

export default RegionsService;
