import Axios from 'axios';
import BaseUrl from '../variables/api';

const List = async (token, category) => {
  return Axios.get(`${BaseUrl}/peb/videos/${category}`, {
    headers: {
      Authorization: 'Bearer ' + token
    }
  });
};

const VideosService = {
  List
};

export default VideosService;
