import React from 'react';
import { Link } from 'react-router-dom';
import {
  Alert,
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroup,
  Col,
  Container
} from 'reactstrap';

import axios from 'axios';
import baseURL from '../../../variables/api';
import Loader from '../../../components/Bridgestone/Loader';
import excelenciaLogo from '../../../assets/img/brand/excelencia-logo.png';

class RequestRecover extends React.Component {
  state = {
    email: '',
    loading: false
  };

  requestPass = async () => {
    try {
      this.setState({ loading: true });
      await axios.post(baseURL + '/request-reset', {
        email: this.state.email,
        application: 'www.programadeexcelencia.com'
      });

      this.setState({ success: true, loading: false });
    } catch (err) {
      this.setState({
        loading: false,
        error: true,
        errorText: 'E-mail não encontrado'
      });
      setTimeout(() => {
        this.setState({ error: false });
      }, 4000);
    }
  };

  render() {
    return (
      <>
        {this.state.error ? (
          <Alert color="danger" style={{ position: 'fixed', top: '30px', zIndex: 9999 }}>
            {this.state.errorText}
          </Alert>
        ) : (
          ''
        )}
        {this.state.loading && <Loader />}
        <Col lg="5" md="7" sm="12">
          <Container fluid className="">
            <Link
              to={{
                pathname: '/auth'
              }}>
              <Button className="" color="primary" type="button">
                {'< Voltar'}
              </Button>
            </Link>
          </Container>
          <Container fluid className="p-3 text-center">
            <img style={{ maxWidth: '80%' }} src={excelenciaLogo} />
          </Container>
        </Col>
        <Col lg="5" md="7">
          <Card className=" border-0">
            <CardBody className="px-lg-5 py-lg-5">
              <h3>Recuperação de senha</h3>
              <Form role="form">
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <Input
                      placeholder="Digite seu e-mail"
                      type="email"
                      onChange={(e) => {
                        this.setState({ email: e.target.value });
                      }}
                    />
                  </InputGroup>
                </FormGroup>

                <div className="text-center">
                  <Button
                    className="my-4 btn-block login-button"
                    color="primary"
                    type="button"
                    onClick={this.requestPass}
                    disabled={this.state.success}>
                    Solicitar nova senha
                  </Button>
                </div>
              </Form>
              {this.state.success && (
                <div>
                  <p>
                    Recuperação de senha solicitada. Você receberá um link para redefinir a senha no
                    seu e-mail de cadastro
                  </p>
                </div>
              )}
            </CardBody>
          </Card>
        </Col>
      </>
    );
  }
}

export default RequestRecover;
