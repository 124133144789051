import React, { useEffect, useState } from 'react';
import './style.scss';
import Loader from '../../../components/Bridgestone/Loader.jsx';
import { Row, Col, Alert, Container } from 'reactstrap';
import ResultsSections from './ResultsSections';
import ResultsCategories from './ResultsCategories';
import FachadasChart from './Charts/Pdv/FachadasChart';
import ConservacaoExteriorChart from './Charts/Pdv/ConservacaoExteriorChart';
import TotemRevendasChart from './Charts/Pdv/TotemRevendasChart';
import ConservacaoTotemChart from './Charts/Pdv/ConservacaoTotemChart';
import BandeiraChart from './Charts/Pdv/BandeiraChart';
import ConservacaoBandeiraChart from './Charts/Pdv/ConservacaoBandeiraChart';
import PinturaInternaChart from './Charts/Pdv/PinturaInternaChart';
import ConservacaoPinturaInternaChart from './Charts/Pdv/ConservacaoPinturaInternaChart';
import ConservacaoPisoPatioChart from './Charts/Pdv/ConservacaoPisoPatioChart';
import MaquinariosPadraoChart from './Charts/Pdv/MaquinariosPadraoChart';
import ConservacaoMaquinariosChart from './Charts/Pdv/ConservacaoMaquinariosChart';
import BalcaoAtendimentoChart from './Charts/Pdv/BalcaoAtendimentoChart';
import SalaEsperaChart from './Charts/Pdv/SalaEsperaChart';
import ConservacaoBanheirosChart from './Charts/Pdv/ConservacaoBanheirosChart';
import AcessibilidadeChart from './Charts/Pdv/AcessibilidadeChart';
import UniformesChart from './Charts/Pdv/UniformesChart';
import MateriaisMerchandisingChart from './Charts/Pdv/MateriaisMerchandisingChart';
import ArmazenamentoChart from './Charts/Pdv/ArmazenamentoChart';
import EquipeVendasExternaChart from './Charts/Pdv/EquipeVendasExternaChart';
import GoogleMeuNegocioChart from './Charts/Business/GoogleMeuNegocioChart';
import PresencaDigitalChart from './Charts/Business/PresencaDigitalChart';
import WebsiteChart from './Charts/Business/WebsiteChart';
import AnunciosPagosGoDigitalChart from './Charts/Business/AnunciosPagosGoDigitalChart';
import MobileFirstChart from './Charts/Business/MobileFirstChart';
import GestaoClientesChart from './Charts/Atendimento/GestaoClientesChart';
import CanaisAtendimentoOnlineChart from './Charts/Atendimento/CanaisAtendimentoOnlineChart';
import WhatsappChart from './Charts/Atendimento/WhatsappChart';
import ResultsService from '../../../services/ResultsService';

const charts = [
  {
    categories: ['ALL', 'CAR', 'TRUCK'],
    section: 1,
    component: FachadasChart,
    dataKey: 'fronts',
    sizes: {
      xl: 4
    }
  },
  {
    categories: ['ALL', 'CAR', 'TRUCK'],
    section: 1,
    component: ConservacaoExteriorChart,
    dataKey: 'exteriorConservation',
    sizes: {
      xl: 8
    }
  },
  {
    categories: ['ALL', 'CAR', 'TRUCK'],
    section: 1,
    component: TotemRevendasChart,
    dataKey: 'totem',
    sizes: {
      xl: 4
    }
  },
  {
    categories: ['ALL', 'CAR', 'TRUCK'],
    section: 1,
    component: ConservacaoTotemChart,
    dataKey: 'totemConservation',
    sizes: {
      xl: 8
    }
  },
  {
    categories: ['ALL', 'CAR', 'TRUCK'],
    section: 1,
    component: BandeiraChart,
    dataKey: 'flag',
    sizes: {
      xl: 4
    }
  },
  {
    categories: ['ALL', 'CAR', 'TRUCK'],
    section: 1,
    component: ConservacaoBandeiraChart,
    dataKey: 'flagConservation',
    sizes: {
      xl: 8
    }
  },
  {
    categories: ['ALL', 'CAR', 'TRUCK'],
    section: 1,
    component: PinturaInternaChart,
    dataKey: 'internalPainting',
    sizes: {
      xl: 4
    }
  },
  {
    categories: ['ALL', 'CAR', 'TRUCK'],
    section: 1,
    component: ConservacaoPinturaInternaChart,
    dataKey: 'internalPaintingConservation',
    sizes: {
      xl: 8
    }
  },
  {
    categories: ['ALL', 'CAR', 'TRUCK'],
    section: 1,
    component: ConservacaoPisoPatioChart,
    dataKey: 'patioFloorConservation',
    sizes: {
      xl: 4
    }
  },
  {
    categories: ['ALL', 'CAR', 'TRUCK'],
    section: 1,
    component: MaquinariosPadraoChart,
    dataKey: 'standardMachines',
    sizes: {
      xl: 4
    }
  },
  {
    categories: ['ALL', 'CAR', 'TRUCK'],
    section: 1,
    component: ConservacaoMaquinariosChart,
    dataKey: 'machinesConservation',
    sizes: {
      xl: 4
    }
  },
  {
    categories: ['ALL', 'CAR', 'TRUCK'],
    section: 1,
    component: BalcaoAtendimentoChart,
    dataKey: 'serviceDesk',
    sizes: {
      xl: 4
    }
  },
  {
    categories: ['ALL', 'CAR', 'TRUCK'],
    section: 1,
    component: SalaEsperaChart,
    dataKey: 'waitingRoom',
    sizes: {
      xl: 4
    }
  },
  {
    categories: ['ALL', 'CAR', 'TRUCK'],
    section: 1,
    component: ConservacaoBanheirosChart,
    dataKey: 'bathroomsConservation',
    sizes: {
      xl: 4
    }
  },
  {
    categories: ['ALL', 'CAR', 'TRUCK'],
    section: 1,
    component: AcessibilidadeChart,
    dataKey: 'accessibility',
    sizes: {
      xl: 4
    }
  },
  {
    categories: ['ALL', 'CAR', 'TRUCK'],
    section: 1,
    component: UniformesChart,
    dataKey: 'uniforms',
    sizes: {
      xl: 4
    }
  },
  {
    categories: ['ALL', 'CAR', 'TRUCK'],
    section: 1,
    component: MateriaisMerchandisingChart,
    dataKey: 'merchandisingMaterials',
    sizes: {
      xl: 4
    }
  },
  {
    categories: ['ALL', 'CAR', 'TRUCK'],
    section: 1,
    component: ArmazenamentoChart,
    dataKey: 'storage',
    sizes: {
      xl: 4
    }
  },
  {
    categories: ['ALL', 'TRUCK'],
    section: 1,
    component: EquipeVendasExternaChart,
    dataKey: 'outsideSalesTeam',
    sizes: {
      xl: 4
    }
  },
  {
    categories: ['ALL', 'CAR', 'TRUCK'],
    section: 2,
    component: GoogleMeuNegocioChart,
    dataKey: 'googleMyBusiness',
    sizes: {
      xl: 4
    }
  },
  {
    categories: ['ALL', 'CAR', 'TRUCK'],
    section: 2,
    component: PresencaDigitalChart,
    dataKey: 'digitalPresence',
    sizes: {
      xl: 4
    }
  },
  {
    categories: ['ALL', 'CAR', 'TRUCK'],
    section: 2,
    component: WebsiteChart,
    dataKey: 'website',
    sizes: {
      xl: 4
    }
  },
  {
    categories: ['ALL', 'CAR', 'TRUCK'],
    section: 2,
    component: AnunciosPagosGoDigitalChart,
    dataKey: 'goDigitalAds',
    sizes: {
      xl: 4
    }
  },
  {
    categories: ['ALL', 'CAR', 'TRUCK'],
    section: 2,
    component: MobileFirstChart,
    dataKey: 'mobileFirst',
    sizes: {
      xl: 4
    }
  },
  {
    categories: ['ALL', 'CAR', 'TRUCK'],
    section: 3,
    component: GestaoClientesChart,
    dataKey: 'clientManagement',
    sizes: {
      xl: 4
    }
  },
  {
    categories: ['ALL', 'CAR', 'TRUCK'],
    section: 3,
    component: CanaisAtendimentoOnlineChart,
    dataKey: 'onlineServiceChannels',
    sizes: {
      xl: 4
    }
  },
  {
    categories: ['ALL', 'CAR', 'TRUCK'],
    section: 3,
    component: WhatsappChart,
    dataKey: 'whatsapp',
    sizes: {
      xl: 4
    }
  }
];

export default function Results() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(undefined);

  const [category, setCategory] = useState('ALL');
  const [section, setSection] = useState(undefined);

  const [data, setData] = useState(undefined);

  useEffect(() => {
    setLoading(true);

    ResultsService.Get(category, section)
      .then((resp) => {
        setData(resp.data);
      })
      .catch((err) => {
        handleError('danger', 'Ocorreu um erro ao tentar buscar os dados');
        console.warn(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [category, section]);

  const handleError = (color, message) => {
    setError({
      color: color,
      message: message
    });

    setTimeout(() => {
      setError(undefined);
    }, 5000);
  };

  return (
    <>
      {loading && <Loader />}
      {error && (
        <Alert color={error.color} style={{ position: 'fixed', top: '30px', zIndex: 9999 }}>
          {error.message}
        </Alert>
      )}

      <Container fluid className="results-container">
        <ResultsCategories selected={category} handleChange={setCategory} />
        <ResultsSections selected={section} handleChange={setSection} />

        <Row>
          {charts
            .filter((x) => x.section == section && x.categories.includes(category))
            .map((c, key) => {
              return (
                <Col key={key} xl={c.sizes.xl || 4} md={12} sm={12} className="mt-3">
                  <c.component data={(data && data[c.dataKey]) || {}} category={category} />
                </Col>
              );
            })}
        </Row>
      </Container>
    </>
  );
}
