import React from 'react';
import { Link } from 'react-router-dom';
import {
  Alert,
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroup,
  Col,
  Container
} from 'reactstrap';

import axios from 'axios';
import baseURL from '../../../variables/api';
import Loader from '../../../components/Bridgestone/Loader';
import excelenciaLogo from '../../../assets/img/brand/excelencia-logo.png';

class Recover extends React.Component {
  state = {
    token: this.props.match.params.token,
    password: '',
    confirm: null
  };

  resetPassword = async () => {
    try {
      if (this.state.password.length < 4) {
        this.setState({
          loading: false,
          error: true,
          errorText: 'Senha deve ter pelo menos 4 dígitos'
        });

        setTimeout(() => {
          this.setState({ error: false });
        }, 4000);

        return;
      }

      if (this.state.password != this.state.confirm) {
        this.setState({
          loading: false,
          error: true,
          errorText: 'As senhas digitadas não conferem'
        });
        setTimeout(() => {
          this.setState({ error: false });
        }, 4000);
        return;
      }

      this.setState({ loading: true });
      let res = await axios.post(baseURL + '/reset-password', {
        token: this.state.token,
        password: this.state.password
      });

      console.log(res);
      this.setState({ success: true, loading: false });
    } catch (err) {
      this.setState({
        loading: false,
        error: true,
        errorText: 'Token inválido ou expirado'
      });
      setTimeout(() => {
        this.setState({ error: false });
      }, 4000);
    }
  };

  render() {
    return (
      <>
        {this.state.loading && <Loader />}
        {this.state.error ? (
          <Alert color="danger" style={{ position: 'fixed', top: '30px', zIndex: 9999 }}>
            {this.state.errorText}
          </Alert>
        ) : (
          ''
        )}

        <Col lg="5" md="7">
          <Card className=" border-0">
            <CardBody className="px-lg-5 py-lg-5">
              <Container fluid className="p-3 text-center">
                <img style={{ maxWidth: '80%' }} src={excelenciaLogo} />
              </Container>
              <h3>Recuperação de senha</h3>
              <Form role="form">
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <Input
                      placeholder="Digite sua nova senha"
                      type="password"
                      onChange={(e) => this.setState({ password: e.target.value })}
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <Input
                      placeholder="Confirme a senha"
                      type="password"
                      onChange={(e) => this.setState({ confirm: e.target.value })}
                    />
                  </InputGroup>
                </FormGroup>
                <div className="text-center">
                  <Button
                    className="my-4 btn-block login-button"
                    color="primary"
                    type="button"
                    onClick={this.resetPassword}
                    disabled={this.state.success}
                  >
                    Redefinir Senha
                  </Button>
                </div>
              </Form>
              {this.state.success && (
                <div>
                  <h3>Senha redefinida com sucesso.</h3>
                  <Link
                    to={{
                      pathname: '/auth'
                    }}
                  >
                    <Button className="my-4 btn-block login-button" color="primary" type="button">
                      Ir para Login
                    </Button>
                  </Link>
                </div>
              )}
            </CardBody>
          </Card>
        </Col>
      </>
    );
  }
}

export default Recover;
