import React from 'react';
import { Grid } from '@mui/material';
import DefaultTextField from '../../../components/TextField/TextField';
import MaskHelper from '../../../helpers/MaskHelper';

const ReformerData = ({ data, category, token, storeCode, refreshData, setLoading, setError }) => {
  return (
    <Grid container className="background-grey-3" padding={3}>
      <Grid item xs={12} marginBottom={2}>
        <h3 className="text-uppercase mb-0">Dados da revenda</h3>
      </Grid>

      <Grid item lg={6} xs={12} className="paddingright-8px padding-none-lg">
        <Grid container>
          <Grid item xs={12}>
            <DefaultTextField
              label="Código da loja"
              value={data && data.code ? data.code : ''}
              disabled={true}
            />
          </Grid>

          <Grid item xs={12}>
            <DefaultTextField
              label="Código avô"
              value={data && data.antecessor_code ? data.antecessor_code : ''}
              disabled={true}
            />
          </Grid>

          <Grid item xs={12}>
            <DefaultTextField
              label="CNPJ"
              value={data && data.doc_nubmer ? MaskHelper.Cnpj(data.doc_nubmer, false) : ''}
              disabled={true}
            />
          </Grid>

          <Grid item xs={12}>
            <DefaultTextField
              label="Razão social"
              value={data && data.name ? data.name : ''}
              disabled={true}
            />
          </Grid>

          <Grid item xs={12}>
            <DefaultTextField
              label="Nome fantasia"
              value={data && data.comercial_name ? data.comercial_name : ''}
              disabled={true}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item lg={6} xs={12} className="paddingleft-8px padding-none-lg">
        <Grid container>
          <Grid item xs={12}>
            <DefaultTextField
              label="Sua regional"
              value={data && data.region ? data.region : ''}
              disabled={true}
            />
          </Grid>

          <Grid item xs={12}>
            <DefaultTextField
              label="Tipo de loja"
              value={data && data.is_readquarters == 'Y' ? 'Matriz' : 'Filial'}
              disabled={true}
            />
          </Grid>

          <Grid item xs={12}>
            <DefaultTextField
              label="Categoria que concorrerá no programa"
              value={category || ''}
              disabled={true}
            />
          </Grid>

          <Grid item xs={12}>
            <DefaultTextField
              label="Gerente regional responsável"
              value={data && data.regionalManager ? data.regionalManager : ''}
              disabled={true}
            />
          </Grid>

          <Grid item xs={12}>
            <DefaultTextField
              label="Assessor comercial responsável"
              value={data && data.storeAdvisor ? data.storeAdvisor : ''}
              disabled={true}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ReformerData;
