import React from 'react';
import { Card, CardBody, CardHeader, Row } from 'reactstrap';
import PercentageChart from '../../../../../components/Statistics/PercentageChart';
import BarChart from '../BarChart';

export default function BalcaoAtendimentoChart({ data }) {
  const { progress, chart } = data;

  return (
    <Card className="shadow borderradius-default">
      <CardHeader className="bg-transparent">
        <Row className="align-items-center">
          <div className="col">
            <h6 className="text-uppercase text-light ls-1 mb-1"> PDV </h6>
            <h2 className="mb-0">Balcão de atendimento</h2>
          </div>
        </Row>
      </CardHeader>

      <CardBody className="p-0 pb-3">
        <PercentageChart
          data={progress && progress.hasServiceDesk}
          title="Possui balcão de atendimento"
        />

        <PercentageChart
          data={progress && progress.wellMaintained}
          title="Balcão bem conservado"
          containerProps={{
            className: 'with-divisor'
          }}
        />

        <BarChart data={chart} className="mt-4" />
      </CardBody>
    </Card>
  );
}
