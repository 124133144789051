import './satisfaction.scss';

import React, { useContext, useEffect, useState } from 'react';
import { Button, Divider, Grid, Rating, Typography } from '@mui/material';
import { Box } from '@mui/system';
import Loader from '../Bridgestone/Loader';
import { Alert } from 'reactstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import SatisfactionService from '../../services/SatisfactionService';
import { UserContext } from '../Contexts/UserContext';
import { StoreContext } from '../Contexts/StoreContext';
import DialogDownloadSurveys from '../Dialog/DialogDownloadSurveys';

const defaultSatisfaction = {
  average: 0,
  totalResults: 0,
  yourResults: []
};

const Satisfaction = ({
  containerProps = {
    className: 'shadow',
    marginTop: 4
  },
  showHeader = true,
  showAverage = true,
  averageProps = {
    marginTop: 2
  },
  showDetails = true,
  showCopySatisfactionSurveyLink = false,
  showSatisfactionSurveyDownloadAllButton = false,
  showDetailsDivider = false,
  compare
}) => {
  const { token } = useContext(UserContext);
  const { storeCode, selectedCategory, consolidatedRegion } = useContext(StoreContext);

  const [satisfaction, setSatisfaction] = useState(defaultSatisfaction);
  const [showCopyAlert, setShowCopyAlert] = useState(false);
  const [modalDownloadSurveys, setModalDownloadSurveys] = useState(false);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const satisfactionSurveyLink = `${
    window.location.origin
  }/sac-personal/${storeCode}/${selectedCategory.toUpperCase()}`;

  useEffect(() => {
    if (!selectedCategory) {
      return;
    }

    setLoading(true);

    SatisfactionService.Average(
      token,
      storeCode,
      selectedCategory,
      consolidatedRegion ? consolidatedRegion.id : null,
      compare
    )
      .then((resp) => {
        setSatisfaction(resp.data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [storeCode, selectedCategory, compare]);

  useEffect(() => {
    if (!selectedCategory || !consolidatedRegion || !consolidatedRegion.id) {
      return;
    }

    setLoading(true);

    SatisfactionService.Average(
      token,
      storeCode,
      selectedCategory,
      consolidatedRegion ? consolidatedRegion.id : null,
      compare
    )
      .then((resp) => {
        setSatisfaction(resp.data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [consolidatedRegion]);

  const handleError = (message, color = 'danger') => {
    setError({ message, color });

    setTimeout(() => {
      setError(undefined);
    }, 4000);
  };

  return (
    <>
      {loading && <Loader />}

      {error && (
        <Alert color={error.color} style={{ position: 'fixed', top: '30px', zIndex: 9999 }}>
          {error.message}
        </Alert>
      )}

      <Alert
        color="success"
        style={{ position: 'fixed', top: '30px', zIndex: 9999 }}
        isOpen={showCopyAlert}
        toggle={() => setShowCopyAlert(false)}
      >
        Link copiado!
      </Alert>

      <Grid
        container
        {...containerProps}
        className={`satisfaction-container ${containerProps.className || ''}`}
      >
        {showHeader && (
          <Grid item xs={12}>
            <Grid container spacing={2} alignItems="center">
              <Grid item lg={6} xs={12}>
                <h6 className="text-uppercase text-light ls-1 mb-1"> Avaliação do cliente </h6>
                <h2 className="mb-0">Pesquisa de satisfação</h2>
              </Grid>

              {showCopySatisfactionSurveyLink && (
                <Grid item lg={6} xs={12} textAlign="right">
                  <CopyToClipboard text={satisfactionSurveyLink}>
                    <Button
                      className="background-primary color-white borderradius-default fontsize-12"
                      onClick={(_) => setShowCopyAlert(true)}
                    >
                      Copiar link da pesquisa de satisfação
                    </Button>
                  </CopyToClipboard>
                </Grid>
              )}

              {showSatisfactionSurveyDownloadAllButton && (
                <Grid item lg={6} xs={12} textAlign="right">
                  <Button
                    className="background-primary color-white borderradius-default fontsize-12"
                    onClick={() => setModalDownloadSurveys(true)}
                  >
                    Baixar pesquisas
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>
        )}

        {showAverage && (
          <Grid
            item
            xs={12}
            {...averageProps}
            className={`satisfaction-average ${averageProps.className || ''}`}
          >
            <Typography variant="h3" className="text-average">
              {' '}
              {satisfaction.average}{' '}
            </Typography>

            <Rating value={satisfaction.average} precision={0.5} readOnly size="large" />

            <Grid item className="text-description">
              <Typography variant="subtitle2" className="fontweight-600">
                {' '}
                Média geral da sua loja{' '}
              </Typography>
              <Typography variant="caption" component="h6">
                {' '}
                {satisfaction.totalResults} pesquisas efetuadas{' '}
              </Typography>
            </Grid>
          </Grid>
        )}

        {showDetailsDivider && (
          <Grid item xs={12} marginTop={2}>
            <Divider />
          </Grid>
        )}

        {showDetails && (
          <Grid item xs={12} className="satisfaction-details" marginTop={2}>
            <Grid container>
              {satisfaction.yourResults.map((result, index) => {
                return (
                  <Grid item xs={3} className="satisfaction-result textalign-center" key={index}>
                    <Typography variant="caption" component="h6" className="fontweight-600">
                      {result.title}
                    </Typography>

                    <Box marginY={1}>
                      <Typography variant="h4" className="fontweight-600">
                        {result.value}
                      </Typography>
                      <Rating value={result.value} precision={0.5} readOnly size="small" />
                    </Box>

                    <Typography variant="caption" component="h6">
                      <span className="fontweight-600">{result.regionResult}</span> sua regional
                    </Typography>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        )}
      </Grid>

      <DialogDownloadSurveys
        isOpen={modalDownloadSurveys}
        setClosedDialog={(_) => setModalDownloadSurveys(false)}
        token={token}
        setLoading={setLoading}
        setError={handleError}
        selectedCategory={selectedCategory}
        store={false}
      />
    </>
  );
};

export default Satisfaction;
