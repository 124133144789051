import React from 'react';
import { Card, CardBody, CardHeader, Row } from 'reactstrap';
import PercentageChart from '../../../../../components/Statistics/PercentageChart';

export default function AcessibilidadeChart({ data }) {
  const { progress, chart } = data;

  return (
    <Card className="shadow borderradius-default">
      <CardHeader className="bg-transparent">
        <Row className="align-items-center">
          <div className="col">
            <h6 className="text-uppercase text-light ls-1 mb-1"> PDV </h6>
            <h2 className="mb-0">Acessibilidade (rampas de acesso)</h2>
          </div>
        </Row>
      </CardHeader>

      <CardBody className="p-0 pb-3">
        <PercentageChart data={progress && progress.onEntrance} title="Nas entradas da loja" />

        <PercentageChart
          data={progress && progress.onEntranceNotNecessary}
          title="Não necessita"
          containerProps={{
            className: 'with-divisor'
          }}
        />

        <PercentageChart data={progress && progress.internalArea} title="Na área interna da loja" />

        <PercentageChart
          data={progress && progress.internalAreaNotNecessary}
          title="Não necessita"
        />
      </CardBody>
    </Card>
  );
}
