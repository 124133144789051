import React from 'react';
import { Card, CardBody, CardHeader, Row } from 'reactstrap';
import PercentageChart from '../../../../../components/Statistics/PercentageChart';

export default function MateriaisMerchandisingChart({ data, category }) {
  const { progress, chart } = data;

  return (
    <Card className="shadow borderradius-default">
      <CardHeader className="bg-transparent">
        <Row className="align-items-center">
          <div className="col">
            <h6 className="text-uppercase text-light ls-1 mb-1"> PDV </h6>
            <h2 className="mb-0">Materiais de merchandising</h2>
          </div>
        </Row>
      </CardHeader>

      <CardBody className="p-0 pb-3">
        <PercentageChart data={progress && progress.hasDisplays} title="Possui displays" />

        {category != 'BANDAG' && (
          <>
            <PercentageChart
              data={progress && progress.communicationPanels}
              title="Painéis de comunicação"
            />

            <PercentageChart
              data={progress && progress.competitiveProducts}
              title="Produtos/materiais da concorrência"
            />
          </>
        )}
      </CardBody>
    </Card>
  );
}
