import './style.scss';
import React, { useState, useEffect } from 'react';
import { IconButton, Backdrop, Box } from '@mui/material';
import { Close, Download, KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import AttachmentCard from '../../../components/AttachmentCard/AttachmentCard';
import FileHelper from '../../../helpers/FileHelper';
import FileViewer from 'react-file-viewer';

const FullscreenPreview = ({ isOpen, setClose, files = [] }) => {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    setIndex(0);
  }, [files]);

  const handleChangeIndex = (newIndex) => {
    const limit = files.length - 1;

    if (newIndex < 0) {
      newIndex = limit;
    } else if (newIndex > limit) {
      newIndex = 0;
    }

    setIndex(newIndex);
  };

  const file = files[index] || '';

  return (
    <Backdrop open={isOpen} className="backdrop-fullscreenpreview">
      <Box className="box-header">
        <IconButton className="iconbutton-downloadtrigger" onClick={() => window.open(file)}>
          <Download />
        </IconButton>

        <IconButton className="iconbutton-closetrigger" onClick={setClose}>
          <Close />
        </IconButton>
      </Box>

      <Box className="box-content">
        <Box className="box-changeindex">
          <IconButton
            className="iconbutton-changeindex"
            onClick={() => handleChangeIndex(index - 1)}
          >
            <KeyboardArrowLeft fontSize="large" />
          </IconButton>
        </Box>

        <Box className="box-attachment">
          {FileHelper.IsImage(file) ? (
            <AttachmentCard
              url={file}
              name={file}
              className={`attachmentcard-fullscreenpreview attachmentcard-image`}
            />
          ) : FileHelper.IsPdf(file) ? (
            <iframe
              title="attachment-file"
              src={file}
              className="attachmentfile-fullscreenpreview"
            />
          ) : (
            <FileViewer
              fileType={file.split('.').pop()}
              filePath={file}
              errorComponent={
                <AttachmentCard
                  url={file}
                  name={file}
                  className={`attachmentcard-fullscreenpreview`}
                />
              }
              onError={(e) => console.error(e)}
            />
          )}
        </Box>

        <Box className="box-changeindex">
          <IconButton
            className="iconbutton-changeindex"
            onClick={() => handleChangeIndex(index + 1)}
          >
            <KeyboardArrowRight fontSize="large" />
          </IconButton>
        </Box>
      </Box>

      <Box className="box-footer">
        {files.map((file, idx) => {
          const actived = index === idx ? 'actived' : '';

          return (
            <AttachmentCard
              url={file}
              name={file}
              className={`attachmentcard-smallpreview ${actived}`}
              onClick={() => setIndex(idx)}
            />
          );
        })}
      </Box>
    </Backdrop>
  );
};

export default FullscreenPreview;
