import Axios from 'axios';
import baseURL from '../variables/api';

const Get = async (category, area) => {
  const authToken = localStorage.getItem('banana');

  return await Axios.get(`${baseURL}/peb/assessment-results`, {
    headers: {
      Authorization: 'Bearer ' + authToken
    },
    params: {
      category: category,
      area: area
    }
  });
};

const ResultsService = {
  Get
};

export default ResultsService;
