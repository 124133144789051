import Axios from 'axios';
import baseURL from '../variables/api';

const authToken = localStorage.getItem('banana');

const List = async (params = {}) => {
  return await Axios.get(`${baseURL}/uptown/spreadsheets`, {
    headers: {
      Authorization: 'Bearer ' + authToken
    },
    params: params
  });
};

const ListSpreadsheetCategories = async () => {
  return await Axios.get(`${baseURL}/uptown/spreadsheets/categories`, {
    headers: {
      Authorization: 'Bearer ' + authToken
    }
  });
};

const UploadSpreadsheetFile = async (formData) => {
  return await Axios.post(`${baseURL}/uptown/spreadsheets`, formData, {
    headers: {
      Authorization: 'Bearer ' + authToken
    }
  });
};

const Export = async (id) => {
  return await Axios.get(`${baseURL}/uptown/spreadsheets/${id}`, {
    headers: {
      Authorization: 'Bearer ' + authToken
    },
    responseType: 'blob'
  });
};

const SpreadsheetService = {
  List,
  ListSpreadsheetCategories,
  UploadSpreadsheetFile,
  Export
};

export default SpreadsheetService;
