import Axios from 'axios';

const FindCep = async (cep) => {
  cep = cep.replace(/\D/g, '');
  return Axios.get(`https://viacep.com.br/ws/${cep}/json/`);
};

const FindCnpj = async (cnpj) => {
  cnpj = cnpj.replace(/\D/g, '');
  return Axios.get(`https://www.receitaws.com.br/v1/cnpj/${cnpj}`);
};

const ApiHelper = {
  FindCep,
  FindCnpj
};

export default ApiHelper;
