import React from 'react';

const imageExtensions = ['.png', '.jpg', '.jpeg'];

const AttachmentCard = ({ link }) => {
  const getFileName = () => {
    return link.split('/').pop();
  };

  const isImage = () => {
    return imageExtensions.some((ext) => getFileName().indexOf(ext) > -1);
  };

  return (
    <div
      style={{
        width: '30%',
        height: 120,
        margin: 4,
        cursor: 'pointer',
        backgroundColor: '#f5f5f5',
        borderRadius: 2,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundImage: `url('${link}')`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        padding: 12
      }}
      onClick={() => window.open(link)}
    >
      {!isImage() && (
        <>
          <i
            className="far fa-file-alt"
            style={{
              fontSize: 20
            }}
          />

          <span
            style={{
              fontSize: 12,
              display: 'block',
              width: '100%',
              textAlign: 'center',
              marginTop: 8,
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}
          >
            {getFileName()}
          </span>
        </>
      )}
    </div>
  );
};

export default AttachmentCard;
