import Axios from 'axios';
import baseURL from '../variables/api';

const authToken = localStorage.getItem('banana');

const List = async (advisorId, category, limit, page) => {
  return await Axios.get(`${baseURL}/uptown/peb-assessments`, {
    headers: {
      Authorization: 'Bearer ' + authToken
    },
    params: {
      advisor: advisorId,
      category: category,
      limit: limit,
      page: page
    }
  });
};

const Remove = async (assessmentId) => {
  return await Axios.delete(`${baseURL}/uptown/peb-assessments/${assessmentId}`, {
    headers: {
      Authorization: 'Bearer ' + authToken
    }
  });
};

const AssessmentService = {
  List,
  Remove
};

export default AssessmentService;
