import React, { useContext, useEffect, useState } from 'react';
import { Alert, Button, Card, CardText, CardTitle, Col, Container } from 'reactstrap';
import Loader from '../../../components/Bridgestone/Loader';
import ReportListModalFilters from '../../../components/ReportList/ModalFilters';
import ReportService from '../../../services/ReportService';
import { UserContext } from '../../../components/Contexts/UserContext';

const ReportList = () => {
  const { token } = useContext(UserContext);
  const [reportList, setReportList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(undefined);

  const [selectedReport, setSelectedReport] = useState(undefined);

  useEffect(() => {
    handleRefreshReports();
  }, []);

  const handleRefreshReports = async () => {
    setLoading(true);

    ReportService.List(token)
      .then((resp) => {
        setReportList(resp.data);
      })
      .catch((err) => {
        handleError(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleError = (message, color = 'danger') => {
    setError({ message, color });

    setTimeout(() => {
      setError(undefined);
    }, 4000);
  };

  return (
    <>
      {/* Page content */}
      {loading && <Loader />}
      {error ? (
        <Alert color={error.color} style={{ position: 'fixed', top: '30px', zIndex: 9999 }}>
          {error.message}
        </Alert>
      ) : (
        ''
      )}

      <Container className="mt-2 mt-md-5 mb-2 action-plan-container d-flex flex-wrap" fluid>
        <Col className="col-12 mt-5" />

        {reportList.map((report) => {
          return (
            <Col className="col-md-6 col-lg-4 col-12 mt-4" key={report.id}>
              <Card
                body
                className="cursor-pointer"
                style={{ height: '100%' }}
                onClick={(_) => setSelectedReport(report)}
              >
                <CardTitle>{report.title}</CardTitle>

                <CardText>{report.description}</CardText>
              </Card>
            </Col>
          );
        })}
      </Container>

      <ReportListModalFilters
        report={selectedReport}
        isOpen={Boolean(selectedReport)}
        token={token}
        handleClose={(_) => setSelectedReport(undefined)}
        setLoading={setLoading}
        setError={handleError}
      />
    </>
  );
};

export default ReportList;
