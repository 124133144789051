import React from 'react';
import { Card, CardBody, CardHeader, Row } from 'reactstrap';
import PercentageChart from '../../../../../components/Statistics/PercentageChart';

export default function WebsiteChart({ data, category }) {
  const { progress, chart } = data;

  return (
    <Card className="shadow borderradius-default">
      <CardHeader className="bg-transparent">
        <Row className="align-items-center">
          <div className="col">
            <h6 className="text-uppercase text-light ls-1 mb-1"> Business </h6>
            <h2 className="mb-0">Website</h2>
          </div>
        </Row>
      </CardHeader>

      <CardBody className="p-0 pb-3">
        <PercentageChart data={progress && progress.whatsappButton} title="Botão para whatsapp" />

        <PercentageChart data={progress && progress.contactAddress} title="Contato e endereços" />

        <PercentageChart data={progress && progress.budgetForm} title="Formulário de orçamento" />

        {category != 'BANDAG' && (
          <PercentageChart data={progress && progress.oficialResale} title="Revenda oficial" />
        )}

        <PercentageChart
          data={progress && progress.socialNetworksButtons}
          title="Botões para redes sociais"
        />

        <PercentageChart data={progress && progress.updatedBanners} title="Banners atualizados" />

        <PercentageChart
          data={progress && progress.imagesDiscontinuedProducts}
          title="Imagens de produtos fora de linha"
        />

        {category != 'BANDAG' && (
          <PercentageChart
            data={progress && progress.competitorProducts}
            title="Produtos da concorrência"
          />
        )}
      </CardBody>
    </Card>
  );
}
