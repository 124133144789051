import Axios from 'axios';
import baseURL from '../variables/api';

const authToken = localStorage.getItem('banana');

const List = async () => {
  return await Axios.get(`${baseURL}/peb/regions`, {
    headers: {
      Authorization: 'Bearer ' + authToken
    }
  });
};

const RegionService = {
  List
};

export default RegionService;
