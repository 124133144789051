import Axios from 'axios';
import BaseUrl from '../variables/api';

const List = async (managerId) => {
  return Axios.get(`${BaseUrl}/peb/advisors?manager=${managerId}`);
};

const AdvisorsService = {
  List
};

export default AdvisorsService;
