import React, { useEffect, useState } from 'react';
import { Alert, Button, Col, Container, Nav } from 'reactstrap';
import Loader from '../../../components/Bridgestone/Loader';
import axios from 'axios';
import baseURL from '../../../variables/api';
import ContentMaterialModalAddEdit from '../../../components/ContentMaterialCrud/ModalAddEdit';
import ContentMaterialModalRemove from '../../../components/ContentMaterialCrud/ModalRemove';

const ContentMaterialCrud = () => {
  const [contentMaterial, setContentMaterial] = useState([]);
  const [categories, setCategories] = useState([]);
  const [perfis, setPerfis] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(undefined);

  const [selectedContentMaterial, setSelectedContentMaterial] = useState(undefined);
  const [openedModalAddEdit, setOpenedModalAddEdit] = useState(false);
  const [openedModalRemove, setOpenedModalRemove] = useState(false);

  useEffect(() => {
    handleRefreshContentMaterial();
    handleRefreshCategories();
    handleRefreshProfiles();
  }, []);

  const handleRefreshContentMaterial = async () => {
    setLoading(true);

    try {
      const response = (
        await axios.get(`${baseURL}/uptown/materials`, {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('banana')
          }
        })
      ).data;
      setContentMaterial(response);
    } catch (err) {
      handleError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleRefreshCategories = async () => {
    setLoading(true);

    try {
      const response = (
        await axios.get(`${baseURL}/peb/categories`, {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('banana')
          }
        })
      ).data;
      setCategories(response);
    } catch (err) {
      handleError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleRefreshProfiles = async () => {
    setLoading(true);

    try {
      const response = (
        await axios.get(`${baseURL}/users/profiles`, {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('banana')
          }
        })
      ).data;
      setPerfis(response);
    } catch (err) {
      handleError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleError = (message, color = 'danger') => {
    setError({ message, color });

    setTimeout(() => {
      setError(undefined);
    }, 4000);
  };

  return (
    <>
      {/* Page content */}
      {loading && <Loader />}
      {error ? (
        <Alert color={error.color} style={{ position: 'fixed', top: '30px', zIndex: 9999 }}>
          {error.message}
        </Alert>
      ) : (
        ''
      )}

      <Container className="mt-2 mt-md-5 mb-2 action-plan-container" fluid>
        <Nav
          className="mt-6 nav-fill flex-column flex-sm-row justify-content-flex-end"
          pills
          role="tablist"
        >
          <Button
            size="sm"
            onClick={(e) => {
              setSelectedContentMaterial(undefined);
              setOpenedModalAddEdit(true);
            }}
          >
            Adicionar +
          </Button>
        </Nav>

        {/* CONTENT */}
        <Col className="p-0 mt-3">
          <div className="card">
            <div className="card-content table-responsive">
              <table className="table align-items-center">
                <thead className="thead-light">
                  <tr>
                    <th>NOME</th>
                    <th>CATEGORIAS</th>
                    <th>PERFIS</th>
                    <th style={{ textAlign: 'center' }}>AÇÕES</th>
                  </tr>
                </thead>
                <tbody>
                  {contentMaterial.map((material, k) => {
                    return (
                      <tr key={k}>
                        <td style={{ whiteSpace: 'break-spaces' }}>{material.name}</td>
                        <td>
                          <span className="default-chip">
                            {material.categories.map((c) => c.descr_pt).join(', ')}
                          </span>
                        </td>

                        <td>
                          <span className="default-chip">
                            {material.profiles.map((c) => c.name).join(', ')}
                          </span>
                        </td>

                        <td style={{ textAlign: 'center' }}>
                          <Button
                            className={`background-secondary color-white`}
                            title="Editar"
                            onClick={(e) => {
                              setSelectedContentMaterial(material);
                              setOpenedModalAddEdit(true);
                            }}
                          >
                            <i className="fas fa-pen" />
                          </Button>

                          <Button
                            className={`background-primary color-white`}
                            title="Remover"
                            onClick={(e) => {
                              setSelectedContentMaterial(material);
                              setOpenedModalRemove(true);
                            }}
                          >
                            <i className="fas fa-trash" />
                          </Button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </Col>
      </Container>

      <ContentMaterialModalAddEdit
        contentMaterial={selectedContentMaterial}
        categoriesList={categories}
        perfisList={perfis}
        isOpen={openedModalAddEdit}
        handleClose={(_) => setOpenedModalAddEdit(false)}
        handleRefresh={handleRefreshContentMaterial}
        setLoading={setLoading}
        setError={handleError}
      />

      <ContentMaterialModalRemove
        contentMaterial={selectedContentMaterial}
        isOpen={openedModalRemove}
        handleClose={(_) => setOpenedModalRemove(false)}
        handleRefresh={handleRefreshContentMaterial}
        setLoading={setLoading}
        setError={handleError}
      />
    </>
  );
};

export default ContentMaterialCrud;
