import React, { useContext, useEffect, useState } from 'react';

import { Card, CardHeader, CardBody, Container, Row, Col } from 'reactstrap';
import Loader from '../../../components/Bridgestone/Loader';
import { StoreContext } from '../../../components/Contexts/StoreContext';
import { UserContext } from '../../../components/Contexts/UserContext';
import Filters from '../../../components/Filters/Filters';
import VideosService from '../../../services/VideosService';

const Videos = () => {
  const { token } = useContext(UserContext);
  const { selectedCategory } = useContext(StoreContext);

  const [loading, setLoading] = useState(false);
  const [videoList, setVideoList] = useState([]);

  useEffect(() => {
    setLoading(true);

    VideosService.List(token, selectedCategory)
      .then((resp) => {
        setVideoList(resp.data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [selectedCategory]);

  return (
    <>
      {loading && <Loader />}

      <Container fluid>
        <Filters />

        <Row>
          {videoList.map((video, index) => {
            return (
              <Col lg="4" md="6" className="mb-3" key={index}>
                <Card className="shadow">
                  <CardHeader className="bg-transparent">
                    <h3 className=" mb-0">{video.title}</h3>
                  </CardHeader>

                  <CardBody>
                    <div className="videoWrapper">
                      <iframe
                        src={video.link}
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen={true}
                        style={{
                          width: '100%'
                        }}
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            );
          })}
        </Row>
      </Container>
    </>
  );
};

export default Videos;
