import './style.scss';

import React, { useState } from 'react';
import {
  Card,
  CardHeader,
  CardFooter,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  Row
} from 'reactstrap';
import AttachmentCard from '../../../components/AttachmentCard/AttachmentCard';
import { taskAuditStatusList } from '../../../data/Lists';
import useForceRender from '../../../hooks/useForceRender';

export default function TasksTable({
  tasks,
  title,
  showStatusColumn = false,
  filtersRow,
  actionsColumn,
  totalPages,
  currentPage,
  setCurrentPage,
  handleFullscreenPreview
}) {
  const forceRender = useForceRender();
  const [attachmentIndexList, setAttachmentIndexList] = useState({});

  const handleChangeAttachmentIndexList = (task, newAttachmentIndex) => {
    const attachmentIndexListAux = attachmentIndexList;

    if (newAttachmentIndex < 0) {
      newAttachmentIndex = task.attachments.length - 1;
    } else if (newAttachmentIndex > task.attachments.length - 1) {
      newAttachmentIndex = 0;
    }

    attachmentIndexListAux[task.id] = newAttachmentIndex;
    setAttachmentIndexList(attachmentIndexListAux);

    forceRender();
  };

  return (
    <>
      <Row>
        <div className="col">
          <Card className="shadow borderradius-default">
            <CardHeader className="border-0 borderradius-default">
              <h2 className="mb-0">Auditoria - {title}</h2>
            </CardHeader>

            <Table className="align-items-center table-flush" responsive>
              <thead>
                <tr>
                  {showStatusColumn && <th scope="col">Status</th>}

                  <th scope="col">Loja</th>
                  <th scope="col">Fonte</th>
                  <th scope="col">Descritivo</th>
                  <th scope="col">Enviado em</th>
                  <th scope="col" className="width-260px">
                    Comprovantes
                  </th>
                  <th scope="col"> </th>
                </tr>

                {filtersRow}
              </thead>

              <tbody>
                {tasks.map((task, index) => {
                  const auditStatus = taskAuditStatusList.find(
                    (status) => status.id === task.status
                  );

                  const attachmentIndex = attachmentIndexList[task.id] || 0;
                  const attachment = task.attachments[attachmentIndex];

                  let onPrevItemHandler = undefined;
                  let onNextItemHandler = undefined;

                  if (task.attachments.length > 1) {
                    onPrevItemHandler = () =>
                      handleChangeAttachmentIndexList(task, attachmentIndex - 1);
                    onNextItemHandler = () =>
                      handleChangeAttachmentIndexList(task, attachmentIndex + 1);
                  }

                  return (
                    <tr key={index}>
                      {showStatusColumn && <td> {auditStatus ? auditStatus.title : '-'} </td>}

                      <td style={{ wordWrap: 'break-word', whiteSpace: 'normal' }}>
                        {' '}
                        {task.client.code} - {task.client.name}{' '}
                      </td>
                      <td> {task.type.description} </td>
                      <td style={{ wordWrap: 'break-word', whiteSpace: 'normal' }}>
                        {' '}
                        {task.title}{' '}
                      </td>
                      <td>
                        {' '}
                        {task.submitted_at
                          ? new Date(task.submitted_at).toLocaleDateString('pt-BR')
                          : '-'}{' '}
                      </td>
                      <td className="width-260px">
                        <AttachmentCard
                          url={attachment}
                          name={attachment}
                          showPreviewIcon={true}
                          onClick={() => handleFullscreenPreview(task)}
                          className="maxwidth-260px"
                          onNextItem={onNextItemHandler}
                          onPrevItem={onPrevItemHandler}
                        />
                      </td>

                      <td className="textalign-center">{actionsColumn(task)}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>

            <CardFooter className="py-4 borderradius-default">
              <nav aria-label="...">
                <Pagination
                  className="pagination justify-content-end mb-0"
                  listClassName="justify-content-end mb-0"
                >
                  <PaginationItem className={`${currentPage <= 1 ? 'disabled' : ''}`}>
                    <PaginationLink
                      href="#prev"
                      onClick={(e) => {
                        e.preventDefault();

                        if (currentPage > 1) {
                          setCurrentPage(currentPage - 1);
                        }
                      }}
                      tabIndex="-1"
                    >
                      <i className="fas fa-angle-left" />
                    </PaginationLink>
                  </PaginationItem>

                  <PaginationItem className="active">
                    <PaginationLink
                      href="#current"
                      tabIndex="-1"
                      onClick={(e) => e.preventDefault()}
                    >
                      {currentPage}
                    </PaginationLink>
                  </PaginationItem>

                  <PaginationItem className={`${currentPage >= totalPages ? 'disabled' : ''}`}>
                    <PaginationLink
                      href="#next"
                      tabIndex="-1"
                      onClick={(e) => {
                        e.preventDefault();

                        if (currentPage < totalPages) {
                          setCurrentPage(currentPage + 1);
                        }
                      }}
                    >
                      <i className="fas fa-angle-right" />
                    </PaginationLink>
                  </PaginationItem>
                </Pagination>
              </nav>
            </CardFooter>
          </Card>
        </div>
      </Row>
    </>
  );
}
