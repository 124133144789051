import React from 'react';
import { Redirect, Link } from 'react-router-dom';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Alert
} from 'reactstrap';

import axios from 'axios';
import Loader from '../../../components/Bridgestone/Loader.jsx';
import baseURL from '../../../variables/api';
import routes from '../../../routes.js';
import logo from '../../../assets/img/brand/excelencia-logo.png';

class Login extends React.Component {
  state = {
    email: '',
    password: '',
    error: false,
    errorText: '',
    loading: false,
    pathname: ''
  };

  login = async () => {
    try {
      this.setState({ loading: true });

      let res = await axios.post(baseURL + '/auth', {
        email: this.state.email,
        password: this.state.password
      });

      let token = res.data.token;

      if (!token) {
        throw new Error(res.data.message);
      }

      localStorage.setItem('banana', token);
      localStorage.setItem('user', JSON.stringify(res.data.user));

      const route = routes.find((route) => {
        let profileAllowed = route.profilesAllowed.includes(res.data.user.profileId);
        if (profileAllowed && res.data.user.isAdmin === 'Y') return true;
        if (profileAllowed && !route.isAdmin) return true;
        return false;
      });

      const pathname = route ? route.layout + route.path : '/admin/dashboard';

      this.setState({ success: true, loading: false, pathname });
    } catch (err) {
      localStorage.removeItem('banana');
      this.setState({
        loading: false,
        error: true,
        errorText: 'Usuário ou senha inválida'
      });
      setTimeout(() => {
        this.setState({ error: false });
      }, 4000);
    }
  };

  componentDidMount() {
    localStorage.removeItem('banana');
  }

  render() {
    if (this.state.success) {
      this.setState({ success: false });
      return (
        <Redirect
          push
          to={{
            pathname: this.state.pathname
          }}
        />
      );
    }
    return (
      <>
        {this.state.loading && <Loader />}
        {this.state.error ? (
          <Alert color="danger" style={{ position: 'fixed', top: '30px', zIndex: 9999 }}>
            {this.state.errorText}
          </Alert>
        ) : (
          ''
        )}

        <Col lg="5" md="7">
          <Card className="bg-white shadow border-0">
            <CardHeader className="bg-transparent pb-5 text-center">
              <div className="text-center">
                <img src={logo} style={{ width: '50%' }} alt="logo" />
              </div>
            </CardHeader>
            <CardBody className="px-lg-5 py-lg-5">
              <Form role="form">
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <InputGroupText>
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                    <Input
                      placeholder="Email"
                      type="email"
                      onChange={(e) => this.setState({ email: e.target.value })}
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                    <Input
                      placeholder="Senha"
                      type="password"
                      onChange={(e) => this.setState({ password: e.target.value })}
                    />
                  </InputGroup>
                </FormGroup>
                <div className="custom-control custom-control-alternative custom-checkbox">
                  <input className="custom-control-input" id=" customCheckLogin" type="checkbox" />
                  <label className="custom-control-label" htmlFor=" customCheckLogin">
                    <span className="text-muted">Manter Logado</span>
                  </label>
                </div>
                <div className="text-center">
                  <Button className="my-4" color="primary" type="button" onClick={this.login}>
                    Entrar
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
          <Row className="mt-3">
            <Col xs="6">
              <Link to={{ pathname: '/auth/forgot-password' }}>
                <small>Esqueci minha senha</small>
              </Link>
            </Col>
          </Row>
        </Col>
      </>
    );
  }
}

export default Login;
