import React, { useEffect, useState } from 'react';
import { Alert, Button, Col, Container, FormGroup, Input, Nav } from 'reactstrap';
import Loader from '../../../components/Bridgestone/Loader';
import AdvisorService from '../../../services/AdvisorService';
import AdvisorModalAssessments from '../../../components/AdvisorCrud/ModalAssessments';

const AdvisorsCrud = () => {
  const [advisors, setAdvisors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(undefined);

  const [searchInput, setSearchInput] = useState('');

  const [selectedAdvisor, setSelectedAdvisor] = useState(undefined);
  const [openedModalAssessments, setOpenedModalAssessments] = useState(false);

  useEffect(() => {
    handleRefreshAdvisors();
  }, []);

  const handleRefreshAdvisors = async () => {
    setLoading(true);

    AdvisorService.List()
      .then((resp) => {
        setAdvisors(resp.data);
      })
      .catch((err) => {
        handleError(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleError = (message, color = 'danger') => {
    setError({ message, color });

    setTimeout(() => {
      setError(undefined);
    }, 4000);
  };

  const advisorsFiltered = () => {
    if (searchInput.length >= 3) {
      return advisors.filter((advisor) => {
        const searchValue = searchInput.toUpperCase();

        const advisorName = advisor.name.toUpperCase();

        return new Array(advisorName).some((filter) => {
          return filter.indexOf(searchValue) > -1;
        });
      });
    }

    return advisors;
  };

  return (
    <>
      {/* Page content */}
      {loading && <Loader />}
      {error ? (
        <Alert color={error.color} style={{ position: 'fixed', top: '30px', zIndex: 9999 }}>
          {error.message}
        </Alert>
      ) : (
        ''
      )}

      <Container className="mt-2 mt-md-5 mb-2 action-plan-container" fluid>
        <Nav
          className="mt-6 nav-fill flex-column flex-sm-row justify-content-flex-end"
          pills
          role="tablist">
          <FormGroup style={{ width: '40%' }}>
            <Input
              type="text"
              title="O filtro será aplicado ao conter 3 caracteres ou mais no campo de busca"
              placeholder="Pesquisar..."
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
            />
          </FormGroup>
        </Nav>

        {/* CONTENT */}
        <Col className="p-0 mt-0">
          <div className="card">
            <div
              className="card-content table-responsive"
              style={{
                height: '70vh',
                overflow: 'auto'
              }}>
              <table className="table align-items-center">
                <thead className="thead-light thead-sticky">
                  <tr>
                    <th>NOME</th>
                    <th style={{ textAlign: 'center' }}>AÇÕES</th>
                  </tr>
                </thead>
                <tbody>
                  {advisorsFiltered().map((store, k) => {
                    return (
                      <tr key={k}>
                        <td>{store.name}</td>

                        <td style={{ textAlign: 'center' }}>
                          <Button
                            className={`background-secondary color-white`}
                            title="Avaliações"
                            onClick={() => {
                              setSelectedAdvisor(store);
                              setOpenedModalAssessments(true);
                            }}>
                            <i className="fas fa-tasks" />
                          </Button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </Col>
      </Container>

      <AdvisorModalAssessments
        advisor={selectedAdvisor}
        isOpen={openedModalAssessments}
        handleClose={() => setOpenedModalAssessments(false)}
        setLoading={setLoading}
        setError={handleError}
      />
    </>
  );
};

export default AdvisorsCrud;
