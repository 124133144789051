import Axios from 'axios';
import baseURL from '../variables/api';

const authToken = localStorage.getItem('banana');

const List = async (token) => {
  return await Axios.get(`${baseURL}/peb/evaluation-areas`, {
    headers: {
      Authorization: 'Bearer ' + token
    }
  });
};

const AreaService = {
  List
};

export default AreaService;
