import React, { useEffect, useState } from 'react';
import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from 'reactstrap';
import Select from 'react-select';
import FormHelper from '../../helpers/FormHelper';
import FileSaver from 'file-saver';
import ReportService from '../../services/ReportService';
import CategoryService from '../../services/CategoryService';
import ManagerService from '../../services/ManagerService';
import AdvisorService from '../../services/AdvisorService';
import RegionService from '../../services/RegionService';
import StoreService from '../../services/StoreService';
import AreaService from '../../services/AreaService';

const ReportListModalFilters = ({ report, isOpen, handleClose, setLoading, setError, token }) => {
  const [categoryList, setCategoryList] = useState([]);
  const [managerList, setManagerList] = useState([]);
  const [advisorList, setAdvisorList] = useState([]);
  const [regionList, setRegionList] = useState([]);
  const [storeList, setStoreList] = useState([]);
  const [areaList, setAreaList] = useState([]);

  const [dataInicial, setDataInicial] = useState('');
  const [dataFinal, setDataFinal] = useState('');
  const [categoria, setCategoria] = useState(0);
  const [gerenteRegional, setGerenteRegional] = useState(0);
  const [supervisor, setSupervisor] = useState(0);
  const [regiao, setRegiao] = useState(0);
  const [loja, setLoja] = useState(0);
  const [area, setArea] = useState(0);
  const [submits, setSubmits] = useState(0);
  const [submissionsBy, setSubmissionsBy] = useState(0);

  const availableFilters = {
    initialDate: (required = false) => (
      <Col className="col-md-6 col-12">
        <FormGroup>
          <Label for="initialDate">Data inicial:</Label>
          <Input
            type="date"
            id="initialDate"
            name="initialDate"
            required={required}
            value={dataInicial}
            onChange={(e) => setDataInicial(e.target.value)}
          />
        </FormGroup>
      </Col>
    ),
    finalDate: (required = false) => (
      <Col className="col-md-6 col-12">
        <FormGroup>
          <Label for="finalDate">Data final:</Label>
          <Input
            type="date"
            id="finalDate"
            name="finalDate"
            required={required}
            value={dataFinal}
            onChange={(e) => setDataFinal(e.target.value)}
          />
        </FormGroup>
      </Col>
    ),
    category: (required = false) => (
      <Col className="col-12">
        <FormGroup>
          <Label for="category">Categoria:</Label>
          <Input
            type="select"
            id="category"
            name="category"
            required={required}
            value={categoria}
            onChange={(e) => setCategoria(e.target.value)}
          >
            <option value=""> Todas categorias </option>

            {categoryList.map((category, index) => {
              return (
                <option value={category.id} key={index}>
                  {' '}
                  {category.descr_pt}{' '}
                </option>
              );
            })}
          </Input>
        </FormGroup>
      </Col>
    ),
    advisor: (required = false) => (
      <Col className="col-12">
        <FormGroup>
          <Label for="advisor">Supervisor:</Label>
          <Input
            type="select"
            id="advisor"
            name="advisor"
            required={required}
            value={supervisor}
            onChange={(e) => setSupervisor(e.target.value)}
          >
            <option value=""> Todos supervisores </option>

            {advisorList.map((advisor, index) => {
              return (
                <option value={advisor.id} key={index}>
                  {' '}
                  {advisor.name}{' '}
                </option>
              );
            })}
          </Input>
        </FormGroup>
      </Col>
    ),
    region: (required = false) => (
      <Col className="col-md-6 col-12">
        <FormGroup>
          <Label for="region">Região:</Label>
          <Input
            type="select"
            id="region"
            name="region"
            required={required}
            value={regiao}
            onChange={(e) => setRegiao(e.target.value)}
          >
            <option value=""> Todas regiões </option>

            {regionList.map((region, index) => {
              return (
                <option value={region.id} key={index}>
                  {' '}
                  {region.descr_pt}{' '}
                </option>
              );
            })}
          </Input>
        </FormGroup>
      </Col>
    ),
    regionalManager: (required = false) => (
      <Col className="col-md-6 col-12">
        <FormGroup>
          <Label for="regionalManager">Gerente regional:</Label>
          <Input
            type="select"
            id="regionalManager"
            name="regionalManager"
            required={required}
            value={gerenteRegional}
            onChange={(e) => setGerenteRegional(e.target.value)}
          >
            <option value=""> Todos gerentes regionais </option>

            {managerList.map((manager, index) => {
              return (
                <option value={manager.id} key={index}>
                  {' '}
                  {manager.name}{' '}
                </option>
              );
            })}
          </Input>
        </FormGroup>
      </Col>
    ),
    code: (required = false) => (
      <Col className="col-12">
        <FormGroup>
          <Label for="code">Loja:</Label>
          <Select
            options={storeList}
            required={required}
            isClearable={true}
            name="code"
            placeholder="Todas as lojas"
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                height: 'calc(2.375rem + 2px)',
                color: '#8898aa',
                fontSize: '0.875rem',
                borderColor: state.isFocused ? 'rgba(50, 151, 211, 0.25)!important' : '#cad1d7',
                boxShadow: 'none'
              }),
              placeholder: (baseStyles, state) => ({
                ...baseStyles,
                color: '#8898aa',
                marginLeft: '9px'
              }),
              input: (baseStyles, state) => ({
                ...baseStyles,
                color: '#8898aa',
                paddingLeft: '9px'
              }),
              valueContainer: (baseStyles, state) => ({
                ...baseStyles,
                color: '#8898aa'
              }),
              singleValue: (baseStyles, state) => ({
                ...baseStyles,
                color: '#8898aa',
                paddingLeft: '9px'
              })
            }}
          />
        </FormGroup>
      </Col>
    ),
    area: (required = false) => (
      <Col className="col-12">
        <FormGroup>
          <Label for="area">Área de avaliação:</Label>
          <Input
            type="select"
            id="area"
            name="area"
            required={required}
            value={area}
            onChange={(e) => setArea(e.target.value)}
          >
            <option value=""> Todas áreas de avaliação </option>

            {areaList.map((area, index) => {
              return (
                <option value={area.area} key={index}>
                  {' '}
                  {area.name}{' '}
                </option>
              );
            })}
          </Input>
        </FormGroup>
      </Col>
    ),
    submissionsBy: (required = false) => (
      <Col className="col-12">
        <FormGroup>
          <Label for="submissionsBy">Agrupar por:</Label>
          <Input
            type="select"
            id="submissionsBy"
            name="submissionsBy"
            required={required}
            value={submissionsBy}
            onChange={(e) => setSubmissionsBy(e.target.value)}
          >
            <option value="total"> Total </option>
            <option value="region"> Região </option>
            <option value="advisor"> Assessor </option>
          </Input>
        </FormGroup>
      </Col>
    ),
    submits: (required = false) => (
      <Col className="col-12">
        <FormGroup>
          <Label for="submits">Avaliação:</Label>
          <Input
            type="select"
            id="submits"
            name="submits"
            required={required}
            value={submits}
            onChange={(e) => setSubmits(e.target.value)}
          >
            <option value="1"> 1° Avaliação </option>
            <option value="2"> 2° Avaliação </option>
          </Input>
        </FormGroup>
      </Col>
    )
  };

  useEffect(() => {
    Promise.all([
      CategoryService.List(),
      ManagerService.List(),
      AdvisorService.List(),
      RegionService.List(),
      StoreService.List(token),
      AreaService.List(token)
    ]).then((resp) => {
      setCategoryList(resp[0].data);
      setManagerList(resp[1].data);
      setAdvisorList(resp[2].data);
      setRegionList(resp[3].data);
      setStoreList(
        resp[4].data.map((store) => ({ label: `${store.code} - ${store.name}`, value: store.code }))
      );
      setAreaList(resp[5].data);
    });
  }, []);

  useEffect(() => {
    if (isOpen) {
      setDataInicial('');
      setDataFinal('');
    }
  }, [isOpen]);

  const handleExportAction = (e) => {
    e.preventDefault();

    let formData = FormHelper.GenerateFormData(e, null);

    // Remove os filtros sem valor setado
    Object.entries(availableFilters).forEach(([key]) => {
      if (formData.has(key) && formData.get(key).length == 0) {
        formData = FormHelper.RemoveKey(formData, key);
      }
    });

    setLoading(true);

    ReportService.Export(token, report.url_api, formData)
      .then((resp) => {
        const reportTitle = report.title.toLowerCase().replace(/ /g, '_');

        const filename = `${reportTitle}.${report.extension}`;
        const file = new File([resp.data], filename);

        FileSaver.saveAs(file);
      })
      .catch((err) => {
        setError('Ocorreu um erro ao tentar realizar a operação');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <Modal isOpen={isOpen} toggle={handleClose}>
        <form onSubmit={(e) => handleExportAction(e)}>
          <ModalHeader>
            <h3>{(report && report.title) || '-'}</h3>
          </ModalHeader>

          <ModalBody className="pt-0">
            <Row>
              {Object.entries(availableFilters).map(([key, value]) => {
                if (report && report.filters && report.filters[key] !== undefined) {
                  const filterRequired = report.filters[key];
                  return value(filterRequired);
                }

                return <></>;
              })}
            </Row>
          </ModalBody>

          <ModalFooter
            style={{
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <Button className="background-primary color-white" onClick={handleClose}>
              Voltar
            </Button>

            <Button type="submit" className="background-secondary color-white">
              Gerar
            </Button>
          </ModalFooter>
        </form>
      </Modal>
    </>
  );
};

export default ReportListModalFilters;
