import './style.scss';
import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Typography,
  Button,
  Grid,
  Divider,
  ImageList
} from '@mui/material';
import DefaultTextField from '../TextField/TextField';
import { Check, PhotoCamera } from '@mui/icons-material';
import FormHelper from '../../helpers/FormHelper';
import ActionPlanService from '../../services/ActionPlanService';
import AttachmentCard from '../AttachmentCard/AttachmentCard';
import useForceRender from '../../hooks/useForceRender';

const DialogActionPlanFinishTask = ({
  isOpen,
  setClosedDialog = () => {},
  token,
  task,
  handleRefresh,
  setLoading,
  setError
}) => {
  const forceRender = useForceRender();

  const [comentario, setComentario] = useState('');
  const [arquivos, setArquivos] = useState([]);
  const uploadInputRef = useRef(null);

  const fileUploaded = arquivos && arquivos.length > 0;

  useEffect(() => {
    setComentario('');
    setArquivos([]);
  }, [task]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (fileUploaded) {
      setLoading(true);

      let formData = FormHelper.GenerateFormData(e, null);
      formData = await FormHelper.IncludeFileList(formData, 'attachments', arquivos);

      ActionPlanService.Done(token, task.id, formData)
        .then((resp) => {
          setError(`Tarefa finalizada com sucesso`, 'success');
          setClosedDialog();
          handleRefresh();
        })
        .catch((err) => {
          setError('Não foi possível finalizar a tarefa');
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      uploadInputRef.current && uploadInputRef.current.click();
    }
  };

  const handleAddAttach = (e) => {
    const arquivosAux = arquivos;

    Object.entries(e.target.files).forEach(([key, file]) => {
      arquivosAux.push(file);
    });

    setArquivos(arquivosAux);
    forceRender();
  };

  const handleRemoveAttach = (key) => {
    const arquivosAux = arquivos;
    arquivosAux.splice(key, 1);

    setArquivos(arquivosAux);
    forceRender();
  };

  if (!task) return <></>;

  return (
    <Dialog
      open={isOpen}
      maxWidth="sm"
      fullWidth={true}
      onClose={setClosedDialog}
      PaperProps={{
        className: `dialog-default dialog-actionplan-finishtask`
      }}>
      <Box component="form" onSubmit={(e) => handleSubmit(e)}>
        <DialogTitle className="dialogtitle-default" sx={{ paddingTop: 4 }}>
          <Typography variant="subtitle1" className="fontweight-600 textalign-center">
            Concluir Plano de ação
          </Typography>
        </DialogTitle>

        <DialogContent className="dialogcontent-default">
          <input
            ref={uploadInputRef}
            type="file"
            style={{
              display: 'none'
            }}
            multiple={true}
            value=""
            onChange={handleAddAttach}
          />

          <Grid container paddingX={2}>
            <Grid item xs={12}>
              <Typography variant="subtitle1" className="fontweight-600">
                Plano de ação
              </Typography>

              <Typography variant="body1">{task.title}</Typography>

              {task.question_images.length ? (
                <>
                  <Typography variant="subtitle1" className="fontweight-600 mt-3">
                    Foto(s) / arquivo(s) que gerou este plano de ação:
                  </Typography>

                  <ImageList cols={3}>
                    {task.question_images.map((attachment, index) => {
                      return (
                        <AttachmentCard
                          url={attachment}
                          name={attachment}
                          showPreviewIcon={true}
                          onClick={() => window.open(attachment)}
                          key={index}
                        />
                      );
                    })}
                  </ImageList>
                </>
              ) : (
                <></>
              )}

              <DefaultTextField
                label="Deixe um comentário (não obrigatório)"
                name="comments"
                value={comentario}
                onChange={(e) => setComentario(e.target.value)}
                multiline={true}
                required={false}
                rows={3}
              />
            </Grid>

            <Grid item xs={12}>
              <ImageList cols={3}>
                {arquivos.map((arquivo, index) => {
                  const url = URL.createObjectURL(arquivo);

                  return (
                    <AttachmentCard
                      url={url}
                      name={arquivo.name}
                      onRemove={(_) => handleRemoveAttach(index)}
                      key={index}
                    />
                  );
                })}
              </ImageList>
            </Grid>

            <Grid item xs={12} paddingTop={3}>
              <Divider />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions
          className="dialogactions-default"
          sx={{
            paddingX: 4,
            paddingBottom: 4,
            justifyContent: 'space-between'
          }}>
          <Button
            type="button"
            className={`color-white borderradius-default ${
              fileUploaded ? 'background-green' : 'background-primary'
            }`}
            size="small"
            startIcon={fileUploaded ? <Check fontSize="small" /> : <PhotoCamera fontSize="small" />}
            onClick={(_) => uploadInputRef.current && uploadInputRef.current.click()}>
            {fileUploaded ? `${arquivos.length} arquivos anexados` : 'Anexar comprovante'}
          </Button>

          <Button
            type="submit"
            className="background-green color-white borderradius-default"
            size="small">
            Enviar
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default DialogActionPlanFinishTask;
