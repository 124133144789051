import React, { useContext, useEffect, useState } from 'react';
import { Alert, Button, Col, Container, FormGroup, Input, Nav } from 'reactstrap';
import Loader from '../../../components/Bridgestone/Loader';
import StoreService from '../../../services/StoreService';
import MaskHelper from '../../../helpers/MaskHelper';
import StoreModalEdit from '../../../components/StoreCrud/ModalEdit';
import StoreModalUnsubscribe from '../../../components/StoreCrud/ModalUnsubscribe';
import StoreModalManagers from '../../../components/StoreCrud/ModalManagers';
import { UserContext } from '../../../components/Contexts/UserContext';

const StoresCrud = () => {
  const { token } = useContext(UserContext);
  const [stores, setStores] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(undefined);

  const [searchInput, setSearchInput] = useState('');

  const [selectedStore, setSelectedStore] = useState(undefined);
  const [openedModalEdit, setOpenedModalEdit] = useState(false);
  const [openedModalManagers, setOpenedModalManagers] = useState(false);
  const [openedModalUnsubscribe, setOpenedModalUnsubscribe] = useState(false);

  useEffect(() => {
    handleRefreshStores();
  }, []);

  const handleRefreshStores = async () => {
    setLoading(true);

    StoreService.List(token)
      .then((resp) => {
        setStores(resp.data);
      })
      .catch((err) => {
        handleError(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleError = (message, color = 'danger') => {
    setError({ message, color });

    setTimeout(() => {
      setError(undefined);
    }, 4000);
  };

  const storesFiltered = () => {
    if (searchInput.length >= 3) {
      return stores.filter((store) => {
        const searchValue = searchInput.toUpperCase();

        const storeCode = store.code;
        const storeName = store.name.toUpperCase();
        const storeDocNumber = store.doc_number;
        const storeCategories = store.categories
          .map((category) => category.descr_pt.toUpperCase())
          .join(', ');
        const storeAdvisor =
          `${store.storeAdvisor.firstName} ${store.storeAdvisor.lastName}`.toUpperCase();

        return new Array(storeCode, storeName, storeDocNumber, storeCategories, storeAdvisor).some(
          (filter) => {
            return filter.indexOf(searchValue) > -1;
          }
        );
      });
    }

    return stores;
  };

  return (
    <>
      {/* Page content */}
      {loading && <Loader />}
      {error ? (
        <Alert color={error.color} style={{ position: 'fixed', top: '30px', zIndex: 9999 }}>
          {error.message}
        </Alert>
      ) : (
        ''
      )}

      <Container className="mt-2 mt-md-5 mb-2 action-plan-container" fluid>
        <Nav
          className="mt-6 nav-fill flex-column flex-sm-row justify-content-flex-end"
          pills
          role="tablist"
        >
          <FormGroup style={{ width: '40%' }}>
            <Input
              type="text"
              title="O filtro será aplicado ao conter 3 caracteres ou mais no campo de busca"
              placeholder="Pesquisar..."
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
            />
          </FormGroup>
        </Nav>

        {/* CONTENT */}
        <Col className="p-0 mt-0">
          <div className="card">
            <div
              className="card-content table-responsive"
              style={{
                height: '70vh',
                overflow: 'auto'
              }}
            >
              <table className="table align-items-center">
                <thead className="thead-light thead-sticky">
                  <tr>
                    <th>CÓDIGO</th>
                    <th>LOJA</th>
                    <th>CNPJ</th>
                    <th>CATEGORIAS</th>
                    <th>ASSESSOR</th>
                    <th style={{ textAlign: 'center' }}>AÇÕES</th>
                  </tr>
                </thead>
                <tbody>
                  {storesFiltered().map((store, k) => {
                    return (
                      <tr key={k}>
                        <td>{store.code}</td>

                        <td style={{ whiteSpace: 'break-spaces' }}>{store.name}</td>

                        <td>{MaskHelper.Cnpj(store.doc_number, false)}</td>

                        <td>
                          {store.categories.map((category, index) => {
                            return (
                              <span className="default-chip" key={index}>
                                {category.descr_pt}
                              </span>
                            );
                          })}
                        </td>

                        <td style={{ whiteSpace: 'break-spaces' }}>
                          {store.storeAdvisor.firstName} {store.storeAdvisor.lastName}
                        </td>

                        <td style={{ textAlign: 'center' }}>
                          <Button
                            className={`background-secondary color-white`}
                            title="Editar"
                            onClick={(e) => {
                              setSelectedStore(store);
                              setOpenedModalEdit(true);
                            }}
                          >
                            <i className="fas fa-pen" />
                          </Button>

                          <Button
                            className={`background-secondary color-white`}
                            title="Responsáveis"
                            onClick={(e) => {
                              setSelectedStore(store);
                              setOpenedModalManagers(true);
                            }}
                          >
                            <i className="fas fa-user-tie" />
                          </Button>

                          <Button
                            className={`background-primary color-white`}
                            title="Cancelar Inscrição"
                            onClick={(e) => {
                              setSelectedStore(store);
                              setOpenedModalUnsubscribe(true);
                            }}
                          >
                            <i className="fas fa-ban" />
                          </Button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </Col>
      </Container>

      <StoreModalEdit
        store={selectedStore}
        isOpen={openedModalEdit}
        token={token}
        handleClose={(_) => setOpenedModalEdit(false)}
        handleRefresh={handleRefreshStores}
        setLoading={setLoading}
        setError={handleError}
      />

      <StoreModalManagers
        store={selectedStore}
        isOpen={openedModalManagers}
        token={token}
        handleClose={(_) => setOpenedModalManagers(false)}
        setLoading={setLoading}
        setError={handleError}
      />

      <StoreModalUnsubscribe
        store={selectedStore}
        isOpen={openedModalUnsubscribe}
        token={token}
        handleClose={(_) => setOpenedModalUnsubscribe(false)}
        handleRefresh={handleRefreshStores}
        setLoading={setLoading}
        setError={handleError}
      />
    </>
  );
};

export default StoresCrud;
