/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React from 'react';
import {
  ArticleOutlined,
  AssessmentOutlined,
  BadgeOutlined,
  Book,
  BookOutlined,
  Check,
  DescriptionOutlined,
  EmojiEventsOutlined,
  FileCopyOutlined,
  LeaderboardOutlined,
  MilitaryTechOutlined,
  Notifications,
  PeopleAltOutlined,
  Percent,
  PlayCircleOutline,
  Rule,
  RuleOutlined,
  SendOutlined,
  StarBorder,
  Store,
  StoreOutlined,
  ThumbUpOutlined,
  WorkspacePremiumOutlined
} from '@mui/icons-material';

import Dashboard from './views/excelencia/Dashboard/Dashboard.jsx';
import ActionPlan from './views/excelencia/ActionPlan/ActionPlan.jsx';
import ContentMaterial from './views/excelencia/ContentMaterial/ContentMaterial.jsx';
import StoreProfile from './views/excelencia/StoreProfile/StoreProfile.jsx';
import Ranking from './views/excelencia/Ranking/Ranking.jsx';
import Videos from './views/excelencia/Videos/Videos.jsx';
import Rules from './views/excelencia/Rules/Rules.jsx';
import RequestRecover from './views/excelencia/Login/RequestRecover.jsx';
import Recover from './views/excelencia/Login/Recover.jsx';
import SacPersonal from './views/excelencia/Sac/SacPersonal';
import SatisfactionSurvey from './views/excelencia/SatisfactionSurvey/SatisfactionSurvey';
import Social from './views/excelencia/Social/Social';
import Login from './views/excelencia/Login/Login.jsx';
import ExtraScores from './views/excelencia/ExtraScores/ExtraScores';
import YourEmblems from './views/excelencia/YourEmblems/YourEmblems';
import SelectForm from './views/excelencia/SelectForm';
import Template from './views/excelencia/Template';
import Submit from './views/excelencia/Submit';
import SubmitManager from './views/excelencia/SubmitManager';
import Users from './views/excelencia/User/Users';
import UsersEdit from './views/excelencia/User/UsersEdit';
import AdvisorValidations from './views/excelencia/AdvisorValidations/AdvisorValidations';
import ReportList from './views/excelencia/ReportList/ReportList';
import StoresCrud from './views/excelencia/StoresCrud/StoresCrud';
import AdvisorsCrud from './views/excelencia/AdvisorsCrud/AdvisorsCrud';
import ExtraScoresCrud from './views/excelencia/ExtraScoresCrud/ExtraScoresCrud';
import VideosCrud from './views/excelencia/VideosCrud/VideosCrud';
import SocialMediasCrud from './views/excelencia/SocialMediasCrud/SocialMediasCrud';
import ContentMaterialCrud from './views/excelencia/ContentMaterialCrud/ContentMaterialCrud';
import SpreadsheetUpload from './views/excelencia/SpreadsheetUpload/SpreadsheetUpload';
import Audit from './views/excelencia/Audit/Audit';
import SubmitManagerAudit from './views/excelencia/SubmitManagerAudit';
import NotificationsCrud from './views/excelencia/NotificationsCrud/NotificationsCrud';
import StoreSubscription from './views/excelencia/StoreProfile/StoreSubscription';
import RulesAndLicenses from './views/excelencia/RulesAndLicenses/RulesAndLicenses';
import TechnicalAssessment from './views/excelencia/TechnicalAssessment/SpreadsheetUpload';
import RulesCrud from './views/excelencia/RulesCrud/RulesCrud';
import Results from './views/excelencia/Results/Results';

var routes = [
  {
    path: '/dashboard',
    name: 'Dashboard',
    icon: AssessmentOutlined,
    component: Dashboard,
    layout: '/admin',
    store: true,
    profilesAllowed: [1, 2, 3, 4, 5, 6, 7, 8],
    isAdmin: false
  },
  {
    path: '/results',
    name: 'Resultados',
    icon: Percent,
    component: Results,
    layout: '/admin',
    store: true,
    profilesAllowed: [1],
    isAdmin: false
  },
  {
    path: '/forms',
    name: 'Questionários',
    icon: ArticleOutlined,
    component: SelectForm,
    layout: '/admin',
    store: true,
    profilesAllowed: [1, 2],
    isAdmin: false
  },
  {
    path: '/questions',
    name: 'Questionário',
    component: Template,
    layout: '/admin',
    store: false,
    profilesAllowed: [1, 2],
    isAdmin: false
  },
  {
    path: '/submit',
    name: 'Submit',
    component: Submit,
    layout: '/admin',
    store: false,
    profilesAllowed: [1, 2],
    isAdmin: false
  },
  {
    path: '/submits',
    name: 'Meus envios',
    icon: SendOutlined,
    component: SubmitManager,
    layout: '/admin',
    store: true,
    profilesAllowed: [1, 2],
    isAdmin: false
  },
  {
    path: '/users',
    name: 'Usuários',
    icon: PeopleAltOutlined,
    component: Users,
    layout: '/admin',
    store: true,
    profilesAllowed: [1],
    isAdmin: false
  },
  {
    path: '/users-edit',
    name: 'Usuários Editar',
    component: UsersEdit,
    layout: '/admin',
    store: false,
    profilesAllowed: [1],
    isAdmin: false
  },
  {
    path: '/validations',
    name: 'Validações',
    icon: RuleOutlined,
    component: AdvisorValidations,
    layout: '/admin',
    store: true,
    profilesAllowed: [2, 7],
    isAdmin: false
  },
  {
    path: '/report-list',
    name: 'Relatórios',
    icon: FileCopyOutlined,
    component: ReportList,
    layout: '/admin',
    store: true,
    profilesAllowed: [1],
    isAdmin: true
  },
  {
    path: '/stores',
    name: 'Lojas',
    icon: StoreOutlined,
    component: StoresCrud,
    layout: '/admin',
    store: true,
    profilesAllowed: [1],
    isAdmin: true
  },
  {
    path: '/advisors',
    name: 'Assessores',
    icon: BadgeOutlined,
    component: AdvisorsCrud,
    layout: '/admin',
    store: true,
    profilesAllowed: [1],
    isAdmin: true
  },
  {
    path: '/extra-scores',
    name: 'Pontuações Extras',
    icon: WorkspacePremiumOutlined,
    component: ExtraScoresCrud,
    layout: '/admin',
    store: true,
    profilesAllowed: [1],
    isAdmin: true
  },
  {
    path: '/upload-videos',
    name: 'Vídeos',
    icon: PlayCircleOutline,
    component: VideosCrud,
    layout: '/admin',
    store: true,
    profilesAllowed: [1],
    isAdmin: true
  },
  {
    path: '/social-medias',
    name: 'Mídias Sociais',
    icon: ThumbUpOutlined,
    component: SocialMediasCrud,
    layout: '/admin',
    store: true,
    profilesAllowed: [1],
    isAdmin: true
  },
  {
    path: '/content-materials',
    name: 'Materiais de Apoio',
    icon: BookOutlined,
    component: ContentMaterialCrud,
    layout: '/admin',
    store: true,
    profilesAllowed: [1],
    isAdmin: true
  },
  {
    path: '/rules-upload',
    name: 'Regulamento',
    icon: DescriptionOutlined,
    component: RulesCrud,
    layout: '/admin',
    store: true,
    profilesAllowed: [1],
    isAdmin: true
  },
  {
    path: '/spreadsheet-upload',
    name: 'Pontos Excel',
    icon: DescriptionOutlined,
    component: SpreadsheetUpload,
    layout: '/admin',
    store: true,
    profilesAllowed: [1],
    isAdmin: true
  },
  {
    path: '/audit',
    name: 'Auditoria',
    icon: Check,
    component: Audit,
    layout: '/admin',
    store: true,
    profilesAllowed: [1],
    isAdmin: true
  },
  {
    path: '/audit-form',
    name: 'Auditoria (questionários)',
    icon: Check,
    component: SubmitManagerAudit,
    layout: '/admin',
    store: true,
    profilesAllowed: [1],
    isAdmin: true
  },
  {
    path: '/technical-assessment',
    name: 'Questionário Técnico',
    icon: ArticleOutlined,
    component: TechnicalAssessment,
    layout: '/admin',
    store: true,
    profilesAllowed: [7],
    isAdmin: false
  },
  {
    path: '/plano-acao',
    name: 'Plano de ação',
    icon: DescriptionOutlined,
    component: ActionPlan,
    layout: '/admin',
    store: true,
    profilesAllowed: [6],
    isAdmin: false
  },
  {
    path: '/ranking',
    name: 'Ranking e pontuação',
    icon: EmojiEventsOutlined,
    component: Ranking,
    layout: '/admin',
    store: true,
    profilesAllowed: [6],
    isAdmin: false
  },
  {
    path: '/perfil-loja',
    name: 'Perfil das lojas',
    icon: Store,
    component: StoreProfile,
    layout: '/admin',
    store: true,
    profilesAllowed: [6],
    isAdmin: false
  },
  {
    path: '/sac',
    name: 'Pesquisa de satisfação',
    icon: StarBorder,
    component: SatisfactionSurvey,
    layout: '/admin',
    store: true,
    profilesAllowed: [6],
    isAdmin: false
  },
  {
    path: '/emblemas',
    name: 'Seus emblemas',
    icon: MilitaryTechOutlined,
    component: YourEmblems,
    layout: '/admin',
    store: true,
    profilesAllowed: [6],
    isAdmin: false
  },
  {
    path: '/pontuacoes-extras',
    name: 'Pontuações extras',
    icon: LeaderboardOutlined,
    component: ExtraScores,
    layout: '/admin',
    store: true,
    profilesAllowed: [6],
    isAdmin: false
  },
  {
    path: '/normas-licencas',
    name: 'Normas e licenças',
    icon: Rule,
    component: RulesAndLicenses,
    layout: '/admin',
    store: true,
    profilesAllowed: [6],
    isAdmin: false,
    categoriesAllowed: ['BANDAG']
  },
  {
    path: '/social',
    name: 'Redes sociais',
    icon: ThumbUpOutlined,
    component: Social,
    layout: '/admin',
    content: true,
    profilesAllowed: [1, 6],
    isAdmin: false
  },
  {
    path: '/material-apoio',
    name: 'Materiais de apoio',
    icon: Book,
    component: ContentMaterial,
    layout: '/admin',
    content: true,
    profilesAllowed: [1, 2, 6, 7, 8],
    isAdmin: false
  },
  {
    path: '/videos',
    name: 'Vídeos',
    icon: PlayCircleOutline,
    component: Videos,
    layout: '/admin',
    content: true,
    profilesAllowed: [1, 2, 6, 7, 8],
    isAdmin: false
  },
  {
    path: '/rules',
    name: 'Regulamento',
    icon: DescriptionOutlined,
    component: Rules,
    layout: '/admin',
    content: true,
    profilesAllowed: [1, 2, 3, 4, 5, 6, 7, 8, 9],
    isAdmin: false
  },
  {
    path: '/subscription',
    name: 'Inscrição',
    icon: DescriptionOutlined,
    component: StoreSubscription,
    layout: '/admin',
    content: false,
    profilesAllowed: [6],
    isAdmin: false,
    store: false
  },
  {
    path: '/notifications',
    name: 'Notificações',
    icon: Notifications,
    component: NotificationsCrud,
    layout: '/admin',
    store: true,
    profilesAllowed: [1],
    isAdmin: true
  },
  {
    path: '/forgot-password',
    name: 'Recuperar Senha',
    icon: 'ni ni-key-25',
    component: RequestRecover,
    layout: '/auth',
    hidden: true
  },
  {
    path: '/reset-password/:token',
    name: 'Redefinição de Senha',
    icon: 'ni ni-key-25',
    component: Recover,
    layout: '/auth',
    hidden: true
  },
  {
    path: '/sac-personal/:store/:category',
    name: 'Atendimento Pessoal realizado',
    icon: 'ni ni-key-25',
    component: SacPersonal,
    layout: '/auth',
    hidden: true
  },
  {
    path: '/login',
    name: 'Login',
    icon: 'ni ni-key-25',
    component: Login,
    layout: '/auth',
    hidden: true
  },
  {
    path: '/',
    name: 'Login',
    icon: 'ni ni-key-25',
    component: Login,
    layout: '/auth',
    hidden: true
  }
  // {
  //   path: "/register",
  //   name: "Register",
  //   icon: "ni ni-circle-08",
  //   component: Register,
  //   layout: "/auth"
  // },
  // {
  //   path: "/closed",
  //   name: "Fim das Inscrições",
  //   icon: "ni ni-circle-08",
  //   component: RegisterClosed,
  //   layout: "/auth"
  // }
];
export default routes;
