import React from 'react';
import { Link } from 'react-router-dom';
import {
  Card,
  CardHeader,
  CardFooter,
  Pagination,
  PaginationItem,
  PaginationLink,
  Container,
  Row,
  Button,
  Alert,
  Modal,
  CardBody,
  Form,
  FormGroup,
  InputGroup,
  InputGroupText,
  Input
} from 'reactstrap';
// core components
import NeutralHeader from '../../../components/Headers/NeutralHeader.jsx';
import RichTable from '../../../components/Data/RichTable.jsx';
import axios from 'axios';
import Loader from '../../../components/Bridgestone/Loader.jsx';
import baseURL from '../../../variables/api';

class Users extends React.Component {
  state = {
    headers: ['Usuário', 'Email', 'Perfil', 'Editar'],
    data: [],
    usersList: [],
    usersListFiltered: [],
    users: [],
    usersFiltered: [],
    pagesCount: 10,
    currentPage: 0,
    dataCopy: [],
    addUserModal: false,
    editUserModal: false,
    defaultModal: false,
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    profileId: 1,
    regionId: 1,
    storeCategories: [],
    profilesList: [],
    regionsList: [],
    storeCategoriesList: [],
    categories: {
      category: []
    },
    regions: []
  };

  async componentDidMount() {
    await this.getUsers();
  }

  getUsers = async (filters) => {
    try {
      this.setState({ loading: true });
      let res = (
        await axios.get(
          baseURL + '/users?peb=true',
          {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('banana')
            }
          },
          {
            category: 'Programa de Excelência'
          }
        )
      ).data;

      let usersList = res.user;

      this.setState(
        {
          success: true,
          loading: false,
          usersList,
          usersListFiltered: usersList,
          profilesList: res.profiles,
          regionsList: res.regions,
          storeCategoriesList: res.storeCategories
        },
        () => {
          this.setDataTable();
        }
      );
    } catch (err) {
      console.error(err);
      this.setState({
        loading: false,
        error: true
      });
      setTimeout(() => {
        this.setState({ error: false });
      }, 4000);
    }
  };

  setDataTable() {
    let usersList = this.state.usersListFiltered;

    let data = [];
    let users = [];

    for (let user of usersList) {
      data.push([
        {
          formated: user.firstName + ' ' + user.lastName,
          value: user.firstName + ' ' + user.lastName
        },
        { formated: user.email, value: user.email },
        { formated: user.name, value: user.name },
        {
          formated: (
            <Link
              to={{
                pathname: '/admin/users-edit',
                state: {
                  user,
                  profileList: this.state.profilesList,
                  regionsList: this.state.regionsList,
                  storeCategoriesList: this.state.storeCategoriesList
                }
              }}>
              <i className="ni ni-settings" />
            </Link>
          ),
          value: 'Editar'
        }
        // {
        //   formated: (
        //     <button
        //       style={{ background: "none", border: 0, color: "#5e72e4" }}
        //       onClick={() => this.deleteUser(user)}
        //     >
        //       <i className="ni ni-fat-remove" style={{ fontSize: "24px" }} />
        //     </button>
        //   ),
        // },
      ]);
    }

    let dataCopy = data;

    let dataSliced = dataCopy.slice(this.state.currentPage * 20, (this.state.currentPage + 1) * 20);

    let pagesCount = Math.ceil(dataCopy.length / 20);

    this.setState({ data, dataCopy: dataSliced, users, usersFiltered: users, pagesCount });
  }

  deleteUser = async (user) => {
    console.log(user, 'aqui');
    try {
      this.setState({ loading: true });
      let res = (
        await axios.delete(baseURL + `/users/${user.id}`, {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('banana')
          }
        })
      ).data;

      await this.setState({
        success: true,
        loading: false
      });
      window.location.reload(true);
    } catch (err) {
      console.error(err);
      this.setState({
        loading: false,
        error: true
      });
      setTimeout(() => {
        this.setState({ error: false });
      }, 4000);
    }
  };

  userFilter = (event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) => {
    if (suggestion.length > 2) {
      let goalsList = this.state.goalsList;
      let goalsListFiltered = goalsList.filter((goal) =>
        (goal.firstName + ' ' + goal.lastName).includes(suggestion)
      );

      this.setDataTable(goalsListFiltered);
      this.setState({ goalsListFiltered });
    }

    if (suggestion.length == 0) {
      let goalsList = this.state.goalsList;
      let goalsListFiltered = goalsList;
      this.setDataTable(goalsListFiltered);
      this.setState({ goalsListFiltered });
    }
  };

  handlePageClick = (e, index) => {
    e.preventDefault();

    let dataCopy = this.state.data.slice();

    let dataSliced = dataCopy.slice(index * 20, (index + 1) * 20);

    this.setState({
      currentPage: index,
      dataCopy: dataSliced
    });
  };

  handlePreviousPage = (e) => {
    e.preventDefault();

    if (this.state.currentPage === 0) {
      return;
    }

    let dataCopy = this.state.data.slice();

    let dataSliced = dataCopy.slice(
      (this.state.currentPage - 1) * 20,
      (this.state.currentPage - 1 + 1) * 20
    );

    this.setState({
      currentPage: this.state.currentPage - 1,
      dataCopy: dataSliced
    });
  };

  handleNextPage = (e) => {
    e.preventDefault();

    let dataCopy = this.state.data.slice();

    let dataSliced = dataCopy.slice(
      (this.state.currentPage + 1) * 20,
      (this.state.currentPage + 1 + 1) * 20
    );

    this.setState({
      currentPage: this.state.currentPage + 1,
      dataCopy: dataSliced
    });
  };

  openAddUserModal = () => {
    this.setState({
      addUserModal: !this.state.addUserModal
    });
  };

  handleCategories = (e) => {
    console.log(e.target.checked, e.target.value);

    if (e.target.checked === true) {
      let checkIfExists = this.state.categories.category.filter(
        (value, index) => this.state.categories.category.indexOf(value) === index
      );

      this.setState({
        categories: {
          category: [...checkIfExists, e.target.value]
        }
      });
    } else {
      const findPosition = this.state.categories.category.findIndex(
        (category) => category === e.target.value
      );

      this.state.categories.category.splice(findPosition, 1);
    }
  };

  handleRegions = (e) => {
    let value = parseInt(e.target.value);

    if (e.target.checked === true) {
      let regions = this.state.regions;
      let region = this.state.regionsList.find((r) => r.id === value);

      regions.push(region);
      this.setState({ regions });
    } else {
      let regions = this.state.regions.filter((r) => r.id !== value);

      this.setState({ regions });
    }
  };

  handleStoreCategories = (e) => {
    let value = parseInt(e.target.value);

    if (e.target.checked === true) {
      let storeCategories = this.state.storeCategories;
      let storeCategory = this.state.storeCategoriesList.find((category) => category.id === value);

      storeCategories.push(storeCategory);
      this.setState({ storeCategories });
    } else {
      let storeCategories = this.state.storeCategories.filter((category) => category.id !== value);

      this.setState({ storeCategories });
    }
  };

  handleSubmit = async () => {
    let firstName = this.state.firstName;
    let lastName = this.state.lastName;
    let email = this.state.email;
    let password = this.state.password;
    let profileId = this.state.profileId;
    let categories = JSON.stringify(this.state.categories.category);
    let storeCategories = this.state.storeCategories.map((c) => c.id);
    let regions = this.state.regions.map((r) => r.id);

    try {
      this.setState({ loading: true });
      let res = (
        await axios.post(
          baseURL + '/users',
          {
            firstName,
            lastName,
            email,
            password,
            profileId,
            categories,
            regions,
            storeCategories
          },
          {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('banana')
            }
          }
        )
      ).data;
      this.setState({ loading: false, addUserModal: false });
      this.getUsers();
    } catch (err) {
      console.error(err);
      this.setState({
        loading: false,
        error: true
      });
      setTimeout(() => {
        this.setState({ error: false });
      }, 4000);
    }
  };

  render() {
    return (
      <>
        {this.state.loading && <Loader />}
        {this.state.error ? (
          <Alert color="danger" style={{ position: 'fixed', top: '30px', zIndex: 9999 }}>
            {this.state.errorText}
          </Alert>
        ) : (
          ''
        )}
        <NeutralHeader />
        {/* Page content */}
        <Container className="mt--7" fluid>
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0 d-flex justifycontent-spacebetween alignitems-center">
                  <h3 className="mb-0">Usuários</h3>
                  <Button
                    className="btn-icon btn-3 btn-add-user"
                    color="primary"
                    type="button"
                    onClick={() => this.openAddUserModal()}>
                    <span className="btn-inner--icon">
                      <i className="ni ni-fat-add" />
                    </span>
                    <span className="btn-inner--text">Adicionar usuário</span>
                  </Button>
                </CardHeader>

                <CardBody style={{ padding: 0 }}>
                  <RichTable
                    headers={this.state.headers}
                    data={this.state.dataCopy}
                    dataFilter={this.state.data}
                    actionColumns={[2, 3]}
                  />
                </CardBody>

                <CardFooter className="py-4">
                  <Pagination>
                    <PaginationItem>
                      <PaginationLink
                        previous
                        onClick={(e) => this.handlePreviousPage(e)}
                        href="#"
                      />
                    </PaginationItem>
                    {[...Array(this.state.pagesCount)].map((page, i) => (
                      <React.Fragment key={i}>
                        <PaginationItem active={i === this.state.currentPage}>
                          <PaginationLink onClick={(e) => this.handlePageClick(e, i)} href="#">
                            {i + 1}
                          </PaginationLink>
                        </PaginationItem>
                      </React.Fragment>
                    ))}
                    <PaginationItem>
                      <PaginationLink next onClick={(e) => this.handleNextPage(e)} href="#" />
                    </PaginationItem>
                  </Pagination>
                </CardFooter>
              </Card>
            </div>
          </Row>

          <Modal
            className="modal-dialog-centered"
            size="sm"
            isOpen={this.state.addUserModal}
            toggle={() => this.openAddUserModal()}>
            <div className="modal-body p-0">
              <Card className="bg-secondary shadow border-0">
                <CardHeader className="bg-transparent pb-5">
                  <div className="text-muted text-center mt-2 mb-3">
                    <h3>Adicionar um usuário</h3>
                  </div>
                </CardHeader>
                <CardBody className="px-lg-5 py-lg-5">
                  <Form role="form">
                    <FormGroup className="mb-3">
                      <InputGroup className="input-group-alternative">
                        <InputGroupText addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-caps-small" />
                          </InputGroupText>
                        </InputGroupText>
                        <Input
                          placeholder="Nome"
                          type="text"
                          onChange={(e) => this.setState({ firstName: e.target.value })}
                        />
                      </InputGroup>
                    </FormGroup>
                    <FormGroup className="mb-3">
                      <InputGroup className="input-group-alternative">
                        <InputGroupText addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-caps-small" />
                          </InputGroupText>
                        </InputGroupText>
                        <Input
                          placeholder="Sobrenome"
                          type="text"
                          onChange={(e) => this.setState({ lastName: e.target.value })}
                        />
                      </InputGroup>
                    </FormGroup>
                    <FormGroup className="mb-3">
                      <InputGroup className="input-group-alternative">
                        <InputGroupText addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-email-83" />
                          </InputGroupText>
                        </InputGroupText>
                        <Input
                          placeholder="Email"
                          type="email"
                          onChange={(e) => this.setState({ email: e.target.value })}
                        />
                      </InputGroup>
                    </FormGroup>
                    <FormGroup className="mb-3">
                      <InputGroup className="input-group-alternative">
                        <InputGroupText addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-lock-circle-open" />
                          </InputGroupText>
                        </InputGroupText>
                        <Input
                          placeholder="Senha"
                          type="password"
                          onChange={(e) => this.setState({ password: e.target.value })}
                        />
                      </InputGroup>
                    </FormGroup>
                    <FormGroup className="mb-3">
                      <InputGroup className="input-group-alternative">
                        <InputGroupText addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-circle-08" />
                          </InputGroupText>
                        </InputGroupText>
                        <Input
                          type="select"
                          name="profileId"
                          id="profileId"
                          onChange={(e) => {
                            this.setState({ profileId: e.target.value });
                          }}
                          value={this.state.profileId}
                          placeholder="Selecione um perfil">
                          {this.state.profilesList.map((profile) => {
                            return (
                              <option key={profile.id} value={profile.id}>
                                {profile.name}
                              </option>
                            );
                          })}
                        </Input>
                      </InputGroup>
                    </FormGroup>
                    <div className="custom-control custom-control-alternative custom-checkbox mb-3">
                      <h3>Selecione as regiões do usuário: </h3>
                    </div>
                    {this.state.regionsList.map((region, key) => (
                      <div
                        key={`region${key}`}
                        className="custom-control custom-control-alternative custom-checkbox mb-3">
                        <input
                          className="custom-control-input"
                          id={`checkRegion${key}`}
                          type="checkbox"
                          value={region.id}
                          onClick={(e) => this.handleRegions(e)}
                        />
                        <label className="custom-control-label" htmlFor={`checkRegion${key}`}>
                          {region.descr_pt}
                        </label>
                      </div>
                    ))}
                    <div className="custom-control custom-control-alternative custom-checkbox mb-3">
                      <h3>Selecione as categorias de lojas: </h3>
                    </div>
                    {this.state.storeCategoriesList.map((storeCategory, key) => (
                      <div
                        key={`storeCategory${key}`}
                        className="custom-control custom-control-alternative custom-checkbox mb-3">
                        <input
                          className="custom-control-input"
                          id={`checkStoreCategory${key}`}
                          type="checkbox"
                          value={storeCategory.id}
                          onClick={(e) => this.handleStoreCategories(e)}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor={`checkStoreCategory${key}`}>
                          {storeCategory.descr_pt}
                        </label>
                      </div>
                    ))}
                    <div className="text-center">
                      <Button
                        className="my-2"
                        style={{ backgroundColor: '#5e72e4', color: '#fff' }}
                        type="button"
                        onClick={() => this.handleSubmit()}>
                        Salvar
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </div>
          </Modal>
        </Container>
      </>
    );
  }
}

export default Users;
