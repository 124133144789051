import React from 'react';
import { Card, CardBody, CardHeader, Row } from 'reactstrap';
import PercentageChart from '../../../../../components/Statistics/PercentageChart';

export default function SalaEsperaChart({ data }) {
  const { progress, chart } = data;

  return (
    <Card className="shadow borderradius-default">
      <CardHeader className="bg-transparent">
        <Row className="align-items-center">
          <div className="col">
            <h6 className="text-uppercase text-light ls-1 mb-1"> PDV </h6>
            <h2 className="mb-0">Sala de espera</h2>
          </div>
        </Row>
      </CardHeader>

      <CardBody className="p-0 pb-3">
        <PercentageChart data={progress && progress.hasWaitingRoom} title="Possui sala de espera" />

        <PercentageChart data={progress && progress.airConditioning} title="Ar-condicionado" />

        <PercentageChart data={progress && progress.coffe} title="Café" />

        <PercentageChart data={progress && progress.water} title="Água" />

        <PercentageChart data={progress && progress.tvOn} title="TV ligada" />

        <PercentageChart
          data={progress && progress.freeWifi}
          title="Internet com wifi e senha disponível"
        />
      </CardBody>
    </Card>
  );
}
