import React, { useEffect, useState } from 'react';
import { Alert, Button, Col, Container, Nav } from 'reactstrap';
import Loader from '../../../components/Bridgestone/Loader';
import axios from 'axios';
import baseURL from '../../../variables/api';
import NotificationsModalAddEdit from '../../../components/NotificationCrud/ModalAddEdit';
import NotificationsModalRemove from '../../../components/NotificationCrud/ModalRemove';

const NotificationsCrud = () => {
  const [notifications, setNotifications] = useState([]);
  const [categories, setCategories] = useState([]);
  const [clients, setClients] = useState([]);
  const [icons, setIcons] = useState([]);
  const [sessions, setSessions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(undefined);

  const [selectedNotification, setSelectedNotification] = useState(undefined);
  const [openedModalAddEdit, setOpenedModalAddEdit] = useState(false);
  const [openedModalRemove, setOpenedModalRemove] = useState(false);

  useEffect(() => {
    handleRefreshCategories();
    handleRefreshClients();
    handleRefreshIcons();
    handleRefreshSessions();
    handleRefreshNotifications();
  }, []);

  const handleRefreshCategories = async () => {
    setLoading(true);

    try {
      const response = (
        await axios.get(`${baseURL}/peb/categories`, {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('banana')
          }
        })
      ).data;
      setCategories(response);
    } catch (err) {
      handleError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleRefreshClients = async () => {
    setLoading(true);

    try {
      const response = (
        await axios.get(`${baseURL}/peb/clients`, {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('banana')
          }
        })
      ).data;
      setClients(response);
    } catch (err) {
      handleError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleRefreshIcons = async () => {
    setLoading(true);

    try {
      const response = (
        await axios.get(`${baseURL}/uptown/notifications/icons`, {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('banana')
          }
        })
      ).data;
      setIcons(response);
    } catch (err) {
      handleError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleRefreshSessions = async () => {
    setLoading(true);

    try {
      const response = (
        await axios.get(`${baseURL}/uptown/notifications/sessions`, {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('banana')
          }
        })
      ).data;
      setSessions(response);
    } catch (err) {
      handleError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleRefreshNotifications = async () => {
    setLoading(true);

    try {
      const response = (
        await axios.get(`${baseURL}/uptown/notifications`, {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('banana')
          }
        })
      ).data;
      setNotifications(response);
    } catch (err) {
      handleError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleError = (message, color = 'danger') => {
    setError({ message, color });

    setTimeout(() => {
      setError(undefined);
    }, 4000);
  };

  return (
    <>
      {/* Page content */}
      {loading && <Loader />}
      {error ? (
        <Alert color={error.color} style={{ position: 'fixed', top: '30px', zIndex: 9999 }}>
          {error.message}
        </Alert>
      ) : (
        ''
      )}

      <Container className="mt-2 mt-md-5 mb-2 action-plan-container" fluid>
        <Nav
          className="mt-6 nav-fill flex-column flex-sm-row justify-content-flex-end"
          pills
          role="tablist"
        >
          <Button
            size="sm"
            onClick={(e) => {
              setSelectedNotification(undefined);
              setOpenedModalAddEdit(true);
            }}
          >
            Adicionar +
          </Button>
        </Nav>

        {/* CONTENT */}
        <Col className="p-0 mt-3">
          <div className="card">
            <div className="card-content table-responsive">
              <table className="table align-items-center">
                <thead className="thead-light">
                  <tr>
                    <th>TÍTULO</th>
                    <th>CATEGORIA</th>
                    <th>DESCRIÇÃO</th>
                    <th>ÍCONE</th>
                    <th>SESSÃO</th>
                    <th style={{ textAlign: 'center' }}>AÇÕES</th>
                  </tr>
                </thead>
                <tbody>
                  {notifications.map((notification, k) => {
                    const category = categories.filter((c) =>
                      notification.categories.includes(c.id)
                    );
                    const icon = icons.find((i) => i.id == notification.icon);
                    const session = sessions.find((s) => s.id == notification.session);

                    return (
                      <tr key={k}>
                        <td style={{ whiteSpace: 'break-spaces' }}>{notification.title}</td>

                        <td>{category.map((c) => c.descr_pt).join(', ')}</td>

                        <td style={{ whiteSpace: 'break-spaces' }}>{notification.description}</td>

                        <td>
                          <img
                            src={icon ? icon.url : notification.custom_icon}
                            style={{ width: '20px', marginRight: '12px' }}
                          />
                          {icon ? icon.title : 'OUTRO'}
                        </td>

                        <td>{session ? session.title : 'Nenhuma'}</td>

                        <td style={{ textAlign: 'center' }}>
                          <Button
                            className={`background-secondary color-white`}
                            title="Editar"
                            onClick={(e) => {
                              setSelectedNotification(notification);
                              setOpenedModalAddEdit(true);
                            }}
                          >
                            <i className="fas fa-pen" />
                          </Button>

                          <Button
                            className={`background-primary color-white`}
                            title="Remover"
                            onClick={(e) => {
                              setSelectedNotification(notification);
                              setOpenedModalRemove(true);
                            }}
                          >
                            <i className="fas fa-trash" />
                          </Button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </Col>
      </Container>

      <NotificationsModalAddEdit
        notification={selectedNotification}
        categories={categories}
        clients={clients}
        icons={icons}
        sessions={sessions}
        isOpen={openedModalAddEdit}
        handleClose={(_) => setOpenedModalAddEdit(false)}
        handleRefresh={handleRefreshNotifications}
        setLoading={setLoading}
        setError={handleError}
      />

      <NotificationsModalRemove
        notification={selectedNotification}
        isOpen={openedModalRemove}
        handleClose={(_) => setOpenedModalRemove(false)}
        handleRefresh={handleRefreshNotifications}
        setLoading={setLoading}
        setError={handleError}
      />
    </>
  );
};

export default NotificationsCrud;
