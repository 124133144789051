export const fieldsProp = {
  codigoLoja: {
    name: 'codigoloja',
    step: 1
  },
  codigoAvo: {
    name: 'codigoavo',
    step: 1
  },
  cnpj: {
    name: 'cnpj',
    step: 1
  },
  razaoSocial: {
    name: 'razaosocial',
    step: 1
  },
  nomeFantasia: {
    name: 'nomefantasia',
    step: 1
  },
  regiao: {
    name: 'regiao',
    step: 1
  },
  tipoLoja: {
    name: 'tipoloja',
    step: 1
  },
  categoriaLoja: {
    name: 'categorialoja',
    step: 1
  },
  gerente: {
    name: 'gerente',
    step: 1
  },
  assessor: {
    name: 'assessor',
    step: 1
  },
  responsaveis: {
    step: 2,
    name: 'responsaveis',
    attributes: {
      nome: 'nome',
      cargo: 'cargo',
      email: 'email',
      telefone: 'telefone'
    }
  },
  cep: {
    name: 'cep',
    step: 3
  },
  logradouro: {
    name: 'logradouro',
    step: 3
  },
  logradouroNumero: {
    name: 'logradouroNumero',
    step: 3
  },
  bairro: {
    name: 'bairro',
    step: 3
  },
  complemento: {
    name: 'complemento',
    step: 3
  },
  cidade: {
    name: 'cidade',
    step: 3
  },
  uf: {
    name: 'uf',
    step: 3
  },
  telefone: {
    name: 'telefone',
    step: 4
  },
  telefoneSecundario: {
    name: 'telefonesecundario',
    step: 4
  },
  email: {
    name: 'email',
    step: 4
  },
  site: {
    name: 'site',
    step: 4
  },
  siteEcommerce: {
    name: 'siteecommerce',
    step: 4
  },
  facebook: {
    name: 'facebook',
    step: 4
  },
  instagram: {
    name: 'instagram',
    step: 4
  }
};

export const tipoLojaData = [
  {
    value: 'Y',
    text: 'Matriz'
  },
  {
    value: 'N',
    text: 'Filial'
  }
];

export const siteEcommerceData = [
  {
    value: 'N',
    text: 'Não'
  },
  {
    value: 'Y',
    text: 'Sim'
  }
];
