import Axios from 'axios';
import baseURL from '../variables/api';

const authToken = localStorage.getItem('banana');

const List = async (token, params = {}) => {
  return await Axios.get(`${baseURL}/peb/clients`, {
    headers: {
      Authorization: 'Bearer ' + token
    },
    params: params
  });
};

const UploadAcbBudgetFile = async (formData) => {
  return await Axios.post(`${baseURL}/uptown/acb-budget`, formData, {
    headers: {
      Authorization: 'Bearer ' + authToken
    }
  });
};

const UploadMysteryShopperFile = async (token, categoryId, formData) => {
  return await Axios.post(`${baseURL}/uptown/mystery-shopper/${categoryId}`, formData, {
    headers: {
      Authorization: 'Bearer ' + token
    }
  });
};

const EditAdvisor = async (token, storeCode, formData) => {
  return await Axios.put(
    `${baseURL}/uptown/peb-client-advisor/${storeCode}`,
    Object.fromEntries(formData),
    {
      headers: {
        Authorization: 'Bearer ' + token
      }
    }
  );
};

const Unsubscribe = async (token, storeCode, categoryId) => {
  return await Axios.delete(`${baseURL}/uptown/peb-unsubscribe/${storeCode}/${categoryId}`, {
    headers: {
      Authorization: 'Bearer ' + token
    }
  });
};

const StoreService = {
  List,
  UploadAcbBudgetFile,
  UploadMysteryShopperFile,
  EditAdvisor,
  Unsubscribe
};

export default StoreService;
