import React, { useContext, useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import DefaultTextField from '../../../components/TextField/TextField';
import DefaultButton from '../../../components/Button/Button';
import MaskHelper from '../../../helpers/MaskHelper';
import FormHelper from '../../../helpers/FormHelper';
import ApiHelper from '../../../helpers/ApiHelper';
import DefaultSelectField from '../../../components/SelectField/SelectField';
import { siteEcommerceData } from '../../../data/ExcellenceSubscriptionData';
import ProfilesService from '../../../services/ProfilesService';

const ReformerAddress = ({
  data,
  token,
  storeCode,
  category,
  refreshData,
  setLoading,
  setError
}) => {
  const [editando, setEditando] = useState(false);

  const [cep, setCep] = useState('');
  const [logradouro, setLogradouro] = useState('');
  const [numero, setNumero] = useState('');
  const [bairro, setBairro] = useState('');
  const [complemento, setComplemento] = useState('');
  const [cidade, setCidade] = useState('');
  const [uf, setUf] = useState('');

  const [telefone, setTelefone] = useState('');
  const [telefoneSecundario, setTelefoneSecundario] = useState('');
  const [siteEcommerce, setSiteEcommerce] = useState('');
  const [email, setEmail] = useState('');
  const [site, setSite] = useState('');
  const [facebook, setFacebook] = useState('');
  const [instagram, setInstagram] = useState('');
  const [responsibleName, setResponsibleName] = useState('');
  const [responsiblePosition, setResponsiblePosition] = useState('');

  useEffect(() => {
    setCep(data && data.zipcode ? MaskHelper.Cep(data.zipcode, false) : '');
    setLogradouro(data && data.adress ? data.adress : '');
    setNumero(data && data.adress_number ? data.adress_number : '');
    setBairro(data && data.district ? data.district : '');
    setComplemento(data && data.adress_extra ? data.adress_extra : '');
    setCidade(data && data.city ? data.city : '');
    setUf(data && data.state ? data.state : '');

    setTelefone(data && data.business_phone1 ? MaskHelper.Phone(data.business_phone1, false) : '');
    setTelefoneSecundario(
      data && data.business_phone2 ? MaskHelper.Phone(data.business_phone2, false) : ''
    );
    setSiteEcommerce(data && data.is_ecommerce ? data.is_ecommerce : '');
    setEmail(data && data.email ? data.email : '');
    setSite(data && data.website ? data.website : '');
    setFacebook(data && data.facebook ? data.facebook : '');
    setInstagram(data && data.instagram ? data.instagram : '');
    setResponsibleName(data && data.responsible_name ? data.responsible_name : '');
    setResponsiblePosition(data && data.responsible_position ? data.responsible_position : '');
  }, [data]);

  const clearAllAddress = () => {
    setCep('');
    setLogradouro('');
    setBairro('');
    setCidade('');
    setUf('');
  };

  const handleFindCep = () => {
    if (cep.length > 0) {
      const validCep = FormHelper.ValidateCep(cep);

      if (validCep) {
        setLoading(true);

        ApiHelper.FindCep(cep)
          .then((resp) => {
            if (resp.data.erro) {
              return;
            }

            setCep(resp.data.cep || '');
            setLogradouro(resp.data.logradouro || '');
            setBairro(resp.data.bairro || '');
            setCidade(resp.data.localidade || '');
            setUf(resp.data.uf || '');
          })
          .catch((err) => {
            // clearAllAddress();
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        clearAllAddress();
        setError('CEP inválido');
      }
    }
  };

  const handleValidatePhone = (phone, setter) => {
    if (phone.length > 0) {
      const validPhone = FormHelper.ValidatePhone(phone);

      if (validPhone) {
        setter(phone);
      } else {
        setter('');
        setError('Telefone inválido');
      }
    }
  };

  const handleValidateEmail = (email, setter) => {
    if (email.length > 0) {
      const validEmail = FormHelper.ValidateEmail(email);

      if (validEmail) {
        setter(email);
      } else {
        setter('');
        setError('E-mail inválido');
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setLoading(true);

    const formData = FormHelper.GenerateFormData(e);
    ProfilesService.EditAddress(token, storeCode, category, formData)
      .then((resp) => {
        setEditando(false);
        refreshData();

        setError(resp.data.message, 'success');
      })
      .catch((err) => {
        if (err.response.data.errors.length > 0) {
          setError(err.response.data.errors[0].message);
        } else {
          setError('Ocorreu um erro ao tentar salvar as alterações');
          console.error({ ...err });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Grid container padding={3} component="form" onSubmit={(e) => handleSubmit(e)}>
      <Grid item xs={12} marginBottom={2}>
        <Grid container spacing={2} alignItems="center">
          <Grid item lg={6} xs={12}>
            <h3 className="text-uppercase mb-0">Endereço da revenda</h3>
          </Grid>

          <Grid item lg={6} xs={12} textAlign="right">
            <DefaultButton
              label="Editar"
              type="button"
              className="background-primary fontsize-12"
              onClick={() => setEditando(true)}
              fullWidth={false}
              size="small"
              disabled={editando}
              sx={{
                marginRight: 2
              }}
            />

            <DefaultButton
              label="Salvar"
              type="submit"
              className="background-primary fontsize-12"
              fullWidth={false}
              size="small"
              disabled={!editando}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container>
          <Grid item lg={4} xs={12} className="paddingright-8px padding-none-lg">
            <DefaultTextField
              label="CEP"
              name="cep"
              value={cep}
              disabled={!editando}
              onChange={(e) =>
                setCep(MaskHelper.Cep(e.target.value, cep.length > e.target.value.length))
              }
              onBlur={handleFindCep}
            />
          </Grid>

          <Grid item lg={8} xs={12} className="paddingleft-8px padding-none-lg">
            <DefaultTextField
              label="Endereço"
              name="logradouro"
              value={logradouro}
              disabled={!editando}
              onChange={(e) => setLogradouro(e.target.value)}
            />
          </Grid>

          <Grid item lg={2} xs={12} className="paddingright-8px padding-none-lg">
            <DefaultTextField
              label="Número"
              name="logradouroNumero"
              value={numero}
              disabled={!editando}
              onChange={(e) => setNumero(e.target.value)}
            />
          </Grid>

          <Grid item lg={3} xs={12} className="paddingright-8px paddingleft-8px padding-none-lg">
            <DefaultTextField
              label="Bairro"
              name="bairro"
              value={bairro}
              disabled={!editando}
              onChange={(e) => setBairro(e.target.value)}
            />
          </Grid>

          <Grid item lg={2} xs={12} className="paddingright-8px paddingleft-8px padding-none-lg">
            <DefaultTextField
              label="Complemento"
              name="complemento"
              value={complemento}
              disabled={!editando}
              onChange={(e) => setComplemento(e.target.value)}
              required={false}
            />
          </Grid>

          <Grid item lg={3} xs={12} className="paddingright-8px paddingleft-8px padding-none-lg">
            <DefaultTextField
              label="Cidade"
              name="cidade"
              value={cidade}
              disabled={!editando}
              onChange={(e) => setCidade(e.target.value)}
              required={true}
            />
          </Grid>

          <Grid item lg={2} xs={12} className="paddingleft-8px padding-none-lg">
            <DefaultTextField
              label="UF"
              name="uf"
              value={uf}
              disabled={!editando}
              onChange={(e) => setUf(e.target.value.toUpperCase())}
              required={true}
              maxlength={2}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} marginBottom={2} marginTop={4}>
        <h3 className="text-uppercase mb-0">
          Dados da pessoa responsável pelo programa dentro da revenda
        </h3>
      </Grid>

      <Grid item xs={12}>
        <Grid container>
          <Grid item lg={4} xs={12} className="paddingright-8px paddingleft-8px padding-none-lg">
            <DefaultTextField
              label="Nome do responsável"
              name="responsible_name"
              value={responsibleName}
              disabled={!editando}
              onChange={(e) => setResponsibleName(e.target.value)}
            />
          </Grid>

          <Grid item lg={4} xs={12} className="paddingright-8px padding-none-lg">
            <DefaultTextField
              label="Telefone para contato"
              name="telefone"
              value={telefone}
              disabled={!editando}
              onChange={(e) =>
                setTelefone(
                  MaskHelper.Phone(e.target.value, telefone.length > e.target.value.length)
                )
              }
              onBlur={(_) => handleValidatePhone(telefone, setTelefone)}
            />
          </Grid>

          <Grid item lg={4} xs={12} className="paddingright-8px paddingleft-8px padding-none-lg">
            <DefaultTextField
              label="Cargo do responsável"
              name="responsible_position"
              value={responsiblePosition}
              disabled={!editando}
              onChange={(e) => setResponsiblePosition(e.target.value)}
            />
          </Grid>

          <Grid item lg={6} xs={12} className="paddingright-8px paddingleft-8px padding-none-lg">
            <DefaultTextField
              label="E-mail do responsável"
              name="email"
              value={email}
              disabled={!editando}
              onChange={(e) => setEmail(e.target.value)}
              onBlur={(_) => handleValidateEmail(email, setEmail)}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ReformerAddress;
