import React from 'react';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Typography,
  Button,
  Grid,
  Divider
} from '@mui/material';
import TaskService from '../../services/TaskService';
import ExtraScoresService from '../../services/ExtraScoresService';
import RulesAndLicensesService from '../../services/RulesAndLicensesService';

const types = {
  T: 'Plano de ação',
  E: 'Pontuação extra',
  R: 'Norma/Licença'
};

const DialogValidateTask = ({
  isOpen,
  setClosedDialog = () => {},
  type,
  task,
  handleRefresh,
  setLoading,
  setError
}) => {
  const handleSubmit = (e) => {
    e.preventDefault();

    setLoading(true);

    if (type === 'T') {
      TaskService.ValidateTask(task.id)
        .then((resp) => {
          setError(`Tarefa validada com sucesso`, 'success');
          setClosedDialog();
          handleRefresh();
        })
        .catch((err) => {
          setError('Não foi possível validar a tarefa');
        })
        .finally(() => {
          setLoading(false);
        });
    }

    if (type === 'E') {
      ExtraScoresService.AdvisorValidateExtraScore(task.id)
        .then((resp) => {
          setError(`Pontuação extra validada com sucesso`, 'success');
          setClosedDialog();
          handleRefresh();
        })
        .catch((err) => {
          setError('Não foi possível validar a pontuação extra');
        })
        .finally(() => {
          setLoading(false);
        });
    }

    if (type === 'R') {
      RulesAndLicensesService.Validate(task.id)
        .then((resp) => {
          setError(`Norma/licença validada com sucesso`, 'success');
          setClosedDialog();
          handleRefresh();
        })
        .catch((err) => {
          setError('Não foi possível validar a norma/licença');
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <Dialog
      open={isOpen}
      maxWidth="xs"
      fullWidth={true}
      onClose={setClosedDialog}
      PaperProps={{
        className: `dialog-default dialog-audit-validatetask`
      }}
    >
      <Box component="form" onSubmit={(e) => handleSubmit(e)}>
        <DialogTitle className="dialogtitle-default" sx={{ paddingTop: 4 }}>
          <Typography variant="subtitle1" className="fontweight-600 textalign-center">
            Tem certeza que deseja validar?
          </Typography>
        </DialogTitle>

        <DialogContent className="dialogcontent-default">
          <Grid container paddingX={2} paddingTop={2}>
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions
          className="dialogactions-default"
          sx={{
            paddingX: 4,
            paddingBottom: 4,
            justifyContent: 'space-between'
          }}
        >
          <Button
            type="button"
            className={`borderradius-default background-grey-3 color-black`}
            size="small"
            onClick={setClosedDialog}
          >
            Não
          </Button>

          <Button
            type="submit"
            className="background-red color-white borderradius-default"
            size="small"
          >
            Sim
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default DialogValidateTask;
