import Axios from 'axios';
import baseURL from '../variables/api';

const List = async (token) => {
  return await Axios.get(`${baseURL}/uptown/reports`, {
    headers: {
      Authorization: 'Bearer ' + token
    }
  });
};

const Export = async (token, url, filters) => {
  const params = Object.fromEntries(filters);

  return await Axios.get(`${baseURL}${url}`, {
    headers: {
      Authorization: 'Bearer ' + token
    },
    responseType: 'blob',
    params: params
  });
};

const ReportService = {
  List,
  Export
};

export default ReportService;
