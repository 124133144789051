import React, { useEffect, useState } from 'react';
import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from 'reactstrap';
import FormHelper from '../../helpers/FormHelper';
import AdvisorService from '../../services/AdvisorService';
import StoreService from '../../services/StoreService';

const StoresModalEdit = ({
  store,
  isOpen,
  handleClose,
  handleRefresh,
  setLoading,
  setError,
  token
}) => {
  const [advisorList, setAdvisorList] = useState([]);

  const [assessor, setAssessor] = useState('');

  useEffect(() => {
    AdvisorService.List().then((resp) => {
      setAdvisorList(resp.data);
    });
  }, []);

  useEffect(() => {
    if (store) {
      setAssessor(store.storeAdvisor.id);
    }
  }, [isOpen]);

  const handleEditAction = (e) => {
    e.preventDefault();

    let formData = FormHelper.GenerateFormData(e, null);

    setLoading(true);

    StoreService.EditAdvisor(token, store.code, formData)
      .then((resp) => {
        setError('Alteração realizada com sucesso', 'success');
        handleClose();
        handleRefresh();
      })
      .catch((err) => {
        setError('Ocorreu um erro ao tentar realizar a operação');
        console.log(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <Modal isOpen={isOpen} toggle={handleClose}>
        <form onSubmit={(e) => handleEditAction(e)}>
          <ModalHeader>
            <h3>Editar</h3>
          </ModalHeader>

          <ModalBody className="pt-0">
            <Row>
              <Col className="col-12">
                <FormGroup>
                  <Label for="advisor">Assessor:</Label>
                  <Input
                    type="select"
                    id="advisor"
                    name="advisor"
                    value={assessor}
                    onChange={(e) => setAssessor(e.target.value)}
                  >
                    {advisorList.map((advisor, index) => {
                      return (
                        <option value={advisor.id} key={index}>
                          {' '}
                          {advisor.name}{' '}
                        </option>
                      );
                    })}
                  </Input>
                </FormGroup>
              </Col>
            </Row>
          </ModalBody>

          <ModalFooter
            style={{
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <Button className="background-primary color-white" onClick={handleClose}>
              Voltar
            </Button>

            <Button type="submit" className="background-secondary color-white">
              Salvar
            </Button>
          </ModalFooter>
        </form>
      </Modal>
    </>
  );
};

export default StoresModalEdit;
