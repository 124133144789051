import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Alert, Card, CardBody, Container, Row, Col, CardHeader } from 'reactstrap';

import axios from 'axios';
import Loader from '../../components/Bridgestone/Loader.jsx';
import baseURL from '../../variables/api';

class SelectForm extends React.Component {
  state = {
    forms: [],
    categories: [],
    colors: [
      '#ED1C24',
      '#D6161D',
      '#AE0E14',
      '#990C13',
      '#69060A',
      '#990C13',
      '#AE0E14',
      '#D6161D',
      '#ED1C24',
      '#D6161D',
      '#AE0E14'
    ],
    show: [false, false, false, false, false, false]
  };

  async componentDidMount() {
    await this.getForms();
  }

  getForms = async () => {
    await this.setState({ loading: true });
    let forms;
    try {
      forms = (
        await axios.get(baseURL + '/peb/forms', {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('banana')
          }
        })
      ).data.userForms;

      let categories = [];
      for (let f of forms) {
        if (!categories.includes(f.category)) categories.push(f.category);
      }
      this.setState({
        forms,
        categories
      });
    } catch (err) {
      localStorage.removeItem('banana');
      this.setState({ error: true, errorText: err.message });
      setTimeout(() => {
        this.setState({ error: false, toLogin: true });
      }, 4000);
    }
    await this.setState({ loading: false });
  };

  render() {
    if (this.state.toLogin) {
      this.setState({ toLogin: false });
      return (
        <Redirect
          push
          to={{
            pathname: '/auth/login/'
          }}
        />
      );
    }
    return (
      <>
        {this.state.loading && <Loader />}
        {this.state.error ? (
          <Alert color="danger" style={{ position: 'fixed', top: '30px', zIndex: 9999 }}>
            {this.state.errorText}
          </Alert>
        ) : (
          ''
        )}
        <div className="pb-8 m-0 pt-md-8">
          <Container fluid className="m-0 mt-md-5 p-0">
            <div className="m-3">
              <h3>Selecione o Questionário:</h3>
            </div>

            <Row className="m-0">
              {this.state.categories.map((category, index) => (
                <Col xs="12" className="m-0 p-0" key={index}>
                  <Card className="shadow text-white font-weight-bold card-full">
                    <CardHeader
                      className="card-header background-primary"
                      onClick={(e) => {
                        let show = this.state.show;
                        show[index] = !show[index];
                        this.setState({ show });
                      }}
                    >
                      <Row>
                        <Col xs="10" className="m-0 p-0">
                          {category}
                        </Col>
                        <Col xs="2" className="m-0 p-0 text-center">
                          <i className="ni ni-bold-down" color="white"></i>
                        </Col>
                      </Row>
                    </CardHeader>
                    {
                      /*this.state.show[index] && */ <CardBody className="p-3" color="white">
                        {this.state.forms
                          .filter((f) => f.category == category)
                          .map((form, indexForm) => (
                            <React.Fragment key={indexForm}>
                              <Link
                                to={{
                                  pathname: '/admin/questions',
                                  state: {
                                    form
                                  }
                                }}
                                key={'f' + indexForm}
                              >
                                <h2>{form.title}</h2>
                              </Link>
                            </React.Fragment>
                          ))}
                      </CardBody>
                    }
                  </Card>
                </Col>
              ))}
            </Row>
          </Container>
        </div>
      </>
    );
  }
}

export default SelectForm;
