import Axios from 'axios';
import baseURL from '../variables/api';

const authToken = localStorage.getItem('banana');

const List = async (params = {}) => {
  return await Axios.get(`${baseURL}/bandag-assessments`, {
    headers: {
      Authorization: 'Bearer ' + authToken
    },
    params: params
  });
};

const Submit = async (formData) => {
  return await Axios.post(`${baseURL}/bandag-assessments`, formData, {
    headers: {
      Authorization: 'Bearer ' + authToken
    }
  });
};

const TechnicalAssessmentService = {
  List,
  Submit
};

export default TechnicalAssessmentService;
