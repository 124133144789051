import React, { useEffect, useState } from 'react';
import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from 'reactstrap';
import axios from 'axios';
import baseURL from '../../variables/api';
import FormHelper from '../../helpers/FormHelper';

const NotificationsModalAddEdit = ({
  notification,
  categories,
  clients,
  icons,
  sessions,
  isOpen,
  handleClose,
  handleRefresh,
  setLoading,
  setError
}) => {
  const [titulo, setTitulo] = useState('');
  const [categorias, setCategorias] = useState([]);
  const [descricao, setDescricao] = useState('');
  const [clientes, setClientes] = useState([]);
  const [icone, setIcone] = useState(0);
  const [sessao, setSessao] = useState(0);
  const [iconeCustomizado, setIconeCustomizado] = useState(undefined);
  const [linkIconeCustomizadoAtual, setLinkIconeCustomizadoAtual] = useState('');

  const [buscarClientes, setBuscarClientes] = useState('');

  useEffect(() => {
    if (isOpen) {
      setTitulo(notification ? notification.title : '');
      setCategorias(notification ? notification.categories : []);
      setDescricao(notification ? notification.description : '');
      setIcone(notification ? notification.icon || 0 : 0);
      setSessao(notification ? notification.session : 0);
      setIconeCustomizado(undefined);
      setLinkIconeCustomizadoAtual(notification ? notification.custom_icon : '');

      let clientesAux = notification ? notification.clients : [];
      clientesAux = clientesAux.filter((item, index) => clientesAux.indexOf(item) == index);

      if (JSON.stringify(clients.map((c) => c.id).sort()) == JSON.stringify(clientesAux.sort())) {
        clientesAux = [0];
      }

      setClientes(clientesAux);
      setBuscarClientes('');
    }
  }, [isOpen]);

  const handleAddEditAction = (e) => {
    e.preventDefault();

    const clientesAux = clientes.filter((c) => c == '0').length > 0 ? [] : clientes;

    let formData = FormHelper.GenerateFormData(e, null);
    formData = FormHelper.IncludeKeyValue(formData, `clients`, JSON.stringify(clientesAux));
    formData = FormHelper.IncludeKeyValue(formData, `categories`, JSON.stringify(categorias));

    setLoading(true);

    let method = 'post';
    let url = `${baseURL}/uptown/notifications`;

    if (notification) {
      method = 'put';
      url = `${baseURL}/uptown/notifications/${notification.id}`;
    }

    axios[method](url, formData, {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('banana')
      }
    })
      .then((resp) => {
        setError(
          notification ? 'Alteração realizada com sucesso' : 'Inclusão realizada com sucesso',
          'success'
        );
        handleClose();
        handleRefresh();
      })
      .catch((err) => {
        setError('Ocorreu um erro ao tentar realizar a operação');
        console.log(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <Modal isOpen={isOpen} toggle={handleClose}>
        <form onSubmit={(e) => handleAddEditAction(e)}>
          <ModalHeader>
            <h3>{notification ? 'Editar' : 'Adicionar'}</h3>
          </ModalHeader>

          <ModalBody className="pt-0">
            <Row>
              <Col className="col-12">
                <FormGroup>
                  <Label for="title">Título:</Label>
                  <Input
                    type="text"
                    id="title"
                    name="title"
                    required
                    value={titulo}
                    onChange={(e) => setTitulo(e.target.value)}
                  />
                </FormGroup>
              </Col>

              <Col className="col-12">
                <FormGroup>
                  <Label for="description">Descrição:</Label>
                  <Input
                    type="textarea"
                    id="description"
                    name="description"
                    required
                    value={descricao}
                    onChange={(e) => setDescricao(e.target.value)}
                    style={{ resize: 'none' }}
                  />
                </FormGroup>
              </Col>

              <Col className="col-12">
                <FormGroup>
                  <Label for="categories">Categorias:</Label>
                  <Input
                    type="select"
                    id="categories"
                    required
                    multiple
                    value={categorias}
                    onChange={(e) =>
                      setCategorias(Array.from(e.target.selectedOptions, (option) => +option.value))
                    }
                  >
                    {categories.map((category, index) => {
                      return (
                        <option value={category.id} key={index}>
                          {' '}
                          {category.descr_pt}{' '}
                        </option>
                      );
                    })}
                  </Input>
                </FormGroup>
              </Col>

              <Col className="col-12">
                <FormGroup>
                  <Label for="searchClients">Buscar clientes:</Label>
                  <Input
                    type="text"
                    id="searchClients"
                    value={buscarClientes}
                    onChange={(e) => setBuscarClientes(e.target.value)}
                  />
                </FormGroup>
              </Col>

              <Col className="col-12">
                <FormGroup>
                  <Label for="clients">Clientes:</Label>
                  <Input
                    type="select"
                    id="clients"
                    required
                    multiple
                    value={clientes}
                    onChange={(e) =>
                      setClientes(Array.from(e.target.selectedOptions, (option) => +option.value))
                    }
                  >
                    <option value="0"> TODOS OS CLIENTES </option>
                    {clients
                      .filter((c) => {
                        const buscarClientesUC = buscarClientes.toUpperCase();
                        return (
                          buscarClientes.length == 0 ||
                          c.code.toUpperCase().indexOf(buscarClientesUC) > -1 ||
                          c.comercial_name.toUpperCase().indexOf(buscarClientesUC) > -1
                        );
                      })
                      .map((client, index) => {
                        return (
                          <option value={client.id} key={index}>
                            {' '}
                            {client.code} - {client.comercial_name}{' '}
                          </option>
                        );
                      })}
                  </Input>
                </FormGroup>
              </Col>

              <Col className="col-md-6 col-12">
                <FormGroup>
                  <Label for="icon">Ícone:</Label>
                  <Input
                    type="select"
                    id="icon"
                    name="icon"
                    required
                    value={icone}
                    onChange={(e) => setIcone(e.target.value)}
                  >
                    <option value={0}> OUTRO </option>

                    {icons.map((icon, index) => {
                      return (
                        <option value={icon.id} key={index}>
                          {' '}
                          {icon.title}{' '}
                        </option>
                      );
                    })}
                  </Input>
                </FormGroup>
              </Col>

              <Col className="col-md-6 col-12">
                <FormGroup>
                  <Label for="session">Sessão:</Label>
                  <Input
                    type="select"
                    id="session"
                    name="session"
                    required
                    value={sessao}
                    onChange={(e) => setSessao(e.target.value)}
                  >
                    <option value={0}> Nenhuma </option>

                    {sessions.map((session, index) => {
                      return (
                        <option value={session.id} key={index}>
                          {' '}
                          {session.title}{' '}
                        </option>
                      );
                    })}
                  </Input>
                </FormGroup>
              </Col>

              {icone == 0 && (
                <Col className="col-12">
                  <FormGroup>
                    <Label for="custom_icon">Ícone da notificação:</Label>
                    <Input
                      type="file"
                      id="custom_icon"
                      name="custom_icon"
                      label={(iconeCustomizado && iconeCustomizado.name) || 'Escolha um arquivo'}
                      onChange={(e) => setIconeCustomizado(e.target.files[0])}
                    />

                    {linkIconeCustomizadoAtual && linkIconeCustomizadoAtual.length > 0 && (
                      <Label
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          width: '100%',
                          height: '32px',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          fontSize: '12px',
                          marginTop: '8px',
                          position: 'relative'
                        }}
                      >
                        Atual:
                        <a
                          href={linkIconeCustomizadoAtual}
                          target="_blank"
                          className="ml-1"
                          rel="noreferrer"
                        >
                          {linkIconeCustomizadoAtual.split('/').pop()}
                        </a>
                      </Label>
                    )}
                  </FormGroup>
                </Col>
              )}
            </Row>
          </ModalBody>

          <ModalFooter
            style={{
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <Button className="background-primary color-white" onClick={handleClose}>
              Voltar
            </Button>

            <Button type="submit" className="background-secondary color-white">
              Salvar
            </Button>
          </ModalFooter>
        </form>
      </Modal>
    </>
  );
};

export default NotificationsModalAddEdit;
