import React from 'react';
import { Card, CardBody, CardHeader, Row } from 'reactstrap';
import PercentageChart from '../../../../../components/Statistics/PercentageChart';

export default function CanaisAtendimentoOnlineChart({ data }) {
  const { progress, chart } = data;

  return (
    <Card className="shadow borderradius-default">
      <CardHeader className="bg-transparent">
        <Row className="align-items-center">
          <div className="col">
            <h6 className="text-uppercase text-light ls-1 mb-1"> Atendimento </h6>
            <h2 className="mb-0">Canais de atendimento online</h2>
          </div>
        </Row>
      </CardHeader>

      <CardBody className="p-0 pb-3">
        <PercentageChart data={progress && progress.facebook} title="Chat facebook" />

        <PercentageChart data={progress && progress.instagram} title="Chat instagram" />

        <PercentageChart data={progress && progress.site} title="Chat online no site" />

        <PercentageChart data={progress && progress.whatsapp} title="Whatsapp" />

        <PercentageChart data={progress && progress.email} title="E-mail" />

        <PercentageChart data={progress && progress.phone} title="Telefone" />
      </CardBody>
    </Card>
  );
}
