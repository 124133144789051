import React from 'react';
import { Container } from 'reactstrap';

import './advisor-validations.css';

import Peb from '../../../components/ActionPlan/Peb';

class AdvisorValidations extends React.Component {
  render() {
    return (
      <Container className="mt-2 mt-md-5 mb-2 action-plan-container" fluid>
        <Peb />
      </Container>
    );
  }
}

//test
export default AdvisorValidations;
