import React from 'react';
import { Card, CardBody, CardHeader, Row } from 'reactstrap';
import PercentageChart from '../../../../../components/Statistics/PercentageChart';

export default function MaquinariosPadraoTruckChart({ data, category }) {
  const { progress, chart } = data;

  return (
    <Card className="shadow borderradius-default">
      <CardHeader className="bg-transparent">
        <Row className="align-items-center">
          <div className="col">
            <h6 className="text-uppercase text-light ls-1 mb-1"> PDV </h6>
            <h2 className="mb-0">
              {category == 'ALL' ? 'Máquinas no pátio' : 'Máquinas no padrão'}
            </h2>
          </div>
        </Row>
      </CardHeader>

      <CardBody className="p-0 pb-3">
        <PercentageChart
          data={progress && progress.mountDismount}
          title="Máquinas de montar e desmontar"
        />

        <PercentageChart data={progress && progress.balancers} title="Balanceadoras" />

        <PercentageChart data={progress && progress.alignment} title="Máquinas de alinhamento" />

        {category == 'ALL' ? (
          <>
            <PercentageChart data={progress && progress.elevators} title="Elevadores (CAR)" />

            <PercentageChart
              data={progress && progress.liftingRamps}
              title="Rampas elevatórias (TRUCK)"
            />
          </>
        ) : category == 'CAR' ? (
          <PercentageChart data={progress && progress.elevators} title="Elevadores" />
        ) : (
          <PercentageChart data={progress && progress.liftingRamps} title="Rampas elevatórias" />
        )}

        {(category == 'ALL' || category == 'TRUCK') && (
          <>
            <PercentageChart
              data={progress && progress.protectiveCage}
              title={`Possui gaiola de proteção ${category == 'ALL' ? '(TRUCK)' : ''}`}
            />

            <PercentageChart
              data={progress && progress.standardCages}
              title={`Gaiolas no padrão ${category == 'ALL' ? '(TRUCK)' : ''}`}
            />
          </>
        )}
      </CardBody>
    </Card>
  );
}
