import React, { useContext, useEffect, useState } from 'react';
import { Container, Alert } from 'reactstrap';

import Filters from '../../components/Filters/Filters';
import Loader from '../../components/Bridgestone/Loader.jsx';

import { StoreContext } from '../../components/Contexts/StoreContext';
import { UserContext } from '../../components/Contexts/UserContext';
import {
  Card,
  CardHeader,
  CardFooter,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  Row
} from 'reactstrap';
import AuditService from '../../services/AuditService';
import { Box, Button, IconButton, Menu, Typography } from '@mui/material';
import { Edit, KeyboardArrowDown } from '@mui/icons-material';
import DefaultTextField from '../../components/TextField/TextField';
import StringHelper from '../../helpers/StringHelper';
import StoreService from '../../services/StoreService';
import { Redirect } from 'react-router-dom';
import FileSaver from 'file-saver';

const limitRows = 20;

export default function SubmitManagerAudit() {
  const { token } = useContext(UserContext);
  const { selectedCategory } = useContext(StoreContext);

  const [storeFilterList, setStoreFilterList] = useState([]);
  const [storeFilter, setStoreFilter] = useState(undefined);

  const [selectedFilter, setSelectedFilter] = useState(undefined);
  const [filterSearch, setFilterSearch] = useState('');

  const [submitList, setSubmitList] = useState([]);
  const [selectedSubmit, setSelectedSubmit] = useState(undefined);

  const [selectedForm, setSelectedForm] = useState(undefined);

  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    handleRefresh();
  }, [currentPage, storeFilter]);

  useEffect(() => {
    if (currentPage !== 1) {
      setCurrentPage(1);
    } else {
      handleRefresh();
    }

    handleRefreshFilters();
  }, [selectedCategory]);

  const handleRefreshFilters = () => {
    setLoading(true);

    setStoreFilter(undefined);

    Promise.all([StoreService.List(token, { category: selectedCategory })])
      .then((resp) => {
        const stores = resp[0].data;
        setStoreFilterList(stores);
      })
      .catch((err) => {
        console.error(err);
        handleError('Não foi possível realizar a busca dos filtros');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleRefresh = () => {
    setLoading(true);

    const params = {};
    params.category = selectedCategory;
    params.page = currentPage;
    params.limit = limitRows;

    if (storeFilter) {
      params.code = storeFilter.code;
    }

    AuditService.ListSubmits(params)
      .then((resp) => {
        const submits = resp.data.submits;
        setSubmitList(submits);

        const pages = resp.data.pages;
        setTotalPages(pages);
      })
      .catch((err) => {
        console.error(err);
        handleError('Não foi possível realizar a busca dos questionários');
      })
      .finally(() => {
        setLoading(false);
      });

    AuditService.GetForm(
      `Avaliação de Loja - ${selectedCategory.charAt(0) + selectedCategory.slice(1).toLowerCase()}`
    )
      .then((resp) => {
        const form = resp.data.userForms[0];
        setSelectedForm(form);
      })
      .catch((err) => {
        console.error(err);
        handleError('Não foi possível realizar a busca dos questionários');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleChangeSelectedFilter = (target, data, value, setter) => {
    setSelectedFilter({
      anchorEl: target,
      data,
      value,
      setter
    });

    setFilterSearch('');
  };

  const handleError = (message, color = 'danger') => {
    setError({ message, color });

    setTimeout(() => {
      setError(undefined);
    }, 4000);
  };

  const handleDownload = () => {
    setLoading(true);

    AuditService.DownloadSubmits(selectedCategory)
      .then((resp) => {
        const filename = `Questionarios_${selectedCategory}.xlsx`;
        const file = new File([resp.data], filename);

        FileSaver.saveAs(file);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  if (selectedSubmit !== undefined) {
    const state = {
      form: { ...selectedForm, metadata: selectedSubmit.submit.form.metadata },
      answers: selectedSubmit.submit.answers,
      clientSelected: selectedSubmit.submit.form.metadata.client,
      attachments: [],
      fileNames: [],
      // _id: selectedSubmit._id,
      // _rev: selectedSubmit.__v,
      isAudit: true,
      submitIdAudit: selectedSubmit._id
    };

    return (
      <Redirect
        push
        to={{
          pathname: '/admin/questions',
          state
        }}
      />
    );
  }

  return (
    <>
      {loading && <Loader />}

      {error && (
        <Alert color={error.color} style={{ position: 'fixed', top: '30px', zIndex: 9999 }}>
          {error.message}
        </Alert>
      )}

      {/* Page content */}
      <Container fluid>
        <Filters />

        <Row>
          <div className="col">
            <Card className="shadow borderradius-default">
              <CardHeader className="border-0 borderradius-default d-flex justifycontent-spacebetween">
                <h2 className="mb-0">Auditoria - Questionários</h2>
                <Button
                  type="button"
                  className="background-primary color-white borderradius-default"
                  size="small"
                  onClick={handleDownload}
                >
                  Download
                </Button>
              </CardHeader>

              <Table className="align-items-center table-flush" responsive>
                <thead>
                  <tr>
                    <th scope="col">Loja</th>
                    <th scope="col">Assessor</th>
                    <th scope="col">Descritivo</th>
                    <th scope="col">Enviado em</th>
                    <th scope="col"></th>
                  </tr>

                  <tr>
                    <th scope="col">
                      <span
                        className="color-primary cursor-pointer"
                        onClick={(e) =>
                          handleChangeSelectedFilter(
                            e.currentTarget,
                            storeFilterList,
                            storeFilter,
                            setStoreFilter
                          )
                        }
                      >
                        {storeFilter
                          ? storeFilter.name.length > 10
                            ? `${storeFilter.name.slice(0, 10)}...`
                            : storeFilter.name
                          : 'Todos'}
                      </span>

                      <IconButton
                        className="background-primary color-white ml-2"
                        size="small"
                        onClick={(e) =>
                          handleChangeSelectedFilter(
                            e.currentTarget,
                            storeFilterList,
                            storeFilter,
                            setStoreFilter
                          )
                        }
                      >
                        <KeyboardArrowDown fontSize="small" />
                      </IconButton>
                    </th>

                    <th scope="col"> </th>
                    <th scope="col"> </th>
                    <th scope="col"> </th>
                    <th scope="col"> </th>
                  </tr>
                </thead>

                <tbody>
                  {submitList.map((item, index) => {
                    const submit = item.submit.form;

                    return (
                      <tr key={index}>
                        <td
                          style={{
                            wordWrap: 'break-word',
                            whiteSpace: 'normal'
                          }}
                        >
                          {' '}
                          {submit.metadata.client.code} - {submit.metadata.client.name}{' '}
                        </td>
                        <td
                          style={{
                            wordWrap: 'break-word',
                            whiteSpace: 'normal'
                          }}
                        >
                          {' '}
                          {`${item.submit.submitedBy.firstName} ${item.submit.submitedBy.lastName}`}{' '}
                        </td>
                        <td
                          style={{
                            wordWrap: 'break-word',
                            whiteSpace: 'normal'
                          }}
                        >
                          {' '}
                          {submit.title.trim()}{' '}
                        </td>
                        <td>
                          {' '}
                          {submit.metadata.submittedAt
                            ? new Date(submit.metadata.submittedAt).toLocaleDateString('pt-BR')
                            : '-'}{' '}
                        </td>
                        <td>
                          <IconButton
                            size="small"
                            className="background-blue color-white"
                            onClick={() => setSelectedSubmit(item)}
                          >
                            <Edit fontSize="inherit" />
                          </IconButton>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>

              <CardFooter className="py-4 borderradius-default">
                <nav aria-label="...">
                  <Pagination
                    className="pagination justify-content-end mb-0"
                    listClassName="justify-content-end mb-0"
                  >
                    <PaginationItem className={`${currentPage <= 1 ? 'disabled' : ''}`}>
                      <PaginationLink
                        href="#prev"
                        onClick={(e) => {
                          e.preventDefault();

                          if (currentPage > 1) {
                            setCurrentPage(currentPage - 1);
                          }
                        }}
                        tabIndex="-1"
                      >
                        <i className="fas fa-angle-left" />
                      </PaginationLink>
                    </PaginationItem>

                    <PaginationItem className="active">
                      <PaginationLink
                        href="#current"
                        tabIndex="-1"
                        onClick={(e) => e.preventDefault()}
                      >
                        {currentPage}
                      </PaginationLink>
                    </PaginationItem>

                    <PaginationItem className={`${currentPage >= totalPages ? 'disabled' : ''}`}>
                      <PaginationLink
                        href="#next"
                        tabIndex="-1"
                        onClick={(e) => {
                          e.preventDefault();

                          if (currentPage < totalPages) {
                            setCurrentPage(currentPage + 1);
                          }
                        }}
                      >
                        <i className="fas fa-angle-right" />
                      </PaginationLink>
                    </PaginationItem>
                  </Pagination>
                </nav>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>

      {selectedFilter && (
        <Menu
          anchorEl={selectedFilter.anchorEl}
          open={true}
          onClose={(_) => setSelectedFilter(undefined)}
          PaperProps={{
            className: 'background-primary mt-2',
            sx: {
              paddingY: 1,
              borderRadius: 4,
              width: 320,
              maxHeight: 500
            }
          }}
        >
          <Box paddingX={2} paddingTop={1} paddingBottom={3}>
            <DefaultTextField
              label="Pesquisar"
              value={filterSearch}
              onChange={(e) => setFilterSearch(e.target.value)}
              onKeyDown={(e) => e.stopPropagation()}
              required={false}
              margin="none"
              className="white-theme"
            />
          </Box>

          {(!filterSearch || filterSearch.length === 0) && (
            <Typography
              onClick={() => {
                selectedFilter.setter(undefined);
                setSelectedFilter(undefined);
                setFilterSearch('');
              }}
              style={{
                backgroundColor: selectedFilter.value === undefined ? 'rgba(0, 0, 0, 0.1)' : ''
              }}
              paddingX={2}
              paddingY={1}
              variant="subtitle2"
              className="color-white cursor-pointer"
            >
              Todos
            </Typography>
          )}

          {selectedFilter.data
            .filter(
              (row) =>
                !filterSearch ||
                filterSearch.length === 0 ||
                StringHelper.Normalize(row.code + ' ' + row.comercial_name).indexOf(
                  StringHelper.Normalize(filterSearch)
                ) > -1
            )
            .map((row, index) => {
              return (
                <Typography
                  key={index}
                  onClick={() => {
                    selectedFilter.setter(row);
                    setSelectedFilter(undefined);
                    setFilterSearch('');
                  }}
                  style={{
                    backgroundColor: row === selectedFilter.value ? 'rgba(0, 0, 0, 0.1)' : ''
                  }}
                  paddingX={2}
                  paddingY={1}
                  variant="subtitle2"
                  className="color-white cursor-pointer"
                >
                  {`${row.code} - ${row.comercial_name.toUpperCase()}`}
                </Typography>
              );
            })}
        </Menu>
      )}
    </>
  );
}
