import React, { useContext, useState } from 'react';
import { Col, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { UserContext } from '../Contexts/UserContext';
import PebTasks from './PebTasks';
import PebExtraScore from './PebExtraScore';
import PebGmb from './PebGmb';
import PebRules from './PebRules';

const Peb = () => {
  const { user } = useContext(UserContext);
  const [activeTab, setActiveTab] = useState(user.profileId === 2 ? 0 : 3);

  return (
    <>
      {/* CONTENT */}
      <Col className="mb-3 p-0">
        <div className="card shadow">
          <div className="card-header border-0">
            <div className="row align-items-center">
              <div className="col col-xs-12 col-sm-12 col-md-6 pb-2">
                <h3 className="mb-0">
                  {activeTab == 0 ? 'Plano de ação - Melhorias agendadas' : 'Pontuações extras'}
                </h3>
              </div>

              <div className="col col-xs-12 col-sm-12 col-md-6">
                <Nav className="nav-fill flex-column flex-sm-row" pills role="tablist">
                  {user.profileId === 2 && (
                    <>
                      <NavItem>
                        <NavLink
                          className={`action-plan__nav-item__nav-link ${
                            activeTab == 0 ? 'active color-white' : ''
                          }`}
                          onClick={() => setActiveTab(0)}
                        >
                          Planos de Ação
                        </NavLink>
                      </NavItem>

                      <NavItem>
                        <NavLink
                          className={`action-plan__nav-item__nav-link ${
                            activeTab == 1 ? 'active color-white' : ''
                          }`}
                          onClick={() => setActiveTab(1)}
                        >
                          Pontuações Extra
                        </NavLink>
                      </NavItem>

                      <NavItem>
                        <NavLink
                          className={`action-plan__nav-item__nav-link ${
                            activeTab == 2 ? 'active color-white' : ''
                          }`}
                          onClick={() => setActiveTab(2)}
                        >
                          Atualizar GMB
                        </NavLink>
                      </NavItem>
                    </>
                  )}

                  {user.profileId === 7 && (
                    <>
                      <NavItem>
                        <NavLink
                          className={`action-plan__nav-item__nav-link ${
                            activeTab == 3 ? 'active color-white' : ''
                          }`}
                          onClick={() => setActiveTab(3)}
                        >
                          Normas e Licenças
                        </NavLink>
                      </NavItem>
                    </>
                  )}
                </Nav>
              </div>
            </div>
          </div>

          <div className="card-content">
            <TabContent activeTab={activeTab}>
              {user.profileId === 2 && (
                <>
                  <TabPane tabId={0}>
                    <PebTasks />
                  </TabPane>

                  <TabPane tabId={1}>
                    <PebExtraScore />
                  </TabPane>

                  <TabPane tabId={2}>
                    <PebGmb />
                  </TabPane>
                </>
              )}

              {user.profileId === 7 && (
                <>
                  <TabPane tabId={3}>
                    <PebRules />
                  </TabPane>
                </>
              )}
            </TabContent>
          </div>
        </div>
      </Col>
    </>
  );
};

export default Peb;
