import React from 'react';
import { Card, CardBody, CardHeader, Row } from 'reactstrap';
import PercentageChart from '../../../../../components/Statistics/PercentageChart';

export default function AnunciosPagosGoDigitalChart({ data, category }) {
  const { progress, chart } = data;

  return (
    <Card className="shadow borderradius-default">
      <CardHeader className="bg-transparent">
        <Row className="align-items-center">
          <div className="col">
            <h6 className="text-uppercase text-light ls-1 mb-1"> Business </h6>
            <h2 className="mb-0">Anúncios pagos Go Digital</h2>
          </div>
        </Row>
      </CardHeader>

      <CardBody className="p-0 pb-3">
        <PercentageChart
          data={progress && progress.socialMedia}
          title="Anúncios nas redes sociais"
        />

        <PercentageChart data={progress && progress.google} title="Anúncios no Google" />

        {category != 'BANDAG' && (
          <PercentageChart data={progress && progress.waze} title="Anúncios no Waze" />
        )}
      </CardBody>
    </Card>
  );
}
