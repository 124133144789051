import React, { useEffect, useState } from 'react';
import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from 'reactstrap';
import axios from 'axios';
import baseURL from '../../variables/api';
import FormHelper from '../../helpers/FormHelper';

const VideosModalAddEdit = ({
  video,
  categoriesList,
  perfisList,
  isOpen,
  handleClose,
  handleRefresh,
  setLoading,
  setError
}) => {
  const [titulo, setTitulo] = useState('');
  const [link, setLink] = useState('');
  const [categorias, setCategorias] = useState([]);
  const [perfis, setPerfis] = useState([]);

  useEffect(() => {
    if (isOpen) {
      setTitulo(video ? video.title : '');
      setLink(video ? video.link : '');
      setCategorias(video ? video.categories.map((c) => c.id) : []);
      setPerfis(video ? video.profiles.map((p) => p.id) : []);
    }
  }, [isOpen]);

  const handleAddEditAction = (e) => {
    e.preventDefault();

    let formData = FormHelper.GenerateFormData(e, null);
    formData = FormHelper.IncludeKeyValue(formData, `categories`, JSON.stringify(categorias));
    formData = FormHelper.IncludeKeyValue(formData, `profiles`, JSON.stringify(perfis));

    setLoading(true);

    let method = 'post';
    let url = `${baseURL}/uptown/videos`;

    if (video) {
      method = 'put';
      url = `${baseURL}/uptown/videos/${video.id}`;
    }

    axios[method](url, Object.fromEntries(formData), {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('banana')
      }
    })
      .then((resp) => {
        setError(
          video ? 'Alteração realizada com sucesso' : 'Inclusão realizada com sucesso',
          'success'
        );
        handleClose();
        handleRefresh();
      })
      .catch((err) => {
        setError('Ocorreu um erro ao tentar realizar a operação');
        console.log(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <Modal isOpen={isOpen} toggle={handleClose}>
        <form onSubmit={(e) => handleAddEditAction(e)}>
          <ModalHeader>
            <h3>{video ? 'Editar' : 'Adicionar'}</h3>
          </ModalHeader>

          <ModalBody className="pt-0">
            <Row>
              <Col className="col-md-12 col-12">
                <FormGroup>
                  <Label for="title">Título:</Label>
                  <Input
                    type="text"
                    id="title"
                    name="title"
                    required
                    value={titulo}
                    onChange={(e) => setTitulo(e.target.value)}
                  />
                </FormGroup>
              </Col>

              <Col className="col-md-12 col-12">
                <FormGroup>
                  <Label for="link">Link:</Label>
                  <Input
                    type="text"
                    id="link"
                    name="link"
                    required
                    value={link}
                    onChange={(e) => setLink(e.target.value)}
                  />
                </FormGroup>
              </Col>

              <Col className="col-12">
                <FormGroup>
                  <Label for="categories">Categorias:</Label>
                  <Input
                    type="select"
                    id="categories"
                    name="categories"
                    required
                    multiple
                    value={categorias}
                    onChange={(e) =>
                      setCategorias(Array.from(e.target.selectedOptions, (option) => +option.value))
                    }
                  >
                    {categoriesList.map((category, index) => {
                      return (
                        <option value={category.id} key={index}>
                          {category.descr_pt}
                        </option>
                      );
                    })}
                  </Input>
                </FormGroup>
              </Col>

              <Col className="col-12">
                <FormGroup>
                  <Label for="profiles">Perfis:</Label>
                  <Input
                    type="select"
                    id="profiles"
                    name="profiles"
                    required
                    multiple
                    value={perfis}
                    onChange={(e) =>
                      setPerfis(Array.from(e.target.selectedOptions, (option) => +option.value))
                    }
                  >
                    {perfisList.map((profile, index) => {
                      return (
                        <option value={profile.id} key={index}>
                          {profile.name}
                        </option>
                      );
                    })}
                  </Input>
                </FormGroup>
              </Col>
            </Row>
          </ModalBody>

          <ModalFooter
            style={{
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <Button className="background-primary color-white" onClick={handleClose}>
              Voltar
            </Button>

            <Button type="submit" className="background-secondary color-white">
              Salvar
            </Button>
          </ModalFooter>
        </form>
      </Modal>
    </>
  );
};

export default VideosModalAddEdit;
