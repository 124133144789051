import Axios from 'axios';
import BaseUrl from '../variables/api';

const List = async (categoryId, regionId) => {
  return Axios.get(`${BaseUrl}/peb/managers?category=${categoryId}&region=${regionId}`);
};

const ManagersService = {
  List
};

export default ManagersService;
