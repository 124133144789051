import Axios from 'axios';
import BaseUrl from '../variables/api';

const List = async () => {
  return Axios.get(`${BaseUrl}/peb/profiles`);
};

const Get = async (token, storeCode, category) => {
  return Axios.get(`${BaseUrl}/peb/profile/${storeCode}/${category}`, {
    headers: {
      Authorization: 'Bearer ' + token
    }
  });
};

const EditAddress = async (token, storeCode, category, formData) => {
  return Axios.put(
    `${BaseUrl}/peb/profile/${storeCode}/${category}`,
    Object.fromEntries(formData),
    {
      headers: {
        Authorization: 'Bearer ' + token
      }
    }
  );
};

const AddManager = async (token, storeCode, category, formData) => {
  return Axios.post(
    `${BaseUrl}/peb/store-managers/${storeCode}/${category}`,
    Object.fromEntries(formData),
    {
      headers: {
        Authorization: 'Bearer ' + token
      }
    }
  );
};

const EditManager = async (token, storeCode, category, idManager, formData) => {
  return Axios.put(
    `${BaseUrl}/peb/store-managers/${storeCode}/${category}/${idManager}`,
    Object.fromEntries(formData),
    {
      headers: {
        Authorization: 'Bearer ' + token
      }
    }
  );
};

const RemoveManager = async (token, storeCode, category, idManager) => {
  return Axios.delete(`${BaseUrl}/peb/store-managers/${storeCode}/${category}/${idManager}`, {
    headers: {
      Authorization: 'Bearer ' + token
    }
  });
};

const AddEmployee = async (token, idProfile, storeCode, category, formData) => {
  return Axios.post(
    `${BaseUrl}/peb/employees/${idProfile}/${storeCode}/${category}`,
    Object.fromEntries(formData),
    {
      headers: {
        Authorization: 'Bearer ' + token
      }
    }
  );
};

const EditEmployee = async (token, idProfile, storeCode, category, idEmployee, formData) => {
  return Axios.put(
    `${BaseUrl}/peb/employees/${idProfile}/${storeCode}/${category}/${idEmployee}`,
    Object.fromEntries(formData),
    {
      headers: {
        Authorization: 'Bearer ' + token
      }
    }
  );
};

const RemoveEmployee = async (token, idProfile, storeCode, category, idEmployee) => {
  return Axios.delete(
    `${BaseUrl}/peb/employees/${idProfile}/${storeCode}/${category}/${idEmployee}`,
    {
      headers: {
        Authorization: 'Bearer ' + token
      }
    }
  );
};

const ProfilesService = {
  List,
  Get,
  EditAddress,
  AddManager,
  EditManager,
  RemoveManager,
  AddEmployee,
  EditEmployee,
  RemoveEmployee
};

export default ProfilesService;
