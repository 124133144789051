import React, { useEffect, useState } from 'react';
import { Alert, Col, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { Button } from '@mui/material';
import Loader from '../Bridgestone/Loader';
import AttachmentCard from './AttachmentCard';
import TaskService from '../../services/TaskService';
import DialogValidateTask from './DialogValidateTask';
import DialogInvalidateTask from './DialogInvalidateTask';

const statusMap = {
  Concluído: 'VALIDADO',
  'Em análise': 'PENDENTE',
  Invalidado: 'INVALIDADO'
};

const PebTasks = () => {
  const [tasks, setTasks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(undefined);
  const [selectedTask, setSelectedTask] = useState(undefined);
  const [dialogValidateOpened, setDialogValidateOpened] = useState(false);
  const [dialogInvalidateOpened, setDialogInvalidateOpened] = useState(false);

  useEffect(() => {
    getTasks();
  }, []);

  const getTasks = async () => {
    setLoading(true);

    TaskService.ListTasks()
      .then((resp) => {
        let result = resp.data.tasks;

        result.sort((a, b) => {
          if (a.status == 'Em análise') return -1;
          if (b.status == 'Em análise') return 1;
          return new Date(a.finishDate) - new Date(b.finishDate);
        });

        setTasks(result);
      })
      .catch((err) => {
        handleError(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleRefresh = () => {
    setSelectedTask(undefined);
    getTasks();
  };

  const handleError = (message, color = 'danger') => {
    setError({ message, color });

    setTimeout(() => {
      setError(undefined);
    }, 4000);
  };

  return (
    <>
      {/* Page content */}
      {loading && <Loader />}
      {error ? (
        <Alert color={error.color} style={{ position: 'fixed', top: '30px', zIndex: 9999 }}>
          {error.message}
        </Alert>
      ) : (
        ''
      )}

      {/* CONTENT */}
      <Col className="p-0">
        <div className="card">
          <div className="card-content table-responsive">
            <table className="table align-items-center">
              <thead className="thead-light">
                <tr>
                  <th>CLIENTE</th>
                  <th>PLANO DE AÇÃO</th>
                  <th>STATUS</th>
                  <th style={{ textAlign: 'center' }}>AÇÃO</th>
                </tr>
              </thead>
              <tbody>
                {tasks.map((task, k) => {
                  const status = statusMap[task.status];
                  const pending = task.status == 'Em análise';

                  return (
                    <tr key={k}>
                      <td> {`${task.client.code} - ${task.client.name}`} </td>
                      <td style={{ whiteSpace: 'break-spaces' }}> {task.title} </td>
                      <td> {status} </td>
                      <td style={{ textAlign: 'center' }}>
                        <Button
                          className={`${
                            pending ? 'background-green' : 'background-grey'
                          } color-white`}
                          disabled={!pending}
                          onClick={(e) => setSelectedTask(task)}>
                          Validar
                        </Button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </Col>

      {/* AVALIAR MODAL */}
      <Modal
        isOpen={selectedTask != undefined}
        toggle={() => setSelectedTask(undefined)}
        centered={true}
        contentClassName="dialog-default dialog-audit-validatetask">
        <ModalHeader toggle={() => setSelectedTask(undefined)}>
          <h3>{(selectedTask && selectedTask.title) || ''}</h3>
        </ModalHeader>

        <ModalBody className="pt-0">
          {selectedTask && selectedTask.comments && (
            <div>
              <span style={{ fontSize: 14 }}>
                <b>Comentários: </b>
                {selectedTask.comments}
              </span>
            </div>
          )}

          <div
            style={{
              display: 'flex',
              marginTop: 16,
              flexFlow: 'wrap'
            }}>
            {selectedTask &&
              selectedTask.attachments.map((attach, index) => {
                return <AttachmentCard link={attach} key={index} />;
              })}
          </div>
        </ModalBody>

        <ModalFooter
          style={{
            display: 'flex',
            justifyContent: 'space-between'
          }}>
          <Button
            className="background-red color-white borderradius-default"
            onClick={() => setDialogInvalidateOpened(true)}>
            Invalidar
          </Button>

          <Button
            className="background-green color-white borderradius-default"
            onClick={() => setDialogValidateOpened(true)}>
            Validar
          </Button>
        </ModalFooter>
      </Modal>

      <DialogValidateTask
        isOpen={dialogValidateOpened}
        setClosedDialog={() => setDialogValidateOpened(false)}
        type="T"
        task={selectedTask}
        handleRefresh={handleRefresh}
        setLoading={setLoading}
        setError={handleError}
      />

      <DialogInvalidateTask
        isOpen={dialogInvalidateOpened}
        setClosedDialog={() => setDialogInvalidateOpened(false)}
        type="T"
        task={selectedTask}
        handleRefresh={handleRefresh}
        setLoading={setLoading}
        setError={handleError}
      />
    </>
  );
};

export default PebTasks;
