import { Delete, Download } from '@mui/icons-material';
import { IconButton, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { Alert, Button, Col, Row, Container, FormGroup, Label, Input } from 'reactstrap';
import FileSaver from 'file-saver';
import Loader from '../../../components/Bridgestone/Loader';
import SpreadsheetModalRemove from '../../../components/SpreadsheetUpload/ModalRemove';
import FormHelper from '../../../helpers/FormHelper';
import CategoryService from '../../../services/CategoryService';
import SpreadsheetService from '../../../services/SpreadsheetService';

const SpreadsheetUpload = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(undefined);
  const [storeCategories, setStoreCategories] = useState([]);
  const [spreadsheetCategories, setSpreadsheetCategories] = useState([]);
  const [spreadsheets, setSpreadsheets] = useState([]);

  const [storeCategory, setStoreCategory] = useState(undefined);
  const [spreadsheetCategory, setSpreadsheetCategory] = useState(undefined);
  const [maxPoints, setMaxPoints] = useState(undefined);
  const [title, setTitle] = useState(undefined);
  const [arquivo, setArquivo] = useState(undefined);
  const [selectedSpreadsheet, setSelectedSpreadsheet] = useState(undefined);
  const [openedModalRemove, setOpenedModalRemove] = useState(false);

  useEffect(() => {
    Promise.all([
      CategoryService.List().then((resp) => {
        setStoreCategories(resp.data);
        setStoreCategory(resp.data[0].id);
      }),
      SpreadsheetService.List().then((resp) => {
        setSpreadsheets(resp.data);
      }),
      SpreadsheetService.ListSpreadsheetCategories().then((resp) => {
        setSpreadsheetCategories(resp.data);
        setSpreadsheetCategory(resp.data[0].id);
      })
    ]).finally(() => {
      setLoading(false);
    });
  }, [false]);

  const handleRefreshSpreadsheets = () => {
    SpreadsheetService.List()
      .then((resp) => {
        setSpreadsheets(resp.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleUploadAction = (e) => {
    e.preventDefault();

    const formData = FormHelper.GenerateFormData(e, null);
    setLoading(true);

    SpreadsheetService.UploadSpreadsheetFile(formData)
      .then((resp) => {
        setError('Upload realizado com sucesso');
        setArquivo(undefined);
        setTimeout(() => {
          setError('');
        }, 4000);
      })
      .catch((err) => {
        setError('Não foi possível realizar a operação');
        setTimeout(() => {
          setError('');
        }, 4000);
      })
      .finally(() => handleRefreshSpreadsheets());
  };

  const handleDownloadSpreadsheet = (item) => {
    SpreadsheetService.Export(item.id)
      .then((resp) => {
        const reportTitle = item.title.toLowerCase().replace(/ /g, '_');

        const filename = `${reportTitle}.xlsx`;
        const file = new File([resp.data], filename);

        FileSaver.saveAs(file);
      })
      .catch((err) => {
        setTimeout(() => {
          setError('Ocorreu um erro ao tentar realizar a operação');
        }, 4000);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      {/* Page content */}
      {loading && <Loader />}
      {error ? (
        <Alert color="danger" style={{ position: 'fixed', top: '30px', zIndex: 9999 }}>
          {error}
        </Alert>
      ) : (
        ''
      )}

      <Container className="mt-2 mt-md-5 mb-2 action-plan-container" fluid>
        <form onSubmit={(e) => handleUploadAction(e)}>
          <Row className="mt-6">
            <Col className="col-12">
              <span>* A planilha obrigatoriamente deve conter as colunas:</span>
              <br />
              <br />
              <span>
                {' '}
                <b>codigo</b>: código da loja;
              </span>
              <br />
              <span>
                {' '}
                <b>pontos</b>: pontos acumulados pela loja.
              </span>
            </Col>

            <Col className="col-md-6 col-12 mt-5">
              <FormGroup>
                <Label>Categoria:</Label>
                <Input
                  type="select"
                  label="Categoria"
                  name="storeCategory"
                  value={storeCategory}
                  onChange={(e) => setStoreCategory(e.target.value)}
                  required={true}
                >
                  {storeCategories.map((storeCategory) => {
                    return (
                      <option value={storeCategory.id} key={storeCategory.id}>
                        {storeCategory.descr_pt}
                      </option>
                    );
                  })}
                </Input>
              </FormGroup>

              <FormGroup>
                <Label>Somar a:</Label>
                <Input
                  type="select"
                  label="Somar a"
                  name="spreadsheetCategory"
                  value={spreadsheetCategory}
                  onChange={(e) => setSpreadsheetCategory(e.target.value)}
                  required={true}
                >
                  {spreadsheetCategories.map((category) => {
                    return (
                      <option value={category.id} key={category.id}>
                        {category.title}
                      </option>
                    );
                  })}
                </Input>
              </FormGroup>

              <FormGroup>
                <Label>Título da planilha:</Label>
                <Input
                  type="text"
                  label="Título da planilha"
                  name="title"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  required={true}
                />
              </FormGroup>

              <FormGroup>
                <Label>Pontuação máxima:</Label>
                <Input
                  type="nubmer"
                  label="Pontuação máxima"
                  name="maxPoints"
                  value={maxPoints}
                  onChange={(e) => setMaxPoints(e.target.value)}
                  required={true}
                />
              </FormGroup>

              <FormGroup>
                <Label for="file">Planilha:</Label>
                <Input
                  type="file"
                  id="file"
                  name="file"
                  label={(arquivo && arquivo.name) || 'Escolha um arquivo'}
                  onChange={(e) => setArquivo(e.target.files[0])}
                  required={true}
                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                />
              </FormGroup>

              <Button type="submit" className="background-secondary color-white">
                Enviar
              </Button>
            </Col>
          </Row>
        </form>

        <Typography variant="subtitle1" className="fontweight-600 mt-5 mb-1">
          Planilhas enviadas
        </Typography>

        <Row>
          <Col className="col-12">
            <div className="card">
              <div className="card-content table-responsive">
                <table className="table align-items-center">
                  <thead>
                    <tr>
                      <th scope="col">Título</th>
                      <th scope="col">Categoria</th>
                      <th scope="col">Somado a</th>
                      <th scope="col">Pontos</th>
                      <th scope="col">Enviado em</th>
                      <th scope="col">Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    {spreadsheets.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td
                            className="py-1"
                            style={{
                              wordWrap: 'break-word',
                              whiteSpace: 'normal'
                            }}
                          >
                            {item.title}
                          </td>
                          <td
                            className="py-1"
                            style={{
                              wordWrap: 'break-word',
                              whiteSpace: 'normal'
                            }}
                          >
                            {item.storeCategory.descr_pt}
                          </td>
                          <td
                            className="py-1"
                            style={{
                              wordWrap: 'break-word',
                              whiteSpace: 'normal'
                            }}
                          >
                            {item.category.title}
                          </td>
                          <td
                            className="py-1"
                            style={{
                              wordWrap: 'break-word',
                              whiteSpace: 'normal'
                            }}
                          >
                            {item.max_points}
                          </td>
                          <td className="py-1">
                            {new Date(item.created_at).toLocaleDateString('pt-BR')}
                          </td>
                          <td className="py-1">
                            <IconButton
                              size="small"
                              className="background-green color-white mr-1"
                              onClick={() => handleDownloadSpreadsheet(item)}
                            >
                              <Download fontSize="inherit" />
                            </IconButton>
                            <IconButton
                              size="small"
                              className="background-red color-white"
                              onClick={() => {
                                setSelectedSpreadsheet(item);
                                setOpenedModalRemove(true);
                              }}
                            >
                              <Delete fontSize="inherit" />
                            </IconButton>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </Col>
        </Row>
      </Container>

      <SpreadsheetModalRemove
        spreadsheet={selectedSpreadsheet}
        isOpen={openedModalRemove}
        handleClose={(_) => setOpenedModalRemove(false)}
        handleRefresh={handleRefreshSpreadsheets}
        setLoading={setLoading}
        setError={() => {}}
      />
    </>
  );
};

export default SpreadsheetUpload;
