import React, { useEffect, useState } from 'react';
import { Alert, Col, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { Button } from '@mui/material';
import Loader from '../Bridgestone/Loader';
import AttachmentCard from './AttachmentCard';
import RulesAndLicensesService from '../../services/RulesAndLicensesService';
import DialogValidateTask from './DialogValidateTask';
import DialogInvalidateTask from './DialogInvalidateTask';

const statusMap = {
  2: 'PENDENTE',
  3: 'VALIDADA',
  4: 'INVALIDADA'
};

const PebRules = () => {
  const [rules, setRules] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(undefined);
  const [selectedRule, setSelectedRudle] = useState(undefined);
  const [dialogValidateOpened, setDialogValidateOpened] = useState(false);
  const [dialogInvalidateOpened, setDialogInvalidateOpened] = useState(false);

  useEffect(() => {
    getRules();
  }, []);

  const getRules = async () => {
    setLoading(true);

    RulesAndLicensesService.ListSubmissions()
      .then((resp) => {
        let result = resp.data;

        result.sort((a, b) => {
          if (a.status == 2) return -1;
          if (b.status == 2) return 1;
          return new Date(a.created_at) - new Date(b.created_at);
        });

        setRules(result);
      })
      .catch((err) => {
        handleError(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleRefresh = () => {
    setSelectedRudle(undefined);
    getRules();
  };

  const handleError = (message, color = 'danger') => {
    setError({ message, color });

    setTimeout(() => {
      setError(undefined);
    }, 4000);
  };

  return (
    <>
      {/* Page content */}
      {loading && <Loader />}
      {error ? (
        <Alert color={error.color} style={{ position: 'fixed', top: '30px', zIndex: 9999 }}>
          {error.message}
        </Alert>
      ) : (
        ''
      )}

      {/* CONTENT */}
      <Col className="p-0">
        <div className="card">
          <div className="card-content table-responsive">
            <table className="table align-items-center">
              <thead className="thead-light">
                <tr>
                  <th>CLIENTE</th>
                  <th>NORMA/LICENÇA</th>
                  <th>STATUS</th>
                  <th style={{ textAlign: 'center' }}>AÇÃO</th>
                </tr>
              </thead>
              <tbody>
                {rules.map((rule, k) => {
                  const pending = rule.status == 2;
                  const status = statusMap[rule.status];

                  return (
                    <tr key={k}>
                      <td> {`${rule.client.code} - ${rule.client.name}`} </td>
                      <td style={{ whiteSpace: 'break-spaces' }}>{rule.title}</td>
                      <td> {status} </td>
                      <td style={{ textAlign: 'center' }}>
                        <Button
                          className={`${
                            pending ? 'background-green' : 'background-grey'
                          } color-white`}
                          disabled={!pending}
                          onClick={(e) => setSelectedRudle(rule)}
                        >
                          Validar
                        </Button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </Col>

      {/* AVALIAR MODAL */}
      <Modal
        isOpen={selectedRule != undefined}
        toggle={() => setSelectedRudle(undefined)}
        centered={true}
        contentClassName="dialog-default dialog-audit-validatetask"
      >
        <ModalHeader toggle={() => setSelectedRudle(undefined)}>
          <h3>{(selectedRule && selectedRule.title) || ''}</h3>
        </ModalHeader>

        <ModalBody className="pt-0">
          <div>
            <span style={{ fontSize: 14 }}>
              <b>Descritivo: </b>
              {(selectedRule && selectedRule.description) || ''}
            </span>
          </div>

          <div
            style={{
              display: 'flex',
              marginTop: 16,
              flexFlow: 'wrap'
            }}
          >
            {selectedRule &&
              selectedRule.attachments.map((attach, index) => {
                return <AttachmentCard link={attach} key={index} />;
              })}
          </div>
        </ModalBody>

        <ModalFooter
          style={{
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <Button
            className="background-red color-white borderradius-default"
            onClick={() => setDialogInvalidateOpened(true)}
          >
            Invalidar
          </Button>

          <Button
            className="background-green color-white borderradius-default"
            onClick={() => setDialogValidateOpened(true)}
          >
            Validar
          </Button>
        </ModalFooter>
      </Modal>

      <DialogValidateTask
        isOpen={dialogValidateOpened}
        setClosedDialog={() => setDialogValidateOpened(false)}
        type="R"
        task={selectedRule}
        handleRefresh={handleRefresh}
        setLoading={setLoading}
        setError={handleError}
      />

      <DialogInvalidateTask
        isOpen={dialogInvalidateOpened}
        setClosedDialog={() => setDialogInvalidateOpened(false)}
        type="R"
        task={selectedRule}
        handleRefresh={handleRefresh}
        setLoading={setLoading}
        setError={handleError}
      />
    </>
  );
};

export default PebRules;
