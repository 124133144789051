import Axios from 'axios';
import baseURL from '../variables/api';

const authToken = localStorage.getItem('banana');

const ListTasks = async () => {
  return await Axios.get(`${baseURL}/tasks`, {
    headers: {
      Authorization: 'Bearer ' + authToken
    }
  });
};

const ValidateTask = async (taskId) => {
  return await Axios.put(
    `${baseURL}/tasks/${taskId}/validate`,
    {},
    {
      headers: {
        Authorization: 'Bearer ' + authToken
      }
    }
  );
};

const InvalidateTask = async (taskId, idReason) => {
  return await Axios.put(
    `${baseURL}/tasks/${taskId}/invalidate/${idReason}`,
    {},
    {
      headers: {
        Authorization: 'Bearer ' + authToken
      }
    }
  );
};

const TaskService = {
  ListTasks,
  ValidateTask,
  InvalidateTask
};

export default TaskService;
