import Axios from 'axios';
import baseURL from '../variables/api';

const ListTaskTypes = async () => {
  const authToken = localStorage.getItem('banana');
  return await Axios.get(`${baseURL}/uptown/audit/types`, {
    headers: {
      Authorization: 'Bearer ' + authToken
    }
  });
};

const ListTasksByType = async (idTaskType, category) => {
  const authToken = localStorage.getItem('banana');
  return await Axios.get(`${baseURL}/uptown/audit/types/${idTaskType}`, {
    headers: {
      Authorization: 'Bearer ' + authToken
    },
    params: {
      category: category
    }
  });
};

const ListTasks = async (params = {}) => {
  const authToken = localStorage.getItem('banana');
  return await Axios.get(`${baseURL}/uptown/audit`, {
    headers: {
      Authorization: 'Bearer ' + authToken
    },
    params: params
  });
};

const ListSubmits = async (params = {}) => {
  const authToken = localStorage.getItem('banana');
  return await Axios.get(`${baseURL}/submits/audit`, {
    headers: {
      Authorization: 'Bearer ' + authToken
    },
    params: params
  });
};

const GetForm = async (title) => {
  const authToken = localStorage.getItem('banana');
  return await Axios.get(encodeURI(`${baseURL}/peb/forms?title=${title}`), {
    headers: {
      Authorization: 'Bearer ' + authToken
    }
  });
};

const ListReasons = async (type = 'T') => {
  const authToken = localStorage.getItem('banana');
  return await Axios.get(`${baseURL}/uptown/audit/reasons?type=${type}`, {
    headers: {
      Authorization: 'Bearer ' + authToken
    }
  });
};

const ValidateTask = async (taskId, taskType) => {
  const authToken = localStorage.getItem('banana');
  return await Axios.put(
    `${baseURL}/uptown/audit/${taskId}/${taskType}/validate`,
    {},
    {
      headers: {
        Authorization: 'Bearer ' + authToken
      }
    }
  );
};

const InvalidateTask = async (taskId, taskType, idReason) => {
  const authToken = localStorage.getItem('banana');
  return await Axios.put(
    `${baseURL}/uptown/audit/${taskId}/${taskType}/invalidate/${idReason}`,
    {},
    {
      headers: {
        Authorization: 'Bearer ' + authToken
      }
    }
  );
};

const DownloadSubmits = async (category) => {
  const authToken = localStorage.getItem('banana');
  return await Axios.get(`${baseURL}/dash/submit-table?storeCategory=${category}`, {
    headers: {
      Authorization: 'Bearer ' + authToken
    },
    responseType: 'blob'
  });
};

const AuditService = {
  ListTaskTypes,
  ListTasksByType,
  ListTasks,
  ListSubmits,
  ListReasons,
  ValidateTask,
  InvalidateTask,
  GetForm,
  DownloadSubmits
};

export default AuditService;
