import Axios from 'axios';
import baseURL from '../variables/api';

const authToken = localStorage.getItem('banana');

const List = async () => {
  return await Axios.get(`${baseURL}/peb/categories`, {
    headers: {
      Authorization: 'Bearer ' + authToken
    }
  });
};

const CategoryService = {
  List
};

export default CategoryService;
