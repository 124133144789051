import React, { useContext, useState } from 'react';
import { NavLink as NavLinkRRD, Link, Redirect } from 'react-router-dom';
import {
  Collapse,
  Form,
  Input,
  InputGroupText,
  InputGroup,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col
} from 'reactstrap';
import './style.scss';
import { Logout } from '@mui/icons-material';
import StoreContainerTrigger from '../StoreContainer/StoreContainerTrigger';
import NotificationsTrigger from '../Notifications/NotificationsTrigger';
import { UserContext } from '../Contexts/UserContext';
import { StoreContext } from '../Contexts/StoreContext';

export default function Sidebar(props) {
  const { user } = useContext(UserContext);
  const { selectedCategory } = useContext(StoreContext);

  const [collapseOpen, setCollapseOpen] = useState(false);
  const [toLogin, setToLogin] = useState(false);

  // toggles collapse between opened and closed (true/false)
  const toggleCollapse = () => {
    setCollapseOpen(!collapseOpen);
  };

  // closes the collapse
  const closeCollapse = () => {
    setCollapseOpen(false);
  };

  const clearUser = () => {
    localStorage.removeItem('banana');
    localStorage.removeItem('user');
    setToLogin(true);
  };

  // creates the links that appear in the left menu / Sidebar
  const createLinks = (routes, keyFilter = undefined, valueFilter = undefined) => {
    return routes
      .filter((route) => keyFilter == undefined || route[keyFilter] == valueFilter)
      .filter(
        (route) => !route.categoriesAllowed || route.categoriesAllowed.includes(selectedCategory)
      )
      .map((prop, key) => {
        const Icon = prop.icon;

        return (
          <NavItem key={key} className="nav-item">
            <NavLink
              to={prop.layout + prop.path}
              tag={NavLinkRRD}
              onClick={closeCollapse}
              activeClassName="active"
              className="nav-link">
              <Icon className="icon" />
              {prop.name}
            </NavLink>
          </NavItem>
        );
      });
  };

  const { routes, logo } = props;

  return (
    <>
      {toLogin && (
        <Redirect
          push
          to={{
            pathname: '/auth/login/'
          }}
        />
      )}

      <Navbar
        className="navbar-vertical fixed-left navbar-light bg-white"
        expand="md"
        id="sidenav-main">
        <Container fluid>
          <Container className="navbar-header">
            {/* Toggler */}
            <button className="navbar-toggler float-left" type="button" onClick={toggleCollapse}>
              <span className="navbar-toggler-icon" />
            </button>
            {/* Brand */}
            {logo ? (
              <>
                <Link to="/admin/dashboard" className="navbar-brand-link">
                  <img alt={logo.imgAlt} className="navbar-brand-img" src={logo.imgSrc} />
                </Link>
              </>
            ) : null}

            <div className={`navbar-triggers ${user.profileId != 6 ? 'is-not-store' : ''}`}>
              {user.profileId == 6 && <NotificationsTrigger onHeader={true} />}
              <StoreContainerTrigger onHeader={true} />
            </div>
          </Container>

          {/* User */}

          {/* Collapse */}
          <Collapse navbar isOpen={collapseOpen}>
            {/* Collapse header */}
            <div className="navbar-collapse-header d-md-none">
              <Row>
                {logo ? (
                  <Col className="collapse-brand" xs="6">
                    {logo.innerLink ? (
                      <Link to={logo.innerLink}>
                        <img alt={logo.imgAlt} src={logo.imgSrc} />
                      </Link>
                    ) : (
                      <a href={logo.outterLink}>
                        <img alt={logo.imgAlt} src={logo.imgSrc} />
                      </a>
                    )}
                  </Col>
                ) : null}
                <Col className="collapse-close" xs="6">
                  <button className="navbar-toggler" type="button" onClick={toggleCollapse}>
                    <span />
                    <span />
                  </button>
                </Col>
              </Row>
            </div>
            {/* Form */}
            <Form className="mt-4 mb-3 d-md-none">
              <InputGroup className="input-group-rounded input-group-merge">
                <Input
                  aria-label="Search"
                  className="form-control-rounded form-control-prepended"
                  placeholder="Search"
                  type="search"
                />
                <InputGroupText>
                  <span className="fa fa-search" />
                </InputGroupText>
              </InputGroup>
            </Form>
            {/* Navigation */}
            <hr className="my-3" />

            <Nav navbar>
              {createLinks(routes, 'store', true)}

              <NavItem className="nav-item">
                <NavLink
                  to={'#'}
                  tag={'sair'}
                  onClick={() => clearUser()}
                  activeClassName="active"
                  className="nav-link cursor-pointer">
                  <Logout className="icon" />
                  <span>Sair</span>
                </NavLink>
              </NavItem>
            </Nav>

            {createLinks(routes, 'content', true).length > 0 && (
              <>
                <hr className="my-3" />
                <h6 className="navbar-heading text-muted">Conteúdo</h6>
                <Nav navbar>{createLinks(routes, 'content', true)}</Nav>
              </>
            )}
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}
