import React from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import {
  Alert,
  Card,
  Container,
  Row,
  Col,
  Button,
  CardHeader,
  UncontrolledDropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  Modal,
  ModalBody,
  ModalFooter
} from 'reactstrap';

import axios from 'axios';
import Loader from '../../components/Bridgestone/Loader.jsx';
import baseURL from '../../variables/api';
import { Table } from '@mui/material';
// import PDFExporterForm from "../../components/Data/FormExport/PDFExporterForm";

class SubmitManager extends React.Component {
  constructor() {
    super();

    this.state = {
      clientsList: [],
      clientsFiltered: [],
      error: false,
      errorText: '',
      submitsList: [],
      shoppingPctSubmitList: [],
      submitsListFiltered: [],
      indexedDb: [],
      data: {},
      form: '',
      answers: '',
      clientSelected: '',
      id: '',
      categories: [],
      submitList: [],
      titles: [],
      colors: [
        '#ED1C24',
        '#D6161D',
        '#AE0E14',
        '#990C13',
        '#69060A',
        '#990C13',
        '#AE0E14',
        '#D6161D',
        '#ED1C24',
        '#D6161D',
        '#AE0E14',
        '#ED1C24',
        '#D6161D',
        '#AE0E14',
        '#990C13',
        '#69060A',
        '#990C13',
        '#AE0E14',
        '#D6161D',
        '#ED1C24',
        '#D6161D',
        '#AE0E14',
        '#ED1C24',
        '#D6161D',
        '#AE0E14',
        '#990C13',
        '#69060A',
        '#990C13',
        '#AE0E14',
        '#D6161D',
        '#ED1C24',
        '#D6161D',
        '#AE0E14',
        '#ED1C24',
        '#D6161D',
        '#AE0E14',
        '#990C13',
        '#69060A',
        '#990C13',
        '#AE0E14',
        '#D6161D',
        '#ED1C24',
        '#D6161D',
        '#AE0E14'
      ],
      show: [false, false, false, false, false, false],
      indexedDBCategory: [],
      indexedDBListFiltered: [],
      showIndexedDB: [false, false, false, false, false, false],
      migrationList: {
        TBR: [],
        PCT: [],
        Consumer: []
      },
      migrationListFiltered: {
        TBR: [],
        PCT: [],
        Consumer: []
      },
      hasMigrations: false,
      migrationCategory: [],
      showMigration: [false, false, false, false, false, false],
      pagesCount: 10,
      currentPage: { TBR: 0, PCT: 0, Consumer: 0 },
      searchedValue: { TBR: '', PCT: '', Consumer: '' },
      currentForm: {},
      savedSubmitList: [],
      savedSubmitCategories: [],
      savedSubmitListFiltered: [],
      showSavedSubmit: []
    };
  }

  async componentDidMount() {
    this.setState({ loading: true });
    await Promise.all([this.getSubmits(), this.getSavedSubmits()]);
    this.setState({ loading: false });
  }

  getSubmits = async () => {
    let submitList;
    try {
      submitList = (
        await axios.get(baseURL + '/peb/submits', {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('banana')
          }
        })
      ).data.submits;

      let categories = [];

      submitList.sort(
        (a, b) =>
          new Date(b.submit.form.metadata.datetime) - new Date(a.submit.form.metadata.datetime)
      );

      for (let f of submitList) {
        if (
          !categories.find(
            (el) => el.category == f.submit.form.category && el.title == f.submit.form.title
          )
        ) {
          categories.push({
            category: f.submit.form.category,
            title: f.submit.form.title
          });
        }
      }

      this.setState({
        submitList,
        categories,
        submitsListFiltered: submitList
      });
    } catch (err) {
      this.setState({ error: true, errorText: err.message });
      console.error(err);
      await this.setState({ loading: false });
      setTimeout(() => {
        this.setState({ error: false });
      }, 4000);
    }
  };

  getSavedSubmits = async () => {
    try {
      let savedSubmitList = (
        await axios.get(baseURL + '/submits/saved', {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('banana')
          }
        })
      ).data.submits;

      let savedSubmitCategories = [];

      savedSubmitList.sort(
        (a, b) =>
          new Date(b.submit.form.metadata.datetime) - new Date(a.submit.form.metadata.datetime)
      );

      for (let f of savedSubmitList) {
        if (
          !savedSubmitCategories.find(
            (el) => el.category == f.submit.form.category && el.title == f.submit.form.title
          )
        ) {
          savedSubmitCategories.push({
            category: f.submit.form.category,
            title: f.submit.form.title
          });
        }
      }

      this.setState({
        savedSubmitList,
        savedSubmitCategories,
        savedSubmitListFiltered: savedSubmitList
      });
    } catch (err) {
      this.setState({ error: true, errorText: err.message });
      console.error(err);
      await this.setState({ loading: false });
      setTimeout(() => {
        this.setState({ error: false });
      }, 4000);
    }
  };

  showAlert(message) {
    this.setState({ error: true, errorText: message });
    setTimeout(() => {
      this.setState({ error: false });
    }, 3000);
  }

  handleDate = (string) => {
    const date = new Date(string).toLocaleString('pt-BR', {
      hour12: false
    });
    return date;
  };

  redirectForm = async ({ submit, isEdit, submitId, submitIdSave, isSave = false }) => {
    await this.setState({ loading: true });

    let form;

    try {
      const res = (
        await axios.get(encodeURI(baseURL + '/peb/forms?title=' + submit.form.title), {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('banana')
          }
        })
      ).data;

      form = res.userForms.find(
        (f) => f.category === submit.form.category && f.title === submit.form.title
      );

      // for (let i = 0; i < 12; i++) {
      //   storeList.push({ name: "Loja Teste " + i, id: i });
      // }

      let answers = submit.answers;

      // let attachmentNames = [];
      // for (let n in answers) {
      //   if (Array.isArray(answers[n])) {
      //     for (let elem of answers[n]) {
      //       if (elem.endsWith(".jpg") || elem.endsWith(".png")) {
      //         attachmentNames.push(elem)
      //       }
      //     }
      //   }
      // }

      if (submit.form.metadata == undefined) {
        submit.form.metadata = {};
      }

      if (submit.form.category === 'TBR Revendas') {
        form.category = 'TBR Revendas';
      }

      form.metadata = submit.form.metadata ? submit.form.metadata : undefined;

      if (form.metadata.datetime) {
        form.metadata.datetime = new Date(form.metadata.datetime);
      }

      if (form.metadata.startedAt) {
        form.metadata.startedAt = new Date(form.metadata.startedAt);
      } else {
        form.metadata.startedAt = '';
      }

      await this.setState({
        form,
        answers,
        clientSelected: submit.form.metadata.client ? submit.form.metadata.client : undefined,
        isEdit,
        submitIdEdit: submitId,
        submitIdSave,
        isSave
      });
    } catch (err) {
      //localStorage.removeItem("banana");
      this.setState({ error: true, errorText: err.message });
      console.error(err);
      setTimeout(() => {
        this.setState({ error: false });
      }, 4000);
    }
    await this.setState({ loading: false, toForms: true });
  };

  invalidateSubmit = async () => {
    let submit = this.state.invalidSubmit;

    console.log('invalidadating submit');
    console.log(submit);

    await this.setState({ loading: true });

    try {
      if (submit.hasOwnProperty('isSave') && submit.isSave) {
        await axios.delete(`${baseURL}/submits/saved/${submit._id}`, {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('banana')
          }
        });

        let savedSubmitList = this.state.savedSubmitList;
        let savedSubmitListFiltered = this.state.savedSubmitListFiltered;

        savedSubmitList = savedSubmitList.filter((el) => el._id != submit._id);
        savedSubmitListFiltered = savedSubmitListFiltered.filter((el) => el._id != submit._id);

        this.setState({
          loading: false,
          success: true,
          savedSubmitList,
          savedSubmitListFiltered
        });
      } else {
        let migration = await axios.put(
          baseURL + '/submits/invalidate',
          { submitId: submit._id },
          {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('banana')
            }
          }
        );

        let submitList = this.state.submitList;
        let submitsListFiltered = this.state.submitsListFiltered;

        submitList = submitList.filter((el) => el._id != submit._id);
        submitsListFiltered = submitsListFiltered.filter((el) => el._id != submit._id);

        this.setState({
          loading: false,
          success: true,
          submitList,
          submitsListFiltered
        });
      }
    } catch (err) {
      //localStorage.removeItem("banana");
      console.error(err);
      this.setState({
        error: true,
        loading: false,
        success: false,
        errorText: err.message
      });
    }
  };

  keepInForm = async (form, answers, clientSelected, _id, _rev, attachments, fileNames) => {
    if (form.metadata && form.metadata.datetime) {
      form.metadata.datetime = new Date(form.metadata.datetime);
    }
    await this.setState({
      form,
      answers,
      clientSelected,
      attachments,
      fileNames,
      _id,
      _rev
    });
    await this.setState({ toForms: true });
  };

  handlePageClick = (e, index, dataset) => {
    e.preventDefault();
    let currentPage = this.state.currentPage;
    currentPage[dataset] = index;
    this.setState({
      currentPage
    });
  };

  handlePreviousPage = (e, category) => {
    e.preventDefault();

    if (this.state.currentPage[category] === 0) {
      return;
    }

    let currentPage = this.state.currentPage;
    currentPage[category] = currentPage[category] - 1;

    this.setState({
      currentPage
    });
  };

  handleNextPage = (e, dataset) => {
    e.preventDefault();

    if (this.state.currentPage[dataset] === 0) {
      return;
    }

    let currentPage = this.state.currentPage;
    currentPage[dataset] = currentPage[dataset] + 1;

    this.setState({
      currentPage
    });
  };

  handleSearch = (e, dataset) => {
    let searchedValue = this.state.searchedValue;
    searchedValue[dataset] = e.target.value.toUpperCase();

    this.setState({ searchedValue });
  };

  render() {
    if (this.state.toLogin) {
      this.setState({ toLogin: false });
      return (
        <Redirect
          push
          to={{
            pathname: '/auth/login/'
          }}
        />
      );
    }

    if (this.state.toForms) {
      this.setState({ toForms: false });
      let state = {
        form: this.state.form,
        answers: this.state.answers,
        clientSelected: this.state.clientSelected,
        attachments: this.state.attachments,
        fileNames: this.state.fileNames,
        _id: this.state._id,
        _rev: this.state._rev
      };

      if (this.state.isEdit) {
        state.isEdit = true;
        state.submitIdEdit = this.state.submitIdEdit;
      }

      if (this.state.isSave) {
        state.isSave = true;
        state.submitIdSave = this.state.submitIdSave;
      }

      return (
        <Redirect
          push
          to={{
            pathname: '/admin/questions',
            state
          }}
        />
      );
    }

    //console.log(this.state.indexedDb);

    return (
      <>
        {/* Page content */}
        {this.state.loading && <Loader />}
        {this.state.error ? (
          <Alert color="danger" style={{ position: 'fixed', top: '30px', zIndex: 9999 }}>
            {this.state.errorText}
          </Alert>
        ) : (
          ''
        )}
        <div className="pb-3 pt-2 pt-md-3">
          <Container className="mt-2 mb-2 mt-md-5" fluid>
            <Col className="mb-3">
              <div className="card shadow">
                <div className="card-header border-0">
                  <div className="row">
                    <h3 className="mb-0">Meus Envios</h3>
                  </div>
                </div>
                {this.state.categories.map((category, index) => (
                  <Card className="shadow font-weight-bold card-full" key={index}>
                    <CardHeader
                      className="card-header text-white background-primary"
                      onClick={(e) => {
                        let show = this.state.show;
                        show[index] = !show[index];
                        this.setState({ show });
                      }}
                    >
                      <Row>
                        <Col xs="10" className="m-0 p-0">
                          {category.category} - {category.title}
                        </Col>
                        <Col xs="2" className="m-0 p-0 text-center">
                          <i className="ni ni-bold-down" color="white"></i>
                        </Col>
                      </Row>
                    </CardHeader>
                    {this.state.show[index] && (
                      <div className="table-responsive" style={{ minHeight: '150px' }}>
                        <Table className="table align-items-center table-flush">
                          <thead className="thead-light">
                            <tr>
                              <th scope="col">Cliente</th>
                              <th scope="col">Código</th>
                              <th scope="col">Cidade</th>
                              <th scope="col">Data</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.submitList
                              .filter(
                                (f) =>
                                  f.submit.form.category == category.category &&
                                  f.submit.form.title == category.title
                              )
                              .map((form, indexForm) => (
                                <tr key={indexForm}>
                                  {form.submit.form.metadata.client ? (
                                    <>
                                      <td>{form.submit.form.metadata.client.name}</td>
                                      <td>{form.submit.form.metadata.client.code}</td>
                                      <td>
                                        {form.submit.form.metadata.client.address
                                          ? `${form.submit.form.metadata.client.address.city} - ${form.submit.form.metadata.client.address.state}`
                                          : ''}
                                      </td>
                                    </>
                                  ) : (
                                    <>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                    </>
                                  )}
                                  <td>{this.handleDate(form.submit.form.metadata.datetime)}</td>
                                  <td>
                                    <UncontrolledDropdown style={{ position: 'static' }}>
                                      <DropdownToggle
                                        className="btn-icon-only text-light"
                                        role="button"
                                        size="sm"
                                        onClick={(e) => e.preventDefault()}
                                      >
                                        <i className="fas fa-ellipsis-v" />
                                      </DropdownToggle>
                                      <DropdownMenu className="dropdown-menu-arrow" right>
                                        {/* <DropdownItem
                                          onClick={(e) => {
                                            this.redirectForm({
                                              submit: form.submit,
                                            });
                                          }}
                                        >
                                          Replicar/Novo Envio
                                        </DropdownItem> */}
                                        <DropdownItem
                                          onClick={(e) => {
                                            this.redirectForm({
                                              submit: form.submit,
                                              isEdit: true,
                                              submitId: form._id
                                            });
                                          }}
                                        >
                                          Revisar/Editar
                                        </DropdownItem>
                                        {/* <DropdownItem
                                          onClick={(e) => {
                                            this.setState({
                                              invalidSubmit: {
                                                ...form.submit,
                                                _id: form._id,
                                              },
                                              confirmInvalidation: true,
                                            });
                                          }}
                                        >
                                          Invalidar
                                        </DropdownItem> */}
                                        {/* <DropdownItem>
                                          <PDFExporterForm
                                            title={form.submit.form.title}
                                            category={form.submit.form.category}
                                            submit={{
                                              client:
                                                form.submit.form.metadata
                                                  .client,
                                              submitter: form.submit.submitedBy,
                                              values: form.submit.answers,
                                              datetime:
                                                form.submit.form.metadata
                                                  .datetime,
                                              submittedAt: form.submit.form
                                                .metadata.submittedAt
                                                ? form.submit.form.metadata
                                                    .submittedAt
                                                : form.submit.form.metadata
                                                    .datetime,
                                            }}
                                          />
                                        </DropdownItem> */}
                                      </DropdownMenu>
                                    </UncontrolledDropdown>
                                  </td>
                                </tr>
                              ))}
                            {this.state.submitsListFiltered.length == 0 && (
                              <tr>
                                <th scope="row">Não há Envios</th>
                              </tr>
                            )}
                          </tbody>
                        </Table>
                      </div>
                    )}
                  </Card>
                ))}
              </div>
            </Col>
          </Container>
        </div>

        {this.state.confirmInvalidation && (
          <Modal
            toggle={() => {
              let confirmInvalidation = this.state.confirmInvalidation;
              this.setState({
                confirmInvalidation: !confirmInvalidation,
                invalidSubmit: null
              });
            }}
            isOpen={this.state.confirmInvalidation}
          >
            <ModalBody>
              <h3>Confirmar a remoção do seguinte questionário:</h3>

              <p>
                <b>Cliente:</b>{' '}
                {this.state.invalidSubmit.form.metadata.client
                  ? this.state.invalidSubmit.form.metadata.client.name
                  : ''}
                <br />
                <b>Questionário:</b> {this.state.invalidSubmit.form.title}
                <br />
                <b>Data:</b> {this.handleDate(this.state.invalidSubmit.form.metadata.datetime)}
                <br />
              </p>
            </ModalBody>

            <ModalFooter>
              <Button
                color="primary"
                onClick={async (e) => {
                  await this.invalidateSubmit();
                  this.setState({
                    confirmInvalidation: false,
                    invalidSubmit: null
                  });
                }}
              >
                Confirmar
              </Button>
              <Button
                onClick={(e) => {
                  this.setState({
                    confirmInvalidation: false,
                    invalidSubmit: null
                  });
                }}
              >
                Cancelar
              </Button>
            </ModalFooter>
          </Modal>
        )}

        <div className="pb-3 pt-2 pt-md-3">
          <Container className="mt-2 mb-2 mt-md-5" fluid>
            <Col className="mb-3">
              <div className="card shadow">
                <div className="card-header border-0">
                  <div className="row">
                    <h3 className="mb-0">Formulários salvos</h3>
                  </div>
                </div>
                {this.state.savedSubmitCategories.map((category, index) => (
                  <Card className="shadow font-weight-bold card-full" key={index}>
                    <CardHeader
                      className="card-header text-white background-primary"
                      onClick={(e) => {
                        let showSavedSubmit = this.state.showSavedSubmit;
                        showSavedSubmit[index] = !showSavedSubmit[index];
                        this.setState({ showSavedSubmit });
                      }}
                    >
                      <Row>
                        <Col xs="10" className="m-0 p-0">
                          {category.category} - {category.title}
                        </Col>
                        <Col xs="2" className="m-0 p-0 text-center">
                          <i className="ni ni-bold-down" color="white"></i>
                        </Col>
                      </Row>
                    </CardHeader>
                    {this.state.showSavedSubmit[index] && (
                      <div className="table-responsive" style={{ minHeight: '150px' }}>
                        <Table className="table align-items-center table-flush">
                          <thead className="thead-light">
                            <tr>
                              <th scope="col">Cliente</th>
                              <th scope="col">Código</th>
                              <th scope="col">Cidade</th>
                              <th scope="col">Data</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.savedSubmitList
                              .filter(
                                (f) =>
                                  f.submit.form.category == category.category &&
                                  f.submit.form.title == category.title
                              )
                              .map((form, indexForm) => (
                                <tr key={indexForm}>
                                  {form.submit.form.metadata.client ? (
                                    <>
                                      <td>{form.submit.form.metadata.client.name}</td>
                                      <td>{form.submit.form.metadata.client.code}</td>
                                      <td>
                                        {form.submit.form.metadata.client.address
                                          ? `${form.submit.form.metadata.client.address.city} - ${form.submit.form.metadata.client.address.state}`
                                          : ''}
                                      </td>
                                    </>
                                  ) : (
                                    <>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                    </>
                                  )}
                                  <td>{this.handleDate(form.submit.form.metadata.datetime)}</td>
                                  <td>
                                    <UncontrolledDropdown style={{ position: 'static' }}>
                                      <DropdownToggle
                                        className="btn-icon-only text-light"
                                        role="button"
                                        size="sm"
                                        onClick={(e) => e.preventDefault()}
                                      >
                                        <i className="fas fa-ellipsis-v" />
                                      </DropdownToggle>
                                      <DropdownMenu className="dropdown-menu-arrow" right>
                                        <DropdownItem
                                          onClick={(e) => {
                                            this.redirectForm({
                                              submit: form.submit,
                                              submitIdSave: form._id,
                                              isSave: true
                                            });
                                          }}
                                        >
                                          Continuar
                                        </DropdownItem>
                                        <DropdownItem
                                          onClick={(e) => {
                                            this.setState({
                                              invalidSubmit: {
                                                ...form.submit,
                                                _id: form._id,
                                                isSave: true
                                              },
                                              confirmInvalidation: true
                                            });
                                          }}
                                        >
                                          Remover
                                        </DropdownItem>
                                      </DropdownMenu>
                                    </UncontrolledDropdown>
                                  </td>
                                </tr>
                              ))}
                            {this.state.savedSubmitListFiltered.length == 0 && (
                              <tr>
                                <th scope="row">Não há Envios</th>
                              </tr>
                            )}
                          </tbody>
                        </Table>
                      </div>
                    )}
                  </Card>
                ))}
              </div>
            </Col>
          </Container>
        </div>
      </>
    );
  }
}

export default withRouter(SubmitManager);
