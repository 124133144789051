import React from 'react';
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import axios from 'axios';
import baseURL from '../../variables/api';

const NotificationsModalRemove = ({
  notification,
  isOpen,
  handleClose,
  handleRefresh,
  setLoading,
  setError
}) => {
  const handleRemoveAction = () => {
    setLoading(true);

    axios
      .delete(`${baseURL}/uptown/notifications/${notification.id}`, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('banana')
        }
      })
      .then((resp) => {
        setError('Remoção realizada com sucesso', 'success');
        handleClose();
        handleRefresh();
      })
      .catch((err) => {
        setError(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <Modal isOpen={isOpen} toggle={handleClose}>
        <ModalHeader>
          <h3>Excluir</h3>
        </ModalHeader>

        <ModalBody className="pt-0">
          <Row>
            <Col className="col-12">
              <span> Deseja realmente remover a notificação selecionada? </span>
            </Col>
          </Row>
        </ModalBody>

        <ModalFooter
          style={{
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <Button className="background-primary color-white" onClick={handleClose}>
            Não
          </Button>

          <Button className="background-secondary color-white" onClick={handleRemoveAction}>
            Sim
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default NotificationsModalRemove;
