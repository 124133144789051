import React from 'react';

// reactstrap components
import {
  Card,
  CardHeader,
  Container,
  Row,
  Button,
  Alert,
  CardBody,
  Form,
  FormGroup,
  InputGroup,
  InputGroupText,
  Input
} from 'reactstrap';
// core components
import NeutralHeader from '../../../components/Headers/NeutralHeader.jsx';
import axios from 'axios';
import Loader from '../../../components/Bridgestone/Loader.jsx';
import baseURL from '../../../variables/api';

class UsersEdit extends React.Component {
  state = {
    headers: ['Usuário', 'Email', 'Editar'],
    data: this.props.location.state.user,
    usersList: [],
    usersListFiltered: [],
    users: [],
    usersFiltered: [],
    pagesCount: 10,
    currentPage: 0,
    dataCopy: [],
    addUserModal: false,
    editUserModal: false,
    defaultModal: false,
    firstName: '',
    lastName: '',
    email: '',
    profileId: 1,
    regionId: 1,
    profilesList: this.props.location.state.profileList,
    regionsList: this.props.location.state.regionsList,
    storeCategoriesList: this.props.location.state.storeCategoriesList,
    categories: {
      category: []
    },
    storeCategories: [],
    regions: [],
    categoriesList: ['Programa de Excelência', 'Mystery Shopper'],
    checked: [],
    active: 'Y',
    passwordRecoverLink: ''
  };

  async componentDidMount() {
    this.handleCheckedCategories();

    this.setState({
      firstName: this.state.data.firstName,
      lastName: this.state.data.lastName,
      email: this.state.data.email,
      profileId: this.state.data.profileId,
      categories: {
        category: this.state.data.categories
      },
      active: this.state.data.active,
      regionId: this.state.data.regionalid,
      regions: this.state.data.regions,
      storeCategories: this.state.data.storeCategories
    });
  }

  handleCategories = (e) => {
    console.log(e.target.checked, e.target.value);

    if (e.target.checked === true) {
      let checkIfExists = this.state.categories.category.filter(
        (value, index) => this.state.categories.category.indexOf(value) === index
      );

      this.setState({
        categories: {
          category: [...checkIfExists, e.target.value]
        }
      });
    } else {
      const findPosition = this.state.categories.category.findIndex(
        (category) => category === e.target.value
      );

      this.state.categories.category.splice(findPosition, 1);
    }
  };

  handleRegions = (e) => {
    let value = parseInt(e.target.value);

    if (e.target.checked === true) {
      let regions = this.state.regions;
      let region = this.state.regionsList.find((r) => r.id === value);

      regions.push(region);
      this.setState({ regions });
    } else {
      let regions = this.state.regions.filter((r) => r.id !== value);

      this.setState({ regions });
    }
  };

  handleStoreCategories = (e) => {
    let value = parseInt(e.target.value);

    if (e.target.checked === true) {
      let storeCategories = this.state.storeCategories;
      let storeCategory = this.state.storeCategoriesList.find((category) => category.id === value);

      storeCategories.push(storeCategory);
      this.setState({ storeCategories });
    } else {
      let storeCategories = this.state.storeCategories.filter((category) => category.id !== value);

      this.setState({ storeCategories });
    }
  };

  handleSubmit = async () => {
    let firstName = this.state.firstName;
    let lastName = this.state.lastName;
    let email = this.state.email;
    let profileId = this.state.profileId;
    let categories = JSON.stringify(this.state.categories.category);
    let active = this.state.active;
    let regions = this.state.regions.map((r) => r.id);
    let storeCategories = this.state.storeCategories.map((c) => c.id);

    console.log('CATEGORIES >>>', this.state.categories);

    try {
      this.setState({ loading: true });
      let res = (
        await axios.put(
          baseURL + `/users/${this.state.data.id}`,
          {
            firstName,
            lastName,
            email,
            profileId,
            categories,
            active,
            regions,
            storeCategories
          },
          {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('banana')
            }
          }
        )
      ).data;
      this.setState({ loading: false });
      this.props.history.push('/admin/users');
    } catch (err) {
      console.error(err);
      this.setState({
        loading: false,
        error: true
      });
      setTimeout(() => {
        this.setState({ error: false });
      }, 4000);
    }
  };

  handleCheckedCategories = () => {
    for (let i = 0; i < 10; i++) {
      let findIndex = this.state.data.categories.filter(
        (category) => category === this.state.categoriesList[i]
      );

      if (findIndex.length > 0) {
        this.state.checked.push(true);
      } else {
        this.state.checked.push(false);
      }
    }
  };

  handlePasswordRecover = async () => {
    this.setState({ loading: true });

    try {
      let res = (
        await axios.post(
          baseURL + `/users/${this.state.data.id}/recover-link`,
          {},
          {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('banana')
            }
          }
        )
      ).data;

      this.setState({
        loading: false,
        passwordRecoverLink: res.link
      });
    } catch (e) {
      this.setState({
        loading: false,
        error: true,
        errorText: 'Erro ao gerar link de recuperação de senha'
      });
      setTimeout(() => {
        this.setState({ error: false, errorText: '' });
      }, 4000);
    }
  };

  render() {
    return (
      <>
        {this.state.loading && <Loader />}
        {this.state.error ? (
          <Alert color="danger" style={{ position: 'fixed', top: '30px', zIndex: 9999 }}>
            {this.state.errorText}
          </Alert>
        ) : (
          ''
        )}
        <NeutralHeader />
        {/* Page content */}
        <Container className="mt--7" fluid>
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <h3 className="mb-0">
                    Edição do usuário: {this.state.data.firstName} {this.state.data.lastName}
                  </h3>
                </CardHeader>
                <CardBody className="px-lg-5 py-lg-5">
                  <Form role="form">
                    <FormGroup className="mb-3">
                      <InputGroup className="input-group-alternative">
                        <InputGroupText addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-caps-small" />
                          </InputGroupText>
                        </InputGroupText>
                        <Input
                          placeholder={this.state.data.firstName}
                          type="text"
                          onChange={(e) => this.setState({ firstName: e.target.value })}
                        />
                      </InputGroup>
                    </FormGroup>
                    <FormGroup className="mb-3">
                      <InputGroup className="input-group-alternative">
                        <InputGroupText addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-caps-small" />
                          </InputGroupText>
                        </InputGroupText>
                        <Input
                          placeholder={this.state.data.lastName}
                          type="text"
                          onChange={(e) => this.setState({ lastName: e.target.value })}
                        />
                      </InputGroup>
                    </FormGroup>
                    <FormGroup className="mb-3">
                      <InputGroup className="input-group-alternative">
                        <InputGroupText addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-email-83" />
                          </InputGroupText>
                        </InputGroupText>
                        <Input
                          placeholder={this.state.data.email}
                          type="email"
                          onChange={(e) => this.setState({ email: e.target.value })}
                        />
                      </InputGroup>
                    </FormGroup>
                    <FormGroup className="mb-3">
                      <InputGroup className="input-group-alternative">
                        <InputGroupText addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-circle-08" />
                          </InputGroupText>
                        </InputGroupText>
                        <Input
                          type="select"
                          name="profileId"
                          id="profileId"
                          onChange={(e) => {
                            this.setState({ profileId: e.target.value });
                          }}
                          value={this.state.profileId}
                          placeholder="Selecione um perfil">
                          {this.state.profilesList.map((profile) => {
                            return (
                              <option key={profile.id} value={profile.id}>
                                {profile.name}
                              </option>
                            );
                          })}
                        </Input>
                      </InputGroup>
                    </FormGroup>
                    <FormGroup className="mb-3">
                      <InputGroup className="input-group-alternative">
                        <InputGroupText addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-lock-circle-open" />
                          </InputGroupText>
                        </InputGroupText>
                        <Input
                          type="select"
                          name="active"
                          id="active"
                          onChange={(e) => {
                            this.setState({ active: e.target.value });
                          }}
                          value={this.state.active}
                          placeholder="Selecione o status do usuário">
                          <option value="Y">Ativo</option>
                          <option value="N">Inativo</option>
                        </Input>
                      </InputGroup>
                    </FormGroup>
                    <div className="custom-control custom-control-alternative custom-checkbox mb-3">
                      <h3>Selecione as regiões do usuário: </h3>
                    </div>
                    {this.state.regionsList.map((region, key) => (
                      <div
                        key={`region${key}`}
                        className="custom-control custom-control-alternative custom-checkbox mb-3">
                        <input
                          className="custom-control-input"
                          id={`checkRegion${key}`}
                          type="checkbox"
                          value={region.id}
                          onClick={(e) => this.handleRegions(e)}
                          defaultChecked={this.state.regions.find((c) => c.id === region.id)}
                        />
                        <label className="custom-control-label" htmlFor={`checkRegion${key}`}>
                          {region.descr_pt}
                        </label>
                      </div>
                    ))}
                    <div className="custom-control custom-control-alternative custom-checkbox mb-3">
                      <h3>Selecione as categorias de lojas: </h3>
                    </div>
                    {this.state.storeCategoriesList.map((storeCategory, key) => (
                      <div
                        key={`storeCategory${key}`}
                        className="custom-control custom-control-alternative custom-checkbox mb-3">
                        <input
                          className="custom-control-input"
                          id={`checkStoreCategory${key}`}
                          type="checkbox"
                          value={storeCategory.id}
                          onClick={(e) => this.handleStoreCategories(e)}
                          defaultChecked={this.state.storeCategories.find(
                            (c) => c.id === storeCategory.id
                          )}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor={`checkStoreCategory${key}`}>
                          {storeCategory.descr_pt}
                        </label>
                      </div>
                    ))}
                    {this.state.passwordRecoverLink && (
                      <div className="text-center my-2">
                        <h3>Link de recuperação da senha:</h3>
                        <a
                          href={this.state.passwordRecoverLink}
                          target="_blank"
                          rel="noopener noreferrer">
                          {this.state.passwordRecoverLink}
                        </a>
                      </div>
                    )}
                    <div className="text-center">
                      <Button
                        className="my-2 background-secondary color-white"
                        type="button"
                        onClick={() => this.handlePasswordRecover()}>
                        Recuperar Senha
                      </Button>
                      <Button
                        className="my-2 background-primary color-white"
                        type="button"
                        onClick={() => this.handleSubmit()}>
                        Salvar
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

export default UsersEdit;
