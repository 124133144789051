import React, { useEffect, useState } from 'react';
import {
  Alert,
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from 'reactstrap';
import Loader from '../Bridgestone/Loader';
import axios from 'axios';
import baseURL from '../../variables/api';
import FormHelper from '../../helpers/FormHelper';

const PebGmb = () => {
  const [gmbList, setGmbList] = useState([]);
  const [scoreList, setScoreList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(undefined);
  const [selectedGmb, setSelectedGmb] = useState(undefined);

  const [score, setScore] = useState(0);
  const [quantidade, setQuantidade] = useState(0);
  const [arquivo, setArquivo] = useState(undefined);

  useEffect(() => {
    getGmbList();
    getScoreList();
  }, []);

  useEffect(() => {
    setScore(selectedGmb ? selectedGmb.score : 0);
    setQuantidade(selectedGmb ? selectedGmb.quantity : 0);
    setArquivo(undefined);
  }, [selectedGmb]);

  const getGmbList = async () => {
    setLoading(true);

    try {
      const response = (
        await axios.get(`${baseURL}/peb/gmb`, {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('banana')
          }
        })
      ).data;
      setGmbList(response);
    } catch (err) {
      handleError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const getScoreList = async () => {
    setLoading(true);

    try {
      const response = (
        await axios.get(`${baseURL}/peb/gmb/options`, {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('banana')
          }
        })
      ).data;
      setScoreList(response);
    } catch (err) {
      handleError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let formData = FormHelper.GenerateFormData(e, null);
    setLoading(true);

    axios
      .put(`${baseURL}/peb/gmb/${selectedGmb.id}`, formData, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('banana')
        }
      })
      .then((resp) => {
        setSelectedGmb(undefined);
        handleError(resp.data.message, 'success');
        getGmbList();
      })
      .catch((err) => {
        handleError(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleError = (message, color = 'danger') => {
    setError({ message, color });

    setTimeout(() => {
      setError(undefined);
    }, 4000);
  };

  return (
    <>
      {/* Page content */}
      {loading && <Loader />}
      {error ? (
        <Alert color={error.color} style={{ position: 'fixed', top: '30px', zIndex: 9999 }}>
          {error.message}
        </Alert>
      ) : (
        ''
      )}

      {/* CONTENT */}
      <Col className="p-0">
        <div className="card">
          <div className="card-content table-responsive">
            <table className="table align-items-center">
              <thead className="thead-light">
                <tr>
                  <th>CLIENTE</th>
                  <th>CATEGORIA</th>
                  <th>SCORE</th>
                  <th>QUANTIDADE</th>
                  <th style={{ textAlign: 'center' }}>AÇÃO</th>
                </tr>
              </thead>
              <tbody>
                {gmbList.map((row, k) => {
                  return (
                    <tr key={k}>
                      <td> {`${row.clientCode} - ${row.clientName}`} </td>
                      <td> {row.category} </td>
                      <td> {row.score} </td>
                      <td> {row.quantity} </td>
                      <td style={{ textAlign: 'center' }}>
                        <Button
                          className={`background-primary color-white`}
                          onClick={(e) => setSelectedGmb(row)}>
                          Editar
                        </Button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </Col>

      {/* AVALIAR MODAL */}
      <Modal isOpen={selectedGmb != undefined} toggle={() => setSelectedGmb(undefined)}>
        <form onSubmit={(e) => handleSubmit(e)}>
          <ModalHeader>
            <h3>{(selectedGmb && selectedGmb.clientName) || ''}</h3>
          </ModalHeader>

          <ModalBody className="pt-0">
            <Row>
              <Col className="col-md-6 col-12">
                <FormGroup>
                  <Label for="score">Nota do GMB:</Label>
                  <Input
                    type="select"
                    id="score"
                    name="score"
                    required
                    value={score}
                    onChange={(e) => setScore(e.target.value)}>
                    {scoreList.map((score, index) => {
                      return (
                        <option value={score.id} key={index}>
                          {' '}
                          {score.name}{' '}
                        </option>
                      );
                    })}
                  </Input>
                </FormGroup>
              </Col>

              <Col className="col-md-6 col-12">
                <FormGroup>
                  <Label for="quantity">Quantidade:</Label>
                  <Input
                    type="number"
                    id="quantity"
                    name="quantity"
                    required
                    value={quantidade}
                    onChange={(e) => setQuantidade(e.target.value)}
                  />
                </FormGroup>
              </Col>

              <Col className="col-md-12 col-12">
                <FormGroup>
                  <Label for="image">Imagem:</Label>
                  <Input
                    type="file"
                    id="image"
                    name="image"
                    label={(arquivo && arquivo.name) || 'Escolha um arquivo'}
                    onChange={(e) => setArquivo(e.target.files[0])}
                    required={true}
                  />
                </FormGroup>
              </Col>
            </Row>
          </ModalBody>

          <ModalFooter
            style={{
              display: 'flex',
              justifyContent: 'space-between'
            }}>
            <Button type="button" onClick={() => setSelectedGmb(undefined)}>
              Cancelar
            </Button>

            <Button type="submit" className="background-primary color-white">
              Salvar
            </Button>
          </ModalFooter>
        </form>
      </Modal>
    </>
  );
};

export default PebGmb;
