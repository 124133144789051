import './style.scss';

import React, { useContext, useEffect, useState } from 'react';
import { Card, CardHeader, CardBody, Container, Row, Col, Alert } from 'reactstrap';
import axios from 'axios';
import baseURL from '../../../variables/api';

import Loader from '../../../components/Bridgestone/Loader.jsx';

import { UserContext } from '../../../components/Contexts/UserContext';
import { StoreContext } from '../../../components/Contexts/StoreContext';
import Filters from '../../../components/Filters/Filters';
import ReformerData from './ReformerData';
import ReformerAddress from './ReformerAddress';
import RegisteredResponsible from './RegisteredResponsible';
import ManagerData from './ManagerData';
import { Divider } from '@mui/material';
import AdministrativeData from './AdministrativeData';
import InsideSellersData from './InsideSellersData';
import OutsideSellersData from './OutsideSellersData';
import TeleshoppingData from './TeleshoppingData';
import OperatorsData from './OperatorsData';
import ProfilesService from '../../../services/ProfilesService';

export default function Profile(props) {
  const { token } = useContext(UserContext);
  const { storeCode, selectedCategory } = useContext(StoreContext);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(undefined);
  const [data, setData] = useState({});

  useEffect(() => {
    if (!selectedCategory) {
      return;
    }

    handleRefresh();
  }, [storeCode, selectedCategory]);

  const handleRefresh = () => {
    setLoading(true);

    ProfilesService.Get(token, storeCode, selectedCategory)
      .then((resp) => {
        setData(resp.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleError = (message, color = 'danger') => {
    setError({ message, color });

    setTimeout(() => {
      setError(undefined);
    }, 4000);
  };

  return (
    <>
      {loading && <Loader />}
      {error && (
        <Alert color={error.color} style={{ position: 'fixed', top: '30px', zIndex: 9999 }}>
          {error.message}
        </Alert>
      )}

      <Container fluid>
        <Filters />

        <Row>
          <Col className="order-xl-1" xl="12">
            <Card className="shadow borderradius-default storeprofile-container">
              <CardHeader className="bg-white border-0 borderradius-default">
                <h6 className="text-uppercase text-light ls-1 mb-1"> Perfil da Loja </h6>
                <h2 className="mb-0">Dados cadastrais</h2>
              </CardHeader>
              <CardBody className="paddingX-none paddingTop-none">
                <ReformerData
                  data={data}
                  token={token}
                  storeCode={storeCode}
                  category={selectedCategory}
                  refreshData={handleRefresh}
                  setLoading={setLoading}
                  setError={handleError}
                />

                <ReformerAddress
                  data={data}
                  token={token}
                  storeCode={storeCode}
                  category={selectedCategory}
                  refreshData={handleRefresh}
                  setLoading={setLoading}
                  setError={handleError}
                />

                <Divider sx={{ marginTop: 2 }} />

                {/* <RegisteredResponsible
                  data={data} 
                  token={token}
                  storeCode={storeCode}
                  category={selectedCategory}
                  refreshData={handleRefresh} 
                  setLoading={setLoading} 
                  setError={handleError}
                />
                
                <Divider sx={{marginTop: 2}} /> */}

                {/* <ManagerData
                  data={data} 
                  token={token}
                  storeCode={storeCode}
                  category={selectedCategory}
                  refreshData={handleRefresh} 
                  setLoading={setLoading} 
                  setError={handleError}
                  idProfile={1}
                />
                
                <Divider sx={{marginTop: 2}} />
                
                <AdministrativeData
                  data={data} 
                  token={token}
                  storeCode={storeCode}
                  category={selectedCategory}
                  refreshData={handleRefresh} 
                  setLoading={setLoading} 
                  setError={handleError}
                  idProfile={2}
                />
                
                <Divider sx={{marginTop: 2}} />
                
                <InsideSellersData
                  data={data} 
                  token={token}
                  storeCode={storeCode}
                  category={selectedCategory}
                  refreshData={handleRefresh} 
                  setLoading={setLoading} 
                  setError={handleError}
                  idProfile={3}
                />
                
                <Divider sx={{marginTop: 2}} />
                
                <OutsideSellersData
                  data={data} 
                  token={token}
                  storeCode={storeCode}
                  category={selectedCategory}
                  refreshData={handleRefresh} 
                  setLoading={setLoading} 
                  setError={handleError}
                  idProfile={4}
                />
                
                <Divider sx={{marginTop: 2}} />
                
                <TeleshoppingData
                  data={data} 
                  token={token}
                  storeCode={storeCode}
                  category={selectedCategory}
                  refreshData={handleRefresh} 
                  setLoading={setLoading} 
                  setError={handleError}
                  idProfile={5}
                />
                
                <Divider sx={{marginTop: 2}} />
                
                <OperatorsData
                  data={data} 
                  token={token}
                  storeCode={storeCode}
                  category={selectedCategory}
                  refreshData={handleRefresh} 
                  setLoading={setLoading} 
                  setError={handleError}
                  idProfile={6}
                /> */}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}
