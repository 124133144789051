import React from 'react';
import { Grid, Button } from '@mui/material';
import TruckIcon from '../../../components/Icons/TruckIcon';
import CarIcon from '../../../components/Icons/CarIcon';
import { ShowChartOutlined } from '@mui/icons-material';

export default function ResultsCategories({ selected, handleChange }) {
  return (
    <Grid container spacing={4} className="buttons-container">
      <Grid item md={8} xs={12} className="box-buttons">
        <Button
          className={`item-button ${selected === 'ALL' ? 'selected' : ''}`}
          onClick={() => handleChange('ALL')}
          startIcon={<ShowChartOutlined className="category-icon" />}>
          Geral
        </Button>

        <Button
          className={`item-button ${selected === 'CAR' ? 'selected' : ''}`}
          onClick={() => handleChange('CAR')}
          startIcon={<CarIcon className="category-icon" />}>
          Car
        </Button>

        <Button
          className={`item-button ${selected === 'TRUCK' ? 'selected' : ''}`}
          onClick={() => handleChange('TRUCK')}
          startIcon={<TruckIcon className="category-icon" />}>
          Truck
        </Button>
      </Grid>
    </Grid>
  );
}
