import React, { useContext, useEffect, useState } from 'react';
import { Alert, Container, Row, Col, Card } from 'reactstrap';
import axios from 'axios';
import baseURL from '../../../variables/api';
import { UserContext } from '../../../components/Contexts/UserContext';
import Filters from '../../../components/Filters/Filters';
import Loader from '../../../components/Bridgestone/Loader';

// ? "https://api-demo.programadeexcelencia.com/uploads/f3a7992f-dcdb-46fe-b7f8-910debafb7be.pdf"
// : "https://api-demo.programadeexcelencia.com/uploads/a260238d-343d-4106-b980-32666fe1d2b3.pdf"

const Rules = () => {
  const { user } = useContext(UserContext);
  const [rules, setRules] = useState([]);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(undefined);

  useEffect(() => {
    handleRefreshRules();
  }, []);

  const handleRefreshRules = async () => {
    setLoading(true);

    try {
      const response = (
        await axios.get(`${baseURL}/peb/rules`, {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('banana')
          }
        })
      ).data;
      setRules(response);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading && <Loader />}

      {error && (
        <Alert color={error.color} style={{ position: 'fixed', top: '30px', zIndex: 9999 }}>
          {error.message}
        </Alert>
      )}

      <Container fluid>
        <Filters showCategories={false} />

        <Row>
          <Col lg="12" md="12">
            {rules.map((rule) => (
              <Card className="shadow mb-3" key={rule.id}>
                <iframe
                  src={rule.file}
                  style={{
                    height: '70vh'
                  }}
                />
              </Card>
            ))}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Rules;
