import React, { useContext, useEffect, useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Chart } from 'chart.js';
import { chartOptions, parseOptions } from '../../../variables/charts.jsx';

import { UserContext } from '../../../components/Contexts/UserContext';
import { StoreContext } from '../../../components/Contexts/StoreContext';

import Loader from '../../../components/Bridgestone/Loader';
import ErrorAlert from '../../../components/UI/ErrorAlert';
import Filters from '../../../components/Filters/Filters';
import ComparisonCharts from './ComparisonCharts';
import ActionPlansChart from './ActionPlansChart';
import CoopBudgetChart from './CoopBudgetChart';
import NotificationModal from './NotificationModal';

import './dashboard.css';
import Metrics from '../../../components/Statistics/Metrics.jsx';
import Satisfaction from '../../../components/Statistics/Satisfaction.jsx';
import DashboardService from '../../../services/DashboardService.js';
import ActionPlan from '../ActionPlan/ActionPlan.jsx';
import Ranking from '../Ranking/Ranking.jsx';
import ExtraScores from '../ExtraScores/ExtraScores.jsx';
import Nps from '../../../components/Statistics/Nps.jsx';
import QuestionnaireSubmissionStatus from '../../../components/Statistics/QuestionnaireSubmissionStatus.jsx';
import RulesAndLicenses from '../RulesAndLicenses/RulesAndLicenses.jsx';
import YourEmblems from '../YourEmblems/YourEmblems.jsx';

export default function Index(props) {
  const { user, token } = useContext(UserContext);
  const { store, storeCode, selectedCategory, consolidatedRegion } = useContext(StoreContext);

  const userIsStore = user.profile.id == 6;

  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [loading, setLoading] = useState(false);

  const [storeSacAvg, setStoreSacAvg] = useState();
  const [regionalSacAvg, setRegionalSacAvg] = useState();
  const [sacComparative, setSacComparative] = useState();
  const [evalStore, setEvalStore] = useState({ quantity: 0 });
  const [evalRegional, setEvalRegional] = useState({});
  const [evalComparative, setEvalComparative] = useState({});
  const [acbBudget, setACBBudget] = useState({});
  const [compareFilters, setCompareFilters] = useState([]);
  const [compare, setCompare] = useState({ id: 'regional', name: 'Regional' });

  const [metrics, setMetrics] = useState([]);
  const [tasksStatus, setTasksStatus] = useState({});

  useEffect(() => {
    if (window.Chart) {
      parseOptions(Chart, chartOptions());
    }
  }, [false]);

  useEffect(() => {
    fetchFilters();
  }, [false]);

  useEffect(() => {
    if (!selectedCategory || !evalComparative.Nacional) {
      return;
    }

    fetchDataOnCompareChange();
  }, [compare]);

  useEffect(() => {
    if (!selectedCategory || (!storeCode && consolidatedRegion && !consolidatedRegion.id)) {
      return;
    }

    init();
  }, [storeCode, selectedCategory, consolidatedRegion]);

  const fetchFilters = () => {
    setLoading(true);

    Promise.all([
      DashboardService.GetCompareFilters(token).then((resp) => {
        setCompareFilters(resp.data);
      })
    ]).finally(() => {
      setLoading(false);
    });
  };

  const fetchDataOnCompareChange = () => {
    setLoading(true);

    Promise.all([
      DashboardService.GetAreas(
        token,
        storeCode,
        selectedCategory,
        consolidatedRegion ? consolidatedRegion.id : null,
        compare.id
      ).then((resp) => {
        setMetrics(resp.data.metrics);

        setEvalStore({
          ...resp.data.storeEval.areasStore,
          quantity: resp.data.storeEval.quantity || 0
        });
        setEvalRegional(resp.data.regionalAvg.areas);
        setEvalComparative({
          Nacional: resp.data.nationalAvg.areas,
          Regional: resp.data.regionalAvg.areas,
          Sul: resp.data.southAvg.areas,
          Sudeste: resp.data.southeastAvg.areas,
          'Centro-Oeste': resp.data.midwestAvg.areas,
          Nordeste: resp.data.northEastAvg.areas,
          Norte: resp.data.northAvg.areas,
          'São Paulo': resp.data.saoPauloAvg.areas
        });
      })
    ]).finally(() => {
      setLoading(false);
    });
  };

  const init = () => {
    setLoading(true);

    Promise.all([
      // DashboardService.GetSac(token, storeCode, selectedCategory, consolidatedRegion ? consolidatedRegion.id : null, compare.id)
      //   .then(resp => {
      //     setStoreSacAvg(resp.data.storeAvg || 0);
      //     setRegionalSacAvg(resp.data.regionalAvg || 0);
      //     setSacComparative({
      //       "Nacional": resp.data.nationalAvg || 0,
      //       "Regional": resp.data.regionalAvg || 0,
      //       "Sul": resp.data.southAvg || 0,
      //       "Sudeste": resp.data.southeastAvg || 0,
      //       "Centro-Oeste": resp.data.midwestAvg || 0,
      //       "Nordeste": resp.data.northEastAvg || 0,
      //       "Norte": resp.data.northAvg || 0,
      //       "São Paulo": resp.data.saoPauloAvg || 0
      //     });
      //   }),

      DashboardService.GetAreas(
        token,
        storeCode,
        selectedCategory,
        consolidatedRegion ? consolidatedRegion.id : null,
        compare.id
      ).then((resp) => {
        setMetrics(resp.data.metrics);

        setEvalStore({
          ...resp.data.storeEval.areasStore,
          quantity: resp.data.storeEval.quantity || 0
        });
        setEvalRegional(resp.data.regionalAvg.areas);
        setEvalComparative({
          Nacional: resp.data.nationalAvg.areas,
          Regional: resp.data.regionalAvg.areas,
          Sul: resp.data.southAvg.areas,
          Sudeste: resp.data.southeastAvg.areas,
          'Centro-Oeste': resp.data.midwestAvg.areas,
          Nordeste: resp.data.northEastAvg.areas,
          Norte: resp.data.northAvg.areas,
          'São Paulo': resp.data.saoPauloAvg.areas
        });
      }),

      DashboardService.GetTasks(
        token,
        storeCode,
        selectedCategory,
        consolidatedRegion ? consolidatedRegion.id : null,
        compare.id
      ).then((resp) => {
        setTasksStatus(resp.data);
      })

      // DashboardService.GetACBBudget(token, storeCode, selectedCategory, consolidatedRegion ? consolidatedRegion.id : null, compare.id)
      //   .then(resp => {
      //     setACBBudget(resp.data);
      //   }),
    ]).finally(() => {
      setLoading(false);
    });
  };

  return (
    <>
      {loading && <Loader />}
      {error && <ErrorAlert text={errorText} />}

      <Container fluid>
        <Filters showCategories={true} />

        <Metrics data={metrics} />

        {![6, 7].includes(user.profileId) && (
          <QuestionnaireSubmissionStatus showRegionsStoresSelect={user.profileId != 2} />
        )}

        {(selectedCategory != 'BANDAG' || user.profileId == 1) && (
          <>
            <Nps
              compare={compare.id}
              showNpsDownloadAllButton={user.profileId == 1 && consolidatedRegion.id == 'nacional'}
              showNpsDownloadClientButton={userIsStore}
            />

            <Satisfaction
              compare={compare.id}
              showCopySatisfactionSurveyLink={userIsStore}
              showSatisfactionSurveyDownloadAllButton={
                user.profileId == 1 && consolidatedRegion.id == 'nacional'
              }
            />
          </>
        )}

        <ComparisonCharts
          evalComparative={evalComparative}
          evalRegional={evalRegional}
          evalStore={evalStore}
          storeSacAvg={storeSacAvg}
          regionalSacAvg={regionalSacAvg}
          sacComparative={sacComparative}
          regions={compareFilters}
          region={compare}
          changeRegion={(region) => setCompare(region)}
        />

        <Row className="mt-3">
          <Col xl="6">
            <ActionPlansChart details={tasksStatus} />
          </Col>

          <Col xl="6">{/* <CoopBudgetChart budget={acbBudget} /> */}</Col>
        </Row>

        {!userIsStore && (
          <>
            {storeCode != 0 && (
              <>
                <Row className="mt-3"></Row>

                <ActionPlan showFilters={false} containerClass="padding-none" />

                <Row className="mt-3"></Row>

                <ExtraScores
                  containerClass="padding-none"
                  showFilters={false}
                  showCardHeader={false}
                  showExamples={false}
                  openCardsReadonly={true}
                />

                {selectedCategory == 'BANDAG' && (
                  <RulesAndLicenses
                    containerClass="padding-none mt-3"
                    showFilters={false}
                    showCardHeader={false}
                    showExamples={false}
                    openCardsReadonly={true}
                  />
                )}

                <YourEmblems containerClass="padding-none mt-3" showFilters={false} />
              </>
            )}

            <Row className="mt-3"></Row>

            <Ranking
              showFilters={false}
              showRegionButtons={
                (consolidatedRegion && consolidatedRegion.regionId) || storeCode != 0
              }
              showAllRows={true}
              containerClass="padding-none"
              showDownloadButton={user.profileId == 2 && storeCode == 0}
            />
          </>
        )}

        <NotificationModal />
      </Container>
    </>
  );
}
