import Axios from 'axios';
import baseURL from '../variables/api';

const authToken = localStorage.getItem('banana');

const List = async () => {
  return await Axios.get(`${baseURL}/peb/managers`, {
    headers: {
      Authorization: 'Bearer ' + authToken
    }
  });
};

const ListByStore = async (storeCode) => {
  return await Axios.get(`${baseURL}/uptown/peb-store-managers/${storeCode}`, {
    headers: {
      Authorization: 'Bearer ' + authToken
    }
  });
};

const Add = async (storeCode, formData) => {
  return await Axios.post(
    `${baseURL}/uptown/peb-store-managers/${storeCode}`,
    Object.fromEntries(formData),
    {
      headers: {
        Authorization: 'Bearer ' + authToken
      }
    }
  );
};

const Remove = async (storeCode, managerId) => {
  return await Axios.delete(`${baseURL}/uptown/peb-store-managers/${storeCode}/${managerId}`, {
    headers: {
      Authorization: 'Bearer ' + authToken
    }
  });
};

const ManagerService = {
  List,
  ListByStore,
  Add,
  Remove
};

export default ManagerService;
