import React from 'react';

class NeutralHeader extends React.Component {
  render() {
    return (
      <>
        <div
          className="header header-gradient pb-8 pt-5 pt-lg-8 d-flex align-items-center"
          style={{ top: '-24px' }}></div>
      </>
    );
  }
}

export default NeutralHeader;
