import React, { useContext, useEffect, useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import axios from 'axios';
import baseURL from '../../../variables/api';

import { UserContext } from '../../../components/Contexts/UserContext';

export default function NotificationModal() {
  const [showModal, setShowModal] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [notification, setNotification] = useState(-1);
  const { user, token } = useContext(UserContext);

  useEffect(() => {
    if (user && user.profile.name === 'Loja') {
      fetchNotifications();
    }
  }, [user]);

  async function fetchNotifications() {
    try {
      const { data } = await axios.get(`${baseURL}/push-notifications`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      setNotifications(data.notifications);

      if (data.notifications.length > 0) {
        setNotification(0);
        setShowModal(true);
      }
    } catch (err) {
      console.log('ERR!', err);
    }
  }

  async function subscribeToNotification() {
    const currentNotification = notifications[notification];

    try {
      await axios.post(
        `${baseURL}/subscribe-push-notifications/${currentNotification.id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      const newNotifications = notifications.filter((item, index) => index !== notification);

      setNotifications(newNotifications);

      if (newNotifications.length > 0) {
        setNotification(0);
        setShowModal(true);
      } else {
        setNotification(-1);
      }
    } catch (err) {
      console.log('ERR!', err);
    }
  }

  const closeModal = () => {
    setShowModal(false);
    subscribeToNotification();
  };

  const toggleModal = () => {
    setShowModal(false);
  };

  return (
    <>
      {notification >= 0 && notifications[notification] && (
        <Modal isOpen={showModal} toggle={toggleModal} centered={true}>
          <ModalHeader toggle={closeModal}>{notifications[notification].title}</ModalHeader>
          <ModalBody>
            <p dangerouslySetInnerHTML={{ __html: notifications[notification].description }} />
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={closeModal}>
              Ok
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </>
  );
}
