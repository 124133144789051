import './style.scss';
import React, { useContext, useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { Alert, Card, CardBody, CardHeader, Container, Col, Row } from 'reactstrap';
import { Grid, MenuItem } from '@mui/material';
import TextField from '../../../components/TextField/TextField';
import SelectField from '../../../components/SelectField/SelectField';
import FormHelper from '../../../helpers/FormHelper';
import MaskHelper from '../../../helpers/MaskHelper';
import ClientsService from '../../../services/ClientsService';
import RegionsService from '../../../services/RegionsService';
import CategoriesService from '../../../services/CategoriesService';
import ManagersService from '../../../services/ManagersService';
import AdvisorsService from '../../../services/AdvisorsService';
import { tipoLojaData } from '../../../data/ExcellenceSubscriptionData';
import { StoreContext } from '../../../components/Contexts/StoreContext';
import { UserContext } from '../../../components/Contexts/UserContext';
import Loader from '../../../components/Bridgestone/Loader.jsx';
import DefaultTextField from '../../../components/TextField/TextField';
import ApiHelper from '../../../helpers/ApiHelper';
import DefaultButton from '../../../components/Button/Button';

const StoreSubscription = () => {
  const { loadCategories, setSelectedCategory, store, setStore, storeCode } =
    useContext(StoreContext);
  const { setUser } = useContext(UserContext);

  const [client, setClient] = useState({
    codigo: storeCode,
    codigoavo: '',
    cnpj: '',
    nomefantasia: '',
    razaosocial: '',
    categorias: [],
    telefone: '',
    email: '',
    nomeresponsavel: '',
    cargoresponsavel: '',
    tipoloja: '',
    logradouro: '',
    numero: '',
    bairro: '',
    complemento: '',
    cidade: '',
    uf: '',
    cep: '',
    regiao: '',
    gerente: '',
    assessor: ''
  });

  const [regioes, setRegioes] = useState([]);
  const [categorias, setCategorias] = useState([]);
  const [gerentes, setGerentes] = useState([]);
  const [assessores, setAssessores] = useState([]);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const handleChangeClient = (name, value) => {
    setClient({ ...client, [name]: value });
  };

  useEffect(() => {
    setClient({ ...client, codigo: storeCode });
  }, [storeCode]);

  useEffect(() => {
    setLoading(true);

    RegionsService.List()
      .then((resp) => {
        const regions = resp.data.map((r) => {
          return {
            value: r.id,
            text: r.descr_pt
          };
        });

        setRegioes(regions);
      })
      .finally(() => {
        setLoading(false);
      });

    setLoading(true);

    CategoriesService.List()
      .then((resp) => {
        const categories = resp.data.map((c) => {
          return {
            value: c.id,
            text: c.descr_pt
          };
        });

        setCategorias(categories);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [false]);

  useEffect(() => {
    handleChangeClient('gerente', '');

    if (client.categorias.length && client.regiao != '') {
      handleListManagers();
    }
  }, [client.categorias, client.regiao]);

  useEffect(() => {
    handleChangeClient('assessor', '');

    if (client.gerente != '') {
      handleListAdvisors();
    }
  }, [client.gerente]);

  const handleValidateCnpj = (cnpj, setter) => {
    if (cnpj.length > 0) {
      const validCnpj = FormHelper.ValidateCnpj(cnpj);

      if (!validCnpj) {
        setter('');
        setError('CNPJ inválido');
        setTimeout(() => setError(), 4000);
      }
    }
  };

  const handleListManagers = () => {
    setLoading(true);

    const categoria = client.categorias.includes(1) ? 1 : 2;

    ManagersService.List(categoria, client.regiao)
      .then((resp) => {
        const managers = resp.data.map((m) => {
          return {
            value: m.id,
            text: m.name
          };
        });

        setGerentes(managers);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleListAdvisors = () => {
    setLoading(true);

    AdvisorsService.List(client.gerente)
      .then((resp) => {
        const advisors = resp.data.map((a) => {
          return {
            value: a.id,
            text: a.name
          };
        });

        setAssessores(advisors);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const clearAllAddress = () => {
    handleChangeClient('cep', '');
    handleChangeClient('logradouro', '');
    handleChangeClient('bairro', '');
    handleChangeClient('cidade', '');
    handleChangeClient('estado', '');
  };

  const handleFindCep = () => {
    if (client.cep.length > 0) {
      const validCep = FormHelper.ValidateCep(client.cep);

      if (validCep) {
        setLoading(true);

        ApiHelper.FindCep(client.cep)
          .then((resp) => {
            if (resp.data.erro) {
              return;
            }

            setClient({
              ...client,
              cep: resp.data.cep || '',
              logradouro: resp.data.logradouro || '',
              bairro: resp.data.bairro || '',
              cidade: resp.data.localidade || '',
              uf: resp.data.uf || ''
            });
          })
          .catch((err) => {
            // clearAllAddress();
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        clearAllAddress();
        setError('CEP inválido');
        setTimeout(() => setError(), 4000);
      }
    }
  };

  const handleValidatePhone = (phone, setter) => {
    if (phone.length > 0) {
      const validPhone = FormHelper.ValidatePhone(phone);

      if (validPhone) {
        setter(phone);
      } else {
        setter('');
        setError('Telefone inválido');
        setTimeout(() => setError(), 4000);
      }
    }
  };

  const handleValidateEmail = (email, setter) => {
    if (email.length > 0) {
      const validEmail = FormHelper.ValidateEmail(email);

      if (validEmail) {
        setter(email);
      } else {
        setter('');
        setError('E-mail inválido');
        setTimeout(() => setError(), 4000);
      }
    }
  };

  const handleChangeCategories = (e) => {
    const value = typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value;

    // não permitir loja hibrida com categoria car
    const found = value.find((v) => v == 1);
    if (found && value.length > 1) return;

    handleChangeClient('categorias', value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setLoading(true);

    ClientsService.Subscription(client)
      .then((resp) => {
        const storeAux = resp.data.user.clients.find((client) => client.code == storeCode);

        loadCategories(resp.data.user);
        setSelectedCategory(storeAux.categories[0].descr_pt);
        setUser(resp.data.user);
        setStore(storeAux);
      })
      .catch((err) => {
        if (err.response.data.errors && err.response.data.errors.length > 0) {
          setError(err.response.data.errors[0].message);
        } else if (err.response.data.message) {
          setError(err.response.data.message);
        } else {
          setError('Ocorreu ao enviar inscrição da loja');
          console.error({ ...err });
        }

        setTimeout(() => setError(), 4000);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      {loading && <Loader />}
      {error && (
        <Alert color="danger" style={{ position: 'fixed', top: '30px', zIndex: 9999 }}>
          {error}
        </Alert>
      )}
      {store.pending === 'N' && <Redirect push to={{ pathname: '/admin/dashboard' }} />}

      <Container fluid>
        <Row>
          <Col className="order-xl-1" xl="12">
            <Card className="shadow borderradius-default storeprofile-container">
              <CardHeader className="bg-white border-0 borderradius-default">
                <h6 className="text-uppercase text-light ls-1 mb-1">
                  Inscrição Programa de Excelência
                </h6>
                <h1 className="mb-0">Conclua a inscrição da revenda/recapadora</h1>
                <p>
                  <b>IMPORTANTE</b>: Se você possui operações de TRUCK CENTER e BANDAG no mesmo
                  código e quer concorrer nas duas categorias este ano, selecione as duas categorias
                  no campo "Categorias que concorrerá".
                </p>
              </CardHeader>
              <CardBody className="paddingX-none paddingTop-none">
                <Grid container padding={3} component="form" onSubmit={(e) => handleSubmit(e)}>
                  <Grid item xs={12} marginBottom={2}>
                    <h3 className="text-uppercase mb-0">Dados da revenda / reformadora</h3>
                  </Grid>

                  <Grid item md={6} xs={12} className="container-form-fields">
                    <Grid item xs={12} className="paddingright-8px padding-none-lg">
                      <TextField
                        type="number"
                        label="Código da loja"
                        name="codigo"
                        value={client.codigo}
                        readonly={true}
                        onChange={(e) =>
                          handleChangeClient('codigo', MaskHelper.IntegerNumber(e.target.value))
                        }
                      />
                    </Grid>

                    <Grid item xs={12} className="paddingright-8px padding-none-lg">
                      <TextField
                        type="number"
                        label="Código avô"
                        name="codigoavo"
                        value={client.codigoavo}
                        onChange={(e) =>
                          handleChangeClient('codigoavo', MaskHelper.IntegerNumber(e.target.value))
                        }
                      />
                    </Grid>

                    <Grid item xs={12} className="paddingright-8px padding-none-lg">
                      <TextField
                        label="CNPJ"
                        name="cnpj"
                        value={client.cnpj}
                        onChange={(e) =>
                          handleChangeClient(
                            'cnpj',
                            MaskHelper.Cnpj(
                              e.target.value,
                              client.cnpj.length > e.target.value.length
                            )
                          )
                        }
                        onBlur={(_) =>
                          handleValidateCnpj(client.cnpj, (value) =>
                            handleChangeClient('cnpj', value)
                          )
                        }
                      />
                    </Grid>

                    <Grid item xs={12} className="paddingright-8px padding-none-lg">
                      <TextField
                        label="Razão social"
                        name="razaosocial"
                        value={client.razaosocial}
                        maxlength={255}
                        onChange={(e) => handleChangeClient('razaosocial', e.target.value)}
                      />
                    </Grid>

                    <Grid item xs={12} className="paddingright-8px padding-none-lg">
                      <TextField
                        label="Nome fantasia"
                        name="nomefantasia"
                        value={client.nomefantasia}
                        maxlength={255}
                        onChange={(e) => handleChangeClient('nomefantasia', e.target.value)}
                      />
                    </Grid>
                  </Grid>

                  <Grid item md={6} xs={12} className="container-form-fields">
                    <Grid item xs={12} className="paddingright-8px padding-none-lg">
                      <SelectField
                        label="Sua regional"
                        name="regiao"
                        value={client.regiao}
                        onChange={(e) => handleChangeClient('regiao', e.target.value)}
                        data={regioes}
                      />
                    </Grid>

                    <Grid item xs={12} className="paddingright-8px padding-none-lg">
                      <SelectField
                        label="Tipo de loja"
                        name="tipoloja"
                        value={client.tipoloja}
                        onChange={(e) => handleChangeClient('tipoloja', e.target.value)}
                        data={tipoLojaData}
                      />
                    </Grid>

                    <Grid item xs={12} className="paddingright-8px padding-none-lg">
                      <SelectField
                        label="Categorias que concorrerá"
                        name="categorias"
                        multiple
                        fullWidth
                        required
                        value={client.categorias}
                        onChange={handleChangeCategories}
                        data={categorias}
                      >
                        {categorias.map((categoria) => (
                          <MenuItem key={categoria.value} value={categoria.value}>
                            {categoria.text}
                          </MenuItem>
                        ))}
                      </SelectField>
                    </Grid>

                    <Grid item xs={12} className="paddingright-8px padding-none-lg">
                      <SelectField
                        label="Gerente regional responsável"
                        name="gerente"
                        value={client.gerente}
                        onChange={(e) => handleChangeClient('gerente', e.target.value)}
                        data={gerentes}
                      />
                    </Grid>

                    <Grid item xs={12} className="paddingright-8px padding-none-lg">
                      <SelectField
                        label="Assessor comercial responsável"
                        name="assessor"
                        value={client.assessor}
                        onChange={(e) => handleChangeClient('assessor', e.target.value)}
                        data={assessores}
                      />
                    </Grid>
                  </Grid>

                  <Grid item xs={12} marginTop={4} marginBottom={2}>
                    <h3 className="text-uppercase mb-0">Endereço da revenda / reformadora</h3>
                  </Grid>

                  <Grid container>
                    <Grid item lg={4} xs={12} className="paddingright-8px padding-none-lg">
                      <DefaultTextField
                        label="CEP"
                        name="cep"
                        value={client.cep}
                        onChange={(e) =>
                          handleChangeClient(
                            'cep',
                            MaskHelper.Cep(
                              e.target.value,
                              client.cep.length > e.target.value.length
                            )
                          )
                        }
                        onBlur={handleFindCep}
                      />
                    </Grid>

                    <Grid item lg={8} xs={12} className="paddingleft-8px padding-none-lg">
                      <DefaultTextField
                        label="Endereço"
                        name="logradouro"
                        value={client.logradouro}
                        onChange={(e) => handleChangeClient('logradouro', e.target.value)}
                      />
                    </Grid>

                    <Grid item lg={2} xs={12} className="paddingright-8px padding-none-lg">
                      <DefaultTextField
                        label="Número"
                        name="logradouroNumero"
                        value={client.numero}
                        required={false}
                        onChange={(e) => handleChangeClient('numero', e.target.value)}
                      />
                    </Grid>

                    <Grid
                      item
                      lg={3}
                      xs={12}
                      className="paddingright-8px paddingleft-8px padding-none-lg"
                    >
                      <DefaultTextField
                        label="Bairro"
                        name="bairro"
                        value={client.bairro}
                        onChange={(e) => handleChangeClient('bairro', e.target.value)}
                      />
                    </Grid>

                    <Grid
                      item
                      lg={2}
                      xs={12}
                      className="paddingright-8px paddingleft-8px padding-none-lg"
                    >
                      <DefaultTextField
                        label="Complemento"
                        name="complemento"
                        value={client.complemento}
                        onChange={(e) => handleChangeClient('complemento', e.target.value)}
                        required={false}
                      />
                    </Grid>

                    <Grid
                      item
                      lg={3}
                      xs={12}
                      className="paddingright-8px paddingleft-8px padding-none-lg"
                    >
                      <DefaultTextField
                        label="Cidade"
                        name="cidade"
                        value={client.cidade}
                        onChange={(e) => handleChangeClient('cidade', e.target.value)}
                        required={true}
                      />
                    </Grid>

                    <Grid item lg={2} xs={12} className="paddingleft-8px padding-none-lg">
                      <DefaultTextField
                        label="UF"
                        name="uf"
                        value={client.uf}
                        onChange={(e) => handleChangeClient('uf', e.target.value.toUpperCase())}
                        required={true}
                        maxlength={2}
                      />
                    </Grid>
                  </Grid>

                  <Grid item xs={12} marginBottom={2} marginTop={4}>
                    <h3 className="text-uppercase mb-0">
                      Dados da pessoa responsável pelo programa dentro da revenda
                    </h3>
                  </Grid>

                  <Grid container>
                    <Grid
                      item
                      lg={4}
                      xs={12}
                      className="paddingright-8px paddingleft-8px padding-none-lg"
                    >
                      <DefaultTextField
                        label="Nome do responsável"
                        name="responsible_name"
                        value={client.nomeresponsavel}
                        onChange={(e) => handleChangeClient('nomeresponsavel', e.target.value)}
                      />
                    </Grid>

                    <Grid item lg={4} xs={12} className="paddingright-8px padding-none-lg">
                      <DefaultTextField
                        label="Telefone para contato"
                        name="telefone"
                        value={client.telefone}
                        onChange={(e) =>
                          handleChangeClient(
                            'telefone',
                            MaskHelper.Phone(
                              e.target.value,
                              client.telefone.length > e.target.value.length
                            )
                          )
                        }
                        onBlur={(_) =>
                          handleValidatePhone(client.telefone, (value) =>
                            handleChangeClient('telefone', value)
                          )
                        }
                      />
                    </Grid>

                    <Grid
                      item
                      lg={4}
                      xs={12}
                      className="paddingright-8px paddingleft-8px padding-none-lg"
                    >
                      <DefaultTextField
                        label="Cargo do responsável"
                        name="cargoresponsavel"
                        value={client.cargoresponsavel}
                        onChange={(e) => handleChangeClient('cargoresponsavel', e.target.value)}
                      />
                    </Grid>

                    <Grid
                      item
                      lg={6}
                      xs={12}
                      className="paddingright-8px paddingleft-8px padding-none-lg"
                    >
                      <DefaultTextField
                        label="E-mail do responsável"
                        name="email"
                        value={client.email}
                        onChange={(e) => handleChangeClient('email', e.target.value)}
                        onBlur={(_) =>
                          handleValidateEmail(client.email, (value) =>
                            handleChangeClient('email', value)
                          )
                        }
                      />
                    </Grid>
                  </Grid>

                  <Grid item xs={12} marginTop={5} textAlign="center">
                    <DefaultButton
                      label="Salvar"
                      type="submit"
                      className="background-red fontsize-12"
                      fullWidth={false}
                      size="small"
                    />
                  </Grid>
                </Grid>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default StoreSubscription;
