import React, { createContext, useEffect, useState } from 'react';

export const StoreContext = createContext({});

export default function StoreProvider({ children }) {
  const [store, setStore] = useState();
  const [storeCode, setStoreCode] = useState(0);
  const [storeName, setStoreName] = useState('');
  const [storeAvatar, setStoreAvatar] = useState('');
  const [storeCategory, setStoreCategory] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [consolidatedRegion, setConsolidatedRegion] = useState(undefined);
  const [consolidatedRegions, setConsolidatedRegions] = useState(undefined);
  const [storeContainerOpened, setStoreContainerOpened] = useState(false);
  const [hasCarCategory, setHasCarCategory] = useState(false);
  const [hasTBRCategory, setHasTBRCategory] = useState(false);
  const [hasBDGCategory, setHasBDGCategory] = useState(false);

  useEffect(() => {
    if (store) {
      setStoreName(store.comercial_name);
      setStoreCode(store.code);
      // setStoreCategory(store.categories[0].descr_pt);
      setStoreAvatar(store.avatar && store.avatar.trim().length > 0 ? store.avatar : '');
    }
    if (store == null) {
      setStoreCode(0);
      setStoreName('');
      setStoreCategory('');
      setStoreAvatar('');
    }
  }, [store]);

  const loadCategories = (user) => {
    let carCategory,
      truckCategory,
      bandagCategory = false;

    let userCategoryIds = [];

    if (user && user.storeCategories) {
      user.storeCategories.forEach((c) => {
        userCategoryIds.push(c.id);
        if (c.descr_pt === 'CAR') carCategory = true;
        if (c.descr_pt === 'TRUCK') truckCategory = true;
        if (c.descr_pt === 'BANDAG') bandagCategory = true;
      });
    }

    const currentStore = store
      ? store
      : user.clients.find((client) =>
          client.categories.some((c) => userCategoryIds.includes(c.id))
        );

    let category = selectedCategory;

    if (currentStore && currentStore.categories.length) {
      currentStore.categories.forEach((c) => {
        if (userCategoryIds.includes(c.id)) {
          category = c.descr_pt;
          return;
        }
      });
    }

    if (!category && user && user.storeCategories) {
      category = user.storeCategories[0].descr_pt;
    }

    setSelectedCategory(category);
    setHasTBRCategory(truckCategory);
    setHasCarCategory(carCategory);
    setHasBDGCategory(bandagCategory);
  };

  return (
    <StoreContext.Provider
      value={{
        store,
        setStore,
        storeCode,
        setStoreCode,
        storeAvatar,
        storeCategory,
        setStoreCategory,
        storeName,
        selectedCategory,
        setSelectedCategory,
        consolidatedRegion,
        setConsolidatedRegion,
        consolidatedRegions,
        setConsolidatedRegions,
        storeContainerOpened,
        setStoreContainerOpened,
        loadCategories,
        hasCarCategory,
        hasTBRCategory,
        hasBDGCategory
      }}>
      {children}
    </StoreContext.Provider>
  );
}
