import React, { useState, useContext, useEffect } from 'react';
import { Alert, Card, CardHeader, Container, Row, CardBody } from 'reactstrap';

import { UserContext } from '../../../components/Contexts/UserContext';
import { StoreContext } from '../../../components/Contexts/StoreContext';
import PaginationControls from '../../../components/UI/Pagination/PaginationControls';
import Filters from '../../../components/Filters/Filters';
import Satisfaction from '../../../components/Statistics/Satisfaction';
import {
  Grid,
  Button as MuiButton,
  Divider,
  IconButton,
  Typography,
  Menu,
  MenuItem,
  Rating
} from '@mui/material';
import { KeyboardArrowDown } from '@mui/icons-material';
import SatisfactionService from '../../../services/SatisfactionService';
import Loader from '../../../components/Bridgestone/Loader';
import Nps from '../../../components/Statistics/Nps';
import DialogDownloadSurveys from '../../../components/Dialog/DialogDownloadSurveys';

const listCategoriaPesquisa = [
  {
    id: 0,
    name: 'Mais recentes',
    expression: (x, y) => {
      return new Date(y.date) - new Date(x.date);
    }
  },
  {
    id: 1,
    name: 'Menor avaliação',
    expression: (x, y) => {
      return x.stars - y.stars;
    }
  },
  {
    id: 2,
    name: 'Maior avaliação',
    expression: (x, y) => {
      return y.stars - x.stars;
    }
  }
];

const rowsPerPage = 5;

export default function SatisfactionSurvey(props) {
  const { token } = useContext(UserContext);
  const { storeCode, storeName, selectedCategory } = useContext(StoreContext);

  const [anchorElCategoriaPesquisaMenu, setAnchorElCategoriaPesquisaMenu] = useState(null);
  const [categoriaPesquisaSelecionada, setCategoriaPesquisaSelecionada] = useState(
    listCategoriaPesquisa[0]
  );

  const [listPesquisas, setListPesquisas] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const listPesquisasFiltradas = listPesquisas.sort(categoriaPesquisaSelecionada.expression);
  const [modalDownloadSurveys, setModalDownloadSurveys] = useState(false);

  useEffect(() => {
    if (!selectedCategory) {
      return;
    }

    // setLoading(true);

    SatisfactionService.List(token, storeCode, selectedCategory)
      .then((resp) => {
        setListPesquisas(resp.data.surveys);
      })
      .finally(() => {
        // setLoading(false);
      });
  }, [storeCode, selectedCategory]);

  const paginator = (items, currentPage) => {
    const page = currentPage || 1;
    const perPage = rowsPerPage;
    const offset = (page - 1) * perPage;

    return items.slice(offset).slice(0, perPage);
  };

  const handleError = (message, color = 'danger') => {
    setError({ message, color });

    setTimeout(() => {
      setError(undefined);
    }, 4000);
  };

  return (
    <>
      {loading && <Loader />}

      {error && (
        <Alert color={error.color} style={{ position: 'fixed', top: '30px', zIndex: 9999 }}>
          {error.message}
        </Alert>
      )}

      <Container fluid>
        <Filters />

        <Satisfaction
          showAverage={false}
          showDetailsDivider={true}
          showCopySatisfactionSurveyLink={true}
        />

        <Nps showNpsDownloadClientButton={true} />

        <Row>
          <div className="col mt-4">
            <Card className="shadow borderradius-default">
              <CardHeader className="border-0 borderradius-default">
                <Grid container spacing={2} alignItems="center">
                  <Grid item lg={6} xs={12}>
                    <h6 className="text-uppercase text-light ls-1 mb-1"> Avaliação do cliente </h6>
                    <h2 className="mb-0">Avaliações dos clientes</h2>
                  </Grid>

                  <Grid item lg={6} xs={12} textAlign="right">
                    <MuiButton
                      className="background-primary color-white borderradius-default fontsize-12"
                      onClick={() => setModalDownloadSurveys(true)}
                    >
                      Baixar pesquisas
                    </MuiButton>
                  </Grid>

                  <Grid item xs={12} marginTop={2}>
                    <Divider />
                  </Grid>
                </Grid>
              </CardHeader>
              <CardBody className="paddingtop-none">
                <Row>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item lg={8} xs={12}>
                      <Satisfaction
                        containerProps={{ className: 'paddingY-none' }}
                        averageProps={{
                          className: 'background-white padding-none'
                        }}
                        showHeader={false}
                        showDetails={false}
                      />
                    </Grid>

                    <Grid item lg={4} xs={12} textAlign="right" paddingRight={2} marginTop={1}>
                      <Typography variant="caption" component="h6">
                        <span className="fontweight-600">Classificar por: </span>
                        {categoriaPesquisaSelecionada.name}

                        <IconButton
                          className="background-primary color-white ml-3"
                          size="small"
                          onClick={(e) => setAnchorElCategoriaPesquisaMenu(e.currentTarget)}
                        >
                          <KeyboardArrowDown fontSize="small" />
                        </IconButton>
                      </Typography>
                    </Grid>

                    <Grid item xs={12} marginTop={2}>
                      <Grid container>
                        {paginator(listPesquisasFiltradas, currentPage).map((pesquisa, index) => {
                          return (
                            <Grid item xs={12} key={index} paddingY={2} paddingX={2}>
                              <Typography variant="subtitle1" className="fontweight-600">
                                {pesquisa.name || 'Anônimo'}
                              </Typography>

                              <Grid container alignItens="center" paddingBottom={1}>
                                <Rating
                                  value={Math.trunc(pesquisa.stars) || 0}
                                  precision={1}
                                  readOnly
                                  size="small"
                                />

                                <Typography variant="caption" className="ml-3">
                                  {new Date(`${pesquisa.date}T00:00`).toLocaleDateString()}
                                </Typography>
                              </Grid>

                              <Typography variant="body2">{pesquisa.message || ''}</Typography>
                            </Grid>
                          );
                        })}
                      </Grid>
                    </Grid>

                    <Grid item xs={12}>
                      <PaginationControls
                        totalItems={listPesquisasFiltradas.length}
                        itemsPerPage={rowsPerPage}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                      />
                    </Grid>
                  </Grid>
                </Row>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>

      <Menu
        anchorEl={anchorElCategoriaPesquisaMenu}
        open={Boolean(anchorElCategoriaPesquisaMenu)}
        onClose={() => setAnchorElCategoriaPesquisaMenu(null)}
        PaperProps={{
          className: 'background-primary mt-2',
          sx: {
            paddingY: 1,
            borderRadius: 4
          }
        }}
      >
        {listCategoriaPesquisa.map((categoria, index) => {
          return (
            <MenuItem
              key={index}
              onClick={(_) => {
                setCategoriaPesquisaSelecionada(categoria);
                setAnchorElCategoriaPesquisaMenu(null);
              }}
              style={{
                backgroundColor:
                  categoria == categoriaPesquisaSelecionada ? 'rgba(0, 0, 0, 0.1)' : ''
              }}
            >
              <Typography variant="subtitle2" className="color-white">
                {categoria.name}
              </Typography>
            </MenuItem>
          );
        })}
      </Menu>

      <DialogDownloadSurveys
        isOpen={modalDownloadSurveys}
        setClosedDialog={() => setModalDownloadSurveys(false)}
        token={token}
        setLoading={setLoading}
        setError={handleError}
        selectedCategory={selectedCategory}
        store={true}
        storeCode={storeCode}
        storeName={storeName}
      />
    </>
  );
}
