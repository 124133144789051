import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardText,
  CardTitle,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane
} from 'reactstrap';
import FormHelper from '../../helpers/FormHelper';
import ManagerService from '../../services/ManagerService';

const StoresModalManagers = ({ store, isOpen, handleClose, setLoading, setError }) => {
  const [currentTab, setCurrentTab] = useState(0);

  const [nome, setNome] = useState('');
  const [email, setEmail] = useState('');
  const [telefone, setTelefone] = useState('');
  const [posicao, setPosicao] = useState('');

  const [managerList, setManagerList] = useState([]);

  useEffect(() => {
    if (isOpen && store) {
      resetForm();
      handleRefreshManagerList();
      setCurrentTab(0);
    }
  }, [isOpen]);

  const resetForm = () => {
    setNome('');
    setEmail('');
    setTelefone('');
    setPosicao('');
  };

  const handleRefreshManagerList = () => {
    setLoading(true);

    ManagerService.ListByStore(store.code)
      .then((resp) => {
        setManagerList(resp.data);
      })
      .catch((err) => {
        setError('Ocorreu um erro ao tentar listar os responsáveis da loja');
        console.log(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleAddAction = (e) => {
    e.preventDefault();

    let formData = FormHelper.GenerateFormData(e, null);

    setLoading(true);

    ManagerService.Add(store.code, formData)
      .then((resp) => {
        setError('Responsável cadastrado com sucesso', 'success');
        handleRefreshManagerList();
        resetForm();
      })
      .catch((err) => {
        setError('Ocorreu um erro ao tentar realizar a operação');
        console.log(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleRemoveAction = (manager) => {
    if (!window.confirm('Deseja realmente remover o responsável selecionado?')) {
      return;
    }

    setLoading(true);

    ManagerService.Remove(store.code, manager.id)
      .then((resp) => {
        setError('Responsável excluído com sucesso', 'success');
        handleRefreshManagerList();
      })
      .catch((err) => {
        setError('Ocorreu um erro ao tentar realizar a operação');
        console.log(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <Modal isOpen={isOpen} toggle={handleClose}>
        <ModalHeader>
          <h3>Responsáveis</h3>
        </ModalHeader>

        <ModalBody className="pt-0">
          <Nav tabs>
            <NavItem>
              <NavLink
                active={currentTab === 0}
                onClick={() => setCurrentTab(0)}
                className="cursor-pointer"
              >
                Adicionar
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                active={currentTab === 1}
                onClick={() => setCurrentTab(1)}
                className="cursor-pointer"
              >
                Remover
              </NavLink>
            </NavItem>
          </Nav>

          <TabContent activeTab={currentTab} className="mt-3">
            <TabPane tabId={0}>
              <form onSubmit={handleAddAction}>
                <Row>
                  <Col sm="12">
                    <FormGroup>
                      <Label for="name">Nome:</Label>
                      <Input
                        type="text"
                        id="name"
                        name="name"
                        required={true}
                        value={nome}
                        onChange={(e) => setNome(e.target.value)}
                      />
                    </FormGroup>
                  </Col>

                  <Col sm="12">
                    <FormGroup>
                      <Label for="email">E-mail:</Label>
                      <Input
                        type="email"
                        id="email"
                        name="email"
                        required={true}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </FormGroup>
                  </Col>

                  <Col sm="12">
                    <FormGroup>
                      <Label for="phone">Telefone:</Label>
                      <Input
                        type="text"
                        id="phone"
                        name="phone"
                        maxlength={11}
                        required={true}
                        value={telefone}
                        onChange={(e) => setTelefone(e.target.value)}
                      />
                    </FormGroup>
                  </Col>

                  <Col sm="12">
                    <FormGroup>
                      <Label for="position">Posição:</Label>
                      <Input
                        type="text"
                        id="position"
                        name="position"
                        required={true}
                        value={posicao}
                        onChange={(e) => setPosicao(e.target.value)}
                      />
                    </FormGroup>
                  </Col>

                  <Col
                    sm="12"
                    className="mt-4"
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between'
                    }}
                  >
                    <Button className="background-primary color-white" onClick={handleClose}>
                      Voltar
                    </Button>

                    <Button type="submit" className="background-secondary color-white">
                      Adicionar
                    </Button>
                  </Col>
                </Row>
              </form>
            </TabPane>

            <TabPane tabId={1}>
              <Row>
                {managerList.map((manager) => {
                  return (
                    <Col sm={12} className="mt-3" key={manager.id}>
                      <Card body className="shadow p-3">
                        <CardTitle className="mb-2">
                          <span> {manager.name} </span>
                          <span className="fontsize-13 display-block width-100perc">
                            {' '}
                            {manager.position}{' '}
                          </span>
                        </CardTitle>

                        <CardText className="mb-0">
                          <span className="fontsize-13 display-block width-100perc">
                            {manager.email}
                          </span>
                          <span className="fontsize-13 display-block width-100perc">
                            {manager.phone}
                          </span>
                        </CardText>

                        <Button
                          className="background-secondary color-white shadow-none"
                          onClick={() => handleRemoveAction(manager)}
                          style={{
                            position: 'absolute',
                            bottom: '1rem',
                            right: '1rem'
                          }}
                          title="Remover"
                        >
                          <i className="fas fa-trash" />
                        </Button>
                      </Card>
                    </Col>
                  );
                })}

                <Col sm={12} className="mt-4">
                  <Button className="background-primary color-white" onClick={handleClose}>
                    Voltar
                  </Button>
                </Col>
              </Row>
            </TabPane>
          </TabContent>
        </ModalBody>
      </Modal>
    </>
  );
};

export default StoresModalManagers;
