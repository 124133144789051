import React from 'react';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Typography,
  Button,
  Grid,
  Divider
} from '@mui/material';
import AuditService from '../../../services/AuditService';

const DialogValidateTask = ({
  isOpen,
  setClosedDialog = () => {},
  task,
  handleRefresh,
  setLoading,
  setError
}) => {
  const handleSubmit = (e) => {
    e.preventDefault();

    setLoading(true);

    AuditService.ValidateTask(task.id, task.type.id)
      .then((resp) => {
        setError(`Tarefa validada com sucesso`, 'success');
        setClosedDialog();
        handleRefresh();
      })
      .catch((err) => {
        setError('Não foi possível validar a tarefa');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Dialog
      open={isOpen}
      maxWidth="xs"
      fullWidth={true}
      onClose={setClosedDialog}
      PaperProps={{
        className: `dialog-default dialog-audit-validatetask`
      }}
    >
      <Box component="form" onSubmit={(e) => handleSubmit(e)}>
        <DialogTitle className="dialogtitle-default" sx={{ paddingTop: 4 }}>
          <Typography variant="subtitle1" className="fontweight-600 textalign-center">
            Tem certeza que deseja validar essa tarefa?
          </Typography>
        </DialogTitle>

        <DialogContent className="dialogcontent-default">
          <Grid container paddingX={2} paddingTop={2}>
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions
          className="dialogactions-default"
          sx={{ paddingX: 4, paddingBottom: 4, justifyContent: 'space-between' }}
        >
          <Button
            type="button"
            className={`borderradius-default background-grey-3 color-black`}
            size="small"
            onClick={setClosedDialog}
          >
            Não
          </Button>

          <Button
            type="submit"
            className="background-red color-white borderradius-default"
            size="small"
          >
            Sim
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default DialogValidateTask;
