import React, { useEffect, useState } from 'react';
import { Alert, Button, Col, Container, Nav } from 'reactstrap';
import Loader from '../../../components/Bridgestone/Loader';
import axios from 'axios';
import baseURL from '../../../variables/api';
import RulesModalAddEdit from '../../../components/RulesCrud/ModalAddEdit';
import RulesModalRemove from '../../../components/RulesCrud/ModalRemove';

const RulesCrud = () => {
  const [rules, setRules] = useState([]);
  const [perfis, setPerfis] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(undefined);

  const [selectedRule, setSelectedRule] = useState(undefined);
  const [openedModalAddEdit, setOpenedModalAddEdit] = useState(false);
  const [openedModalRemove, setOpenedModalRemove] = useState(false);

  useEffect(() => {
    handleRefreshRules();
    handleRefreshProfiles();
  }, []);

  const handleRefreshRules = async () => {
    setLoading(true);

    try {
      const response = (
        await axios.get(`${baseURL}/uptown/rules`, {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('banana')
          }
        })
      ).data;
      setRules(response);
    } catch (err) {
      handleError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleRefreshProfiles = async () => {
    setLoading(true);

    try {
      const response = (
        await axios.get(`${baseURL}/users/profiles`, {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('banana')
          }
        })
      ).data;
      setPerfis(response);
    } catch (err) {
      handleError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleError = (message, color = 'danger') => {
    setError({ message, color });

    setTimeout(() => {
      setError(undefined);
    }, 4000);
  };

  return (
    <>
      {/* Page content */}
      {loading && <Loader />}
      {error ? (
        <Alert color={error.color} style={{ position: 'fixed', top: '30px', zIndex: 9999 }}>
          {error.message}
        </Alert>
      ) : (
        ''
      )}

      <Container className="mt-2 mt-md-5 mb-2 action-plan-container" fluid>
        <Nav
          className="mt-6 nav-fill flex-column flex-sm-row justify-content-flex-end"
          pills
          role="tablist"
        >
          <Button
            size="sm"
            onClick={(e) => {
              setSelectedRule(undefined);
              setOpenedModalAddEdit(true);
            }}
          >
            Adicionar +
          </Button>
        </Nav>

        {/* CONTENT */}
        <Col className="p-0 mt-3">
          <div className="card">
            <div className="card-content table-responsive">
              <table className="table align-items-center">
                <thead className="thead-light">
                  <tr>
                    <th>LINK</th>
                    <th>PERFIS</th>
                    <th style={{ textAlign: 'center' }}>AÇÕES</th>
                  </tr>
                </thead>
                <tbody>
                  {rules.map((rule, k) => {
                    return (
                      <tr key={k}>
                        <td>
                          <a href={rule.file} target="_blank">
                            {rule.file}
                          </a>
                        </td>

                        <td>{rule.profiles.map((c) => c.name).join(', ')}</td>

                        <td style={{ textAlign: 'center' }}>
                          <Button
                            className={`background-secondary color-white`}
                            title="Editar"
                            onClick={(e) => {
                              setSelectedRule(rule);
                              setOpenedModalAddEdit(true);
                            }}
                          >
                            <i className="fas fa-pen" />
                          </Button>

                          <Button
                            className={`background-primary color-white`}
                            title="Remover"
                            onClick={(e) => {
                              setSelectedRule(rule);
                              setOpenedModalRemove(true);
                            }}
                          >
                            <i className="fas fa-trash" />
                          </Button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </Col>
      </Container>

      <RulesModalAddEdit
        rule={selectedRule}
        perfisList={perfis}
        isOpen={openedModalAddEdit}
        handleClose={(_) => setOpenedModalAddEdit(false)}
        handleRefresh={handleRefreshRules}
        setLoading={setLoading}
        setError={handleError}
      />

      <RulesModalRemove
        rule={selectedRule}
        isOpen={openedModalRemove}
        handleClose={(_) => setOpenedModalRemove(false)}
        handleRefresh={handleRefreshRules}
        setLoading={setLoading}
        setError={handleError}
      />
    </>
  );
};

export default RulesCrud;
