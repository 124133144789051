/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from 'react';

// reactstrap components
import {
  Container,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  Table,
  UncontrolledDropdown,
  Dropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  Input,
  Button
} from 'reactstrap';

import './fixed-header.css';

class RichTable extends React.Component {
  state = {
    headers: [],
    data: [],
    dataSorted: [],
    summary: [],
    orders: [],
    currentOrder: null,
    filters: [],
    activeFilters: [],
    actionColumns: [],
    dataFilter: []
  };
  componentDidMount() {
    if (this.props.headers) this.setState({ headers: this.props.headers });
    if (this.props.data) this.setState({ data: this.props.data, dataSorted: this.props.data });
    if (this.props.summary) this.setState({ summary: this.props.summary });
    if (this.props.actionColumns) this.setState({ actionColumns: this.props.actionColumns });
    if (this.props.changeTab) this.setState({ changeTab: this.props.changeTab });
  }
  componentWillReceiveProps({ data, headers, summary, actionColumns, dataFilter }) {
    if (actionColumns == null) {
      actionColumns = [];
    }

    this.setState({
      ...this.state,
      data,
      dataSorted: data,
      dataFilter,
      headers,
      summary,
      actionColumns
    });
  }

  filterAll() {
    if (this.state.dataFilter) {
      let activeFilters = this.state.activeFilters;
      let filters = this.state.filters;
      let dataSorted = this.state.dataFilter;

      for (let k of activeFilters) {
        dataSorted = dataSorted.filter((line) => {
          return line[k].formated.toLowerCase().includes(filters[k].toLowerCase());
        });
      }

      this.setState({ dataSorted, orders: [] });
    } else {
      let activeFilters = this.state.activeFilters;
      let filters = this.state.filters;
      let dataSorted = this.state.data;

      for (let k of activeFilters) {
        dataSorted = dataSorted.filter((line) => {
          return line[k].formated.toLowerCase().includes(filters[k].toLowerCase());
        });
      }

      this.setState({ dataSorted, orders: [] });
    }
  }

  clearAllFilters() {
    let data = this.state.data;
    this.setState({
      activeFilters: [],
      filters: [],
      orders: [],
      dataSorted: data
    });
  }

  async removeFilter(k) {
    let { activeFilters, filters } = this.state;

    activeFilters.splice(activeFilters.indexOf(k), 1);
    filters[k] = '';

    await this.setState({ activeFilters, filters });
    this.filterAll();
  }

  async addFilter(k) {
    let activeFilters = this.state.activeFilters;
    activeFilters.push(k);

    await this.setState({ activeFilters });
    this.filterAll();
  }

  order(colIndex) {
    if (this.state.actionColumns.includes(colIndex)) {
      return;
    }
    let dataSorted = this.state.dataSorted;
    let order = this.state.orders[colIndex];

    dataSorted.sort((lineA, lineB) => {
      if (isNaN(lineA[colIndex].value)) {
        if (lineA[colIndex].value == undefined) {
          lineA[colIndex].value = '';
        }
        if (lineB[colIndex].value == undefined) {
          lineB[colIndex].value = '';
        }
        var x = lineA[colIndex].value.toLowerCase();
        var y = lineB[colIndex].value.toLowerCase();
        if (x < y) {
          return -1 * order;
        }
        if (x > y) {
          return 1 * order;
        }
        return 0;
      }
      return (lineA[colIndex].value - lineB[colIndex].value) * order;
    });
    this.setState({ dataSorted });
  }

  render() {
    return (
      <>
        <div>
          {(this.state.activeFilters.length > 0 || this.state.orders.length > 0) && (
            <div>
              <Button
                className="m-2"
                onClick={() => {
                  this.clearAllFilters();
                }}
              >
                Limpar Filtros
              </Button>
            </div>
          )}
          <Table className="align-items-center table-flush" responsive size="sm">
            <thead className="thead-light">
              <tr>
                {this.state.headers.map((header, k) => (
                  <th
                    scope="col"
                    key={k}
                    style={{ cursor: 'pointer', position: 'sticky', top: 0 }}
                    onClick={() => {
                      if (this.state.actionColumns.includes(k)) {
                        return;
                      }
                      let orders = this.state.orders;
                      if (orders[k] == undefined) {
                        orders[k] = 1;
                      } else {
                        orders[k] = orders[k] * -1;
                      }
                      let currentOrder = k;
                      this.setState({ orders, currentOrder });
                      this.order(k);
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between'
                      }}
                    >
                      <div className="float-left">
                        <span>{header}</span>
                        &nbsp; &nbsp;
                        {this.state.currentOrder == k && this.state.orders[k] == 1 ? (
                          <i className="ni ni-bold-up" />
                        ) : this.state.currentOrder == k && this.state.orders[k] == -1 ? (
                          <i className="ni ni-bold-down" />
                        ) : (
                          ''
                        )}
                        &nbsp;
                      </div>
                      <div className="float-right">
                        {!this.state.actionColumns.includes(k) && (
                          <UncontrolledDropdown>
                            <DropdownToggle
                              className="btn-icon-only text-light"
                              role="button"
                              size="sm"
                              onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                              }}
                            >
                              <i className="fas fa-search" />
                            </DropdownToggle>
                            <DropdownMenu
                              className="dropdown-menu-arrow"
                              onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                              }}
                            >
                              <DropdownItem disabled>Buscar</DropdownItem>
                              <DropdownItem divider />
                              <DropdownItem disabled>
                                <Input
                                  type="text"
                                  value={this.state.filters[k]}
                                  onChange={(e) => {
                                    let filters = this.state.filters;
                                    filters[k] = e.target.value;
                                    this.setState({ filters });
                                  }}
                                />
                              </DropdownItem>
                              <DropdownItem divider />
                              <DropdownItem
                                onClick={(e) => {
                                  this.addFilter(k);
                                }}
                              >
                                Buscar
                              </DropdownItem>
                              <DropdownItem divider />
                              <DropdownItem
                                onClick={(e) => {
                                  this.clearAllFilters();
                                }}
                              >
                                Limpar
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        )}
                      </div>
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {this.state.dataSorted.map((line, k) => (
                <tr key={k}>
                  {line.map((cell, j) => {
                    let field = cell.formated;
                    if (field != null && field.toString().startsWith('#tab')) {
                      let num = field.split('#tab-')[1];
                      field = (
                        <Button
                          id={num}
                          onClick={() => {
                            this.state.changeTab(num);
                          }}
                        >
                          (tabela)
                        </Button>
                      );
                    }
                    if (field != null && field.toString().startsWith('https://')) {
                      let urls = field.toString().split(',');

                      field = urls.map((url, i) => <a href={url}>(Anexo {i + 1}) </a>);
                    }
                    return <td key={j}>{field}</td>;
                  })}
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </>
    );
  }
}

export default RichTable;
