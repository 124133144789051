import React, { useContext, useEffect, useState } from 'react';
import { Card, CardHeader, CardBody, Container, Row, Col } from 'reactstrap';
import Filters from '../../../components/Filters/Filters';
import { StoreContext } from '../../../components/Contexts/StoreContext';
import { UserContext } from '../../../components/Contexts/UserContext';
import ContentMaterialService from '../../../services/ContentMaterialService';
import Loader from '../../../components/Bridgestone/Loader';

const ContentMaterial = () => {
  const { token } = useContext(UserContext);
  const { selectedCategory } = useContext(StoreContext);

  const [loading, setLoading] = useState(false);
  const [contentMaterialList, setContentMaterialList] = useState([]);

  useEffect(() => {
    setLoading(true);

    ContentMaterialService.List(token, selectedCategory)
      .then((resp) => {
        setContentMaterialList(resp.data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [selectedCategory]);

  return (
    <>
      {loading && <Loader />}

      <Container fluid>
        <Filters />

        <Row>
          {contentMaterialList.map((material, index) => {
            return (
              <Col lg="4" md="6" className="mb-3" key={index}>
                <Card className="shadow">
                  <CardHeader className="bg-transparent">
                    <h3 className=" mb-0">{material.name}</h3>
                  </CardHeader>

                  <CardBody>
                    <img src={material.cover} width={'100%'} />

                    <a
                      href={material.file}
                      target="blank"
                      download={`${material.name.replace(/ /g, '_')}.${material.fileExtension}`}
                      className="btn-icon-clipboard"
                    >
                      <i className="ni ni-cloud-download-95 mr-2 text" />
                      Baixar {material.fileExtension.toUpperCase()}
                    </a>
                  </CardBody>
                </Card>
              </Col>
            );
          })}
        </Row>
      </Container>
    </>
  );
};

export default ContentMaterial;
