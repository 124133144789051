import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardText,
  CardTitle,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from 'reactstrap';
import AssessmentService from '../../services/AssessmentService';
import CategoryService from '../../services/CategoryService';

const limitPerPage = 10;

const AdvisorsModalAssessments = ({ advisor, isOpen, handleClose, setLoading, setError }) => {
  const [categoryList, setCategoryList] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(undefined);

  const [assessmentsList, setAssessmentsList] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    CategoryService.List().then((resp) => {
      setCategoryList(resp.data);
    });
  }, []);

  useEffect(() => {
    if (isOpen && advisor) {
      setCurrentPage(1);
      setSelectedCategory(categoryList[0]);
      handleRefreshAssessmentsList();
    }
  }, [isOpen]);

  useEffect(() => {
    if (isOpen && advisor) {
      setCurrentPage(1);
      handleRefreshAssessmentsList();
    }
  }, [selectedCategory]);

  useEffect(() => {
    if (isOpen && advisor) {
      handleRefreshAssessmentsList();
    }
  }, [currentPage]);

  const handleRefreshAssessmentsList = () => {
    setLoading(true);

    const categoryId =
      (selectedCategory && selectedCategory.id) || (categoryList[0] && categoryList[0].id) || 1;

    AssessmentService.List(advisor.id, categoryId, limitPerPage, currentPage)
      .then((resp) => {
        setAssessmentsList(resp.data.items);
        setTotalPages(resp.data.totalPages);
      })
      .catch((err) => {
        setError('Ocorreu um erro ao tentar listar as avaliações do assessor');
        console.log(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleRemoveAction = (assessment) => {
    if (!window.confirm('Deseja realmente remover a avaliação selecionada?')) {
      return;
    }

    setLoading(true);

    AssessmentService.Remove(assessment.id)
      .then(() => {
        setError('Avaliação excluída com sucesso', 'success');
        handleRefreshAssessmentsList();
      })
      .catch((err) => {
        setError('Ocorreu um erro ao tentar realizar a operação');
        console.log(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <Modal isOpen={isOpen} toggle={handleClose}>
        <ModalHeader>
          <h3>Avaliações</h3>
        </ModalHeader>

        <ModalBody className="pt-0 pb-3">
          <Row>
            {categoryList.map((category) => {
              const classes =
                category == selectedCategory ? 'background-secondary color-white' : '';
              return (
                <Col md={4} sm={12} key={category.id}>
                  <Button
                    className={`width-100perc ${classes}`}
                    onClick={() => setSelectedCategory(category)}>
                    {category.descr_pt}
                  </Button>
                </Col>
              );
            })}

            <Col sm={12} className="mt-4" />

            {assessmentsList.map((assessment) => {
              return (
                <Col sm={12} className="mt-3" key={assessment.id}>
                  <Card body className="shadow p-3">
                    <CardTitle className="mb-2">
                      <span> {assessment.client} </span>
                      <span className="fontsize-13 display-block width-100perc">
                        {' '}
                        {assessment.code}{' '}
                      </span>
                    </CardTitle>

                    <CardText className="mb-0">
                      <span className="fontsize-13 display-block width-100perc">
                        {assessment.date}
                      </span>
                    </CardText>

                    <Button
                      className="background-secondary color-white shadow-none"
                      onClick={() => handleRemoveAction(assessment)}
                      style={{
                        position: 'absolute',
                        bottom: '1rem',
                        right: '1rem'
                      }}
                      title="Remover">
                      <i className="fas fa-trash" />
                    </Button>
                  </Card>
                </Col>
              );
            })}

            {totalPages == 0 ? (
              <Col sm={12} className="mt-4 textalign-center">
                <span>Nenhuma avaliação encontrada para essa categoria...</span>
              </Col>
            ) : (
              <Col
                sm={12}
                className="mt-4"
                style={{
                  display: 'flex',
                  justifyContent: 'end'
                }}>
                <Button
                  className="shadow-none"
                  onClick={() => setCurrentPage(currentPage - 1)}
                  title="Página anterior"
                  disabled={currentPage <= 1}>
                  <i className="fas fa-angle-left"></i>
                </Button>

                <Button
                  className="background-secondary color-white shadow-none"
                  onClick={(e) => e.preventDefault()}>
                  {currentPage}
                </Button>

                <Button
                  className="shadow-none"
                  onClick={() => setCurrentPage(currentPage + 1)}
                  title="Próxima página"
                  disabled={currentPage >= totalPages}>
                  <i className="fas fa-angle-right"></i>
                </Button>
              </Col>
            )}
          </Row>
        </ModalBody>

        <ModalFooter
          style={{
            display: 'flex',
            justifyContent: 'space-between'
          }}>
          <Button className="background-primary color-white" onClick={handleClose}>
            Voltar
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default AdvisorsModalAssessments;
