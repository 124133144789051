import './style.scss';

import React, { useContext, useEffect, useState } from 'react';
import {
  Card,
  CardHeader,
  CardFooter,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  Container,
  Row,
  Alert
} from 'reactstrap';

import Filters from '../../../components/Filters/Filters';
import Loader from '../../../components/Bridgestone/Loader.jsx';

import { StoreContext } from '../../../components/Contexts/StoreContext';
import { UserContext } from '../../../components/Contexts/UserContext';
import { IconButton, ImageList, Menu, MenuItem, Tooltip, Typography } from '@mui/material';
import {
  Check,
  Close,
  HelpOutline,
  KeyboardArrowDown,
  NotificationsOutlined
} from '@mui/icons-material';
import ActionPlanService from '../../../services/ActionPlanService';
import DialogActionPlanFinishTask from '../../../components/Dialog/DialogActionPlanFinishTask';
import DialogActionPlanCancelTask from '../../../components/Dialog/DialogActionPlanCancelTask';
import DialogActionPlanRescheduleTask from '../../../components/Dialog/DialogActionPlanRescheduleTask';
import StringHelper from '../../../helpers/StringHelper';
import AttachmentCard from '../../../components/AttachmentCard/AttachmentCard';

const limitRows = 100;

export default function ActionPlan({ showFilters = true, containerClass = '' }) {
  const { storeCode, selectedCategory, consolidatedRegion } = useContext(StoreContext);
  const { user, token } = useContext(UserContext);

  const [anchorElCategoriaTarefaMenu, setAnchorElCategoriaTarefaMenu] = useState(null);
  const [listCategoriaTarefa, setListCategoriaTarefa] = useState([]);
  const [categoriaTarefaSelecionada, setCategoriaTarefaSelecionada] = useState(undefined);

  const [listTarefas, setListTarefas] = useState([]);
  const [tarefaSelecionada, setTarefaSelecionada] = useState(undefined);
  const [paginaAtual, setPaginaAtual] = useState(1);

  const [modalConcluirAberto, setModalConcluirAberto] = useState(false);
  const [modalReagendarAberto, setModalReagendarAberto] = useState(false);
  const [modalCancelarAberto, setModalCancelarAberto] = useState(false);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (!selectedCategory) {
      return;
    }

    setLoading(true);

    ActionPlanService.ListCategories(token, selectedCategory)
      .then((resp) => {
        const categories = resp.data;
        categories.unshift({ id: undefined, name: 'Todos' });

        setListCategoriaTarefa(categories);
        setCategoriaTarefaSelecionada(categories[0]);
      })
      .catch((err) => {
        console.error(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [selectedCategory]);

  useEffect(() => {
    if (!selectedCategory || listCategoriaTarefa.length === 0 || !categoriaTarefaSelecionada) {
      return;
    } else if (storeCode === 0 && consolidatedRegion && consolidatedRegion.id == null) {
      return;
    } else if (storeCode !== 0 && consolidatedRegion && consolidatedRegion.id != null) {
      return;
    }

    handleRefresh();
  }, [
    listCategoriaTarefa,
    categoriaTarefaSelecionada,
    storeCode,
    selectedCategory,
    consolidatedRegion,
    paginaAtual
  ]);

  const handleRefresh = () => {
    setLoading(true);

    ActionPlanService.List(
      token,
      storeCode,
      selectedCategory,
      categoriaTarefaSelecionada.id,
      consolidatedRegion ? consolidatedRegion.id : null,
      paginaAtual,
      limitRows
    )
      .then((resp) => {
        let result = resp.data;
        const priority = {
          Invalidado: 1,
          Atrasado: 2,
          Agendado: 3,
          'Em análise': 4,
          Concluído: 5,
          Cancelado: 6
        };

        result.sort((a, b) => {
          const index1 = priority[a.status];
          const index2 = priority[b.status];

          if (!index1) return 1;
          if (!index2) return -1;

          return index1 - index2;
        });

        setListTarefas(resp.data);
      })
      .catch((err) => {
        console.error(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleAction = (task, setter) => {
    setTarefaSelecionada(task);
    setter(true);
  };

  const handleError = (message, color = 'danger') => {
    setError({ message, color });

    setTimeout(() => {
      setError(undefined);
    }, 4000);
  };

  return (
    <>
      {loading && <Loader />}

      {error && (
        <Alert color={error.color} style={{ position: 'fixed', top: '30px', zIndex: 9999 }}>
          {error.message}
        </Alert>
      )}

      {/* Page content */}
      <Container fluid className={containerClass}>
        {showFilters && <Filters />}

        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow borderradius-default">
              <CardHeader className="border-0 borderradius-default">
                <h6 className="text-uppercase text-light ls-1 mb-1">
                  {' '}
                  {listTarefas.length} itens encontrados{' '}
                </h6>
                <h2 className="mb-0">Plano de ação - Melhorias agendadas</h2>
              </CardHeader>

              <Table className="align-items-center table-flush" responsive>
                <thead>
                  <tr>
                    <th scope="col">
                      Melhoria /{' '}
                      <span
                        className="color-primary cursor-pointer"
                        onClick={(e) => setAnchorElCategoriaTarefaMenu(e.currentTarget)}>
                        {' '}
                        {categoriaTarefaSelecionada
                          ? categoriaTarefaSelecionada.name.toUpperCase()
                          : '-'}{' '}
                      </span>
                      <IconButton
                        className="background-primary color-white ml-2"
                        size="small"
                        onClick={(e) => setAnchorElCategoriaTarefaMenu(e.currentTarget)}>
                        <KeyboardArrowDown fontSize="small" />
                      </IconButton>
                    </th>
                    <th scope="col">Data</th>
                    <th scope="col">Status</th>
                    {user.profile.id === 6 && (
                      <th scope="col" className="textalign-center">
                        Ações
                      </th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {listTarefas.map((task, k) => {
                    const statusNormalized = StringHelper.Normalize(task.status);

                    const showActions =
                      statusNormalized.indexOf('CANCEL') === -1 &&
                      statusNormalized.indexOf('CONCLU') === -1 &&
                      statusNormalized.indexOf('ANALIS') === -1;
                    const showTooltip = statusNormalized.indexOf('INVALID') !== -1;

                    return (
                      <tr key={k}>
                        <td
                          style={{
                            wordWrap: 'break-word',
                            whiteSpace: 'normal'
                          }}>
                          {task.title}
                        </td>

                        <td>{new Date(task.dueDate).toLocaleDateString('pt-BR')}</td>

                        {showTooltip ? (
                          <td className="color-primary">
                            {task.status}

                            <Tooltip
                              title={
                                <>
                                  <Typography
                                    variant="subtitle2"
                                    className="background-primary-2 color-white infotask-title"
                                    padding={1}>
                                    DETALHES DA INVALIDAÇÃO
                                  </Typography>

                                  <Typography
                                    variant="subtitle2"
                                    className="color-white fontsize-12"
                                    paddingX={2.5}
                                    paddingY={1.5}>
                                    <span className="fontweight-bold"> Motivo: </span>
                                    <Typography className="fontsize-14">
                                      {' '}
                                      {(task.reason && task.reason.description) || '-'}{' '}
                                    </Typography>
                                  </Typography>

                                  {task.attachments && (
                                    <Typography
                                      variant="subtitle2"
                                      className="color-white fontsize-12"
                                      paddingX={2.5}
                                      paddingY={1.5}>
                                      <span className="fontweight-bold">
                                        {' '}
                                        Comprovantes invalidados:{' '}
                                      </span>
                                      <ImageList cols={2} className="mt-2">
                                        {task.attachments.map((attachment) => {
                                          return (
                                            <AttachmentCard
                                              url={attachment}
                                              name={attachment}
                                              onClick={() => window.open(attachment)}
                                              showPreviewIcon={true}
                                            />
                                          );
                                        })}
                                      </ImageList>
                                    </Typography>
                                  )}
                                </>
                              }
                              arrow
                              componentsProps={{
                                tooltip: {
                                  className: 'infotask-tooltip background-primary'
                                },
                                arrow: {
                                  className: 'color-primary-2'
                                }
                              }}>
                              <IconButton
                                className="iconbutton-info background-primary color-white ml-2"
                                size="small">
                                <HelpOutline fontSize="inherit" />
                              </IconButton>
                            </Tooltip>
                          </td>
                        ) : (
                          <td> {task.status} </td>
                        )}

                        {user.profile.id === 6 && (
                          <td className="textalign-center">
                            {showActions && (
                              <>
                                <IconButton
                                  size="small"
                                  className="background-green color-white"
                                  onClick={(_) => handleAction(task, setModalConcluirAberto)}>
                                  <Check fontSize="inherit" />
                                </IconButton>

                                <IconButton
                                  size="small"
                                  className="background-yellow color-white ml-2"
                                  onClick={(_) => handleAction(task, setModalReagendarAberto)}>
                                  <NotificationsOutlined fontSize="inherit" />
                                </IconButton>

                                <IconButton
                                  size="small"
                                  className="background-red color-white ml-2"
                                  onClick={(_) => handleAction(task, setModalCancelarAberto)}>
                                  <Close fontSize="inherit" />
                                </IconButton>
                              </>
                            )}
                          </td>
                        )}
                      </tr>
                    );
                  })}
                </tbody>
              </Table>

              <CardFooter className="py-4 borderradius-default">
                <nav aria-label="...">
                  <Pagination
                    className="pagination justify-content-end mb-0"
                    listClassName="justify-content-end mb-0">
                    <PaginationItem className={`${paginaAtual <= 1 ? 'disabled' : ''}`}>
                      <PaginationLink
                        href="#prev"
                        onClick={(e) => {
                          e.preventDefault();

                          if (paginaAtual > 1) {
                            setPaginaAtual(paginaAtual - 1);
                          }
                        }}
                        tabIndex="-1">
                        <i className="fas fa-angle-left" />
                      </PaginationLink>
                    </PaginationItem>

                    <PaginationItem className="active">
                      <PaginationLink
                        href="#current"
                        tabIndex="-1"
                        onClick={(e) => e.preventDefault()}>
                        {paginaAtual}
                      </PaginationLink>
                    </PaginationItem>

                    <PaginationItem
                      className={`${listTarefas.length < limitRows ? 'disabled' : ''}`}>
                      <PaginationLink
                        href="#next"
                        tabIndex="-1"
                        onClick={(e) => {
                          e.preventDefault();

                          if (listTarefas.length >= limitRows) {
                            setPaginaAtual(paginaAtual + 1);
                          }
                        }}>
                        <i className="fas fa-angle-right" />
                      </PaginationLink>
                    </PaginationItem>
                  </Pagination>
                </nav>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>

      <Menu
        anchorEl={anchorElCategoriaTarefaMenu}
        open={Boolean(anchorElCategoriaTarefaMenu)}
        onClose={(_) => setAnchorElCategoriaTarefaMenu(null)}
        PaperProps={{
          className: 'background-primary mt-2',
          sx: {
            paddingY: 1,
            borderRadius: 4
          }
        }}>
        {listCategoriaTarefa.map((categoria, index) => {
          return (
            <MenuItem
              key={index}
              onClick={(_) => {
                setCategoriaTarefaSelecionada(categoria);
                setAnchorElCategoriaTarefaMenu(null);
              }}
              style={{
                backgroundColor:
                  categoria === categoriaTarefaSelecionada ? 'rgba(0, 0, 0, 0.1)' : ''
              }}>
              <Typography variant="subtitle2" className="color-white">
                {categoria.name}
              </Typography>
            </MenuItem>
          );
        })}
      </Menu>

      {user.profile.id === 6 && (
        <>
          <DialogActionPlanFinishTask
            isOpen={modalConcluirAberto}
            setClosedDialog={(_) => setModalConcluirAberto(false)}
            token={token}
            task={tarefaSelecionada}
            handleRefresh={handleRefresh}
            setLoading={setLoading}
            setError={handleError}
          />

          <DialogActionPlanRescheduleTask
            isOpen={modalReagendarAberto}
            setClosedDialog={(_) => setModalReagendarAberto(false)}
            token={token}
            task={tarefaSelecionada}
            handleRefresh={handleRefresh}
            setLoading={setLoading}
            setError={handleError}
          />

          <DialogActionPlanCancelTask
            isOpen={modalCancelarAberto}
            setClosedDialog={(_) => setModalCancelarAberto(false)}
            token={token}
            task={tarefaSelecionada}
            handleRefresh={handleRefresh}
            setLoading={setLoading}
            setError={handleError}
          />
        </>
      )}
    </>
  );
}
