import { Box, Divider, Menu, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import StringHelper from '../../helpers/StringHelper';
import { StoreContext } from '../Contexts/StoreContext';
import { UserContext } from '../Contexts/UserContext';
import DefaultTextField from '../TextField/TextField';

const StoreList = ({ anchorEl, handleClose, setStoreListLength }) => {
  const {
    setStore,
    storeCode,
    selectedCategory,
    consolidatedRegions,
    setConsolidatedRegion,
    consolidatedRegion
  } = useContext(StoreContext);
  const { user } = useContext(UserContext);

  const [filterListValue, setFilterListValue] = useState('');
  const [storeList, setStoreList] = useState([]);
  const [consolidatedRegionsList, setConsolidatedRegionsList] = useState([]);

  useEffect(() => {
    setFilterListValue('');
  }, [anchorEl]);

  useEffect(() => {
    if (!selectedCategory) {
      return;
    }

    if (user && user.clients) {
      setStoreList(
        user.clients.filter((client) => {
          if (client.code === storeCode) return false;
          if (client.pending === 'Y') return true;
          return client.categories.find((category) => category.descr_pt === selectedCategory);
        })
      );
    }

    if (consolidatedRegion) {
      setConsolidatedRegionsList(consolidatedRegions.filter((c) => c.id !== consolidatedRegion.id));
    }
  }, [user, consolidatedRegion, storeCode, selectedCategory]);

  useEffect(() => {
    setStoreListLength(storeList.length);
  }, [storeList]);

  const handleChangeStoreOrConsolidatedRegion = (region, store) => {
    setConsolidatedRegion(region);
    setStore(store);
    handleClose();
  };

  const handleListFiltering = (list, columns = []) => {
    if (filterListValue.trim().length > 0) {
      return list.filter((row) => {
        return columns.some((column) => {
          const columnValue = StringHelper.Normalize(row[column]);
          const filterValue = StringHelper.Normalize(filterListValue);

          return columnValue.indexOf(filterValue) > -1;
        });
      });
    }

    return list;
  };

  const consolidatedRegionsListFiltered = handleListFiltering(consolidatedRegionsList, ['name']);
  const storeListFiltered = handleListFiltering(storeList, ['name', 'code']);

  if (storeList.length == 0 && consolidatedRegionsList.length == 0) {
    return <> </>;
  }

  return (
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleClose}
      PaperProps={{
        className: 'mt-2',
        sx: {
          paddingY: 1,
          borderRadius: 4,
          width: 500,
          maxHeight: '80vh'
        }
      }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    >
      <Box paddingX={2} paddingTop={1} paddingBottom={3}>
        <DefaultTextField
          label="Pesquisar..."
          value={filterListValue}
          onChange={(e) => setFilterListValue(e.target.value)}
          required={false}
          margin="none"
        />
      </Box>

      {consolidatedRegionsListFiltered.map((region, index) => {
        return (
          <Typography
            key={index}
            className="storelist-menuitem"
            onClick={(_) => handleChangeStoreOrConsolidatedRegion(region, null)}
            variant="subtitle2"
          >
            {region.name}
          </Typography>
        );
      })}

      {consolidatedRegionsListFiltered.length > 0 && storeListFiltered.length > 0 && (
        <Box marginY={1}>
          <Divider />
        </Box>
      )}

      {storeListFiltered.map((store, index) => {
        return (
          <Typography
            key={index}
            className="storelist-menuitem"
            onClick={(_) => handleChangeStoreOrConsolidatedRegion({ id: null }, store)}
            variant="subtitle2"
          >
            {`${store.code} - ${store.name}`}
          </Typography>
        );
      })}
    </Menu>
  );
};

export default StoreList;
