import React from 'react';
import { SvgIcon } from '@mui/material';

const CarIcon = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 80 31.84" xmlns="http://www.w3.org/2000/svg">
      <g>
        <g>
          <path
            class="st0"
            d="M45.71,13.88h-4.9c-0.9,0-1.63,0.73-1.63,1.63s0.73,1.63,1.63,1.63h4.9c0.9,0,1.63-0.73,1.63-1.63
                        S46.62,13.88,45.71,13.88z"
          />
          <path
            class="st0"
            d="M79.95,20.02l-1.63-6.67c-0.14-0.57-0.58-1.03-1.15-1.18c-0.1-0.03-2.6-0.71-14.5-3.1L51.95,2.1
                        C48.64,0,43.43,0,38.84,0h-8.62C25.15,0,19.9,0,16.37,2.7L9.31,7.35H6.53c-2.7,0-4.9,2.2-4.9,4.9v2.59l-1.15,1.15
                        C0.17,16.29,0,16.71,0,17.14v1.63c0,4.09,3.05,6.53,8.16,6.53h0.16c0.76,3.72,4.06,6.53,8,6.53c3.94,0,7.24-2.81,8-6.53h30.53
                        c0.76,3.72,4.06,6.53,8,6.53c3.94,0,7.24-2.81,8-6.53h4.25c2.7,0,4.9-2.2,4.9-4.9C80,20.28,79.98,20.15,79.95,20.02z M39.18,3.27
                        c4.06,0,8.58,0.04,11.01,1.58l6.35,4.13H39.18V3.27z M30.22,3.27h5.7v5.71H24.67l4.67-5.71C29.64,3.27,29.93,3.27,30.22,3.27z
                        M16.33,28.57c-2.7,0-4.9-2.2-4.9-4.9s2.2-4.9,4.9-4.9s4.9,2.2,4.9,4.9S19.03,28.57,16.33,28.57z M62.86,28.57
                        c-2.7,0-4.9-2.2-4.9-4.9s2.2-4.9,4.9-4.9c2.7,0,4.9,2.2,4.9,4.9S65.56,28.57,62.86,28.57z M75.1,22.04h-4.25
                        c-0.76-3.72-4.06-6.53-8-6.53c-3.94,0-7.24,2.81-8,6.53H24.33c-0.76-3.72-4.06-6.53-8-6.53c-3.94,0-7.24,2.81-8,6.53H8.16
                        c-4.9,0-4.9-2.46-4.9-3.26v-0.96l0.68-0.68h4.22c0.9,0,1.63-0.73,1.63-1.63s-0.73-1.63-1.63-1.63H4.9v-1.63
                        c0-0.9,0.73-1.63,1.63-1.63H9.8c0.32,0,0.63-0.09,0.9-0.27l7.52-4.95c0.04-0.02,0.07-0.05,0.1-0.07c1.63-1.27,4.04-1.75,6.71-1.94
                        l-5.07,6.19c-0.4,0.49-0.48,1.16-0.21,1.73c0.27,0.57,0.84,0.93,1.48,0.93h40.66c8.22,1.65,11.98,2.49,13.5,2.85l1.34,5.48
                        C76.64,21.4,75.95,22.04,75.1,22.04z"
          />
        </g>
      </g>
    </SvgIcon>
  );
};

export default CarIcon;
