import React, { useEffect } from 'react';
import { Grid, Button } from '@mui/material';

const sections = [
  {
    title: 'PDV',
    value: 1
  },
  {
    title: 'Digital',
    value: 2
  },
  {
    title: 'Atendimento',
    value: 3
  }
];

export default function ResultsSections({ selected, handleChange }) {
  useEffect(() => {
    handleChange(sections[0].value);
  }, []);

  return (
    <Grid container spacing={4} className="buttons-container">
      <Grid item md={8} xs={12} className="box-buttons">
        {sections.map((s) => {
          return (
            <Button
              className={`item-button ${selected === s.value ? 'selected' : ''}`}
              onClick={() => handleChange(s.value)}
              key={s.value}>
              {s.title}
            </Button>
          );
        })}
      </Grid>
    </Grid>
  );
}
