import { Delete, Edit } from '@mui/icons-material';
import { IconButton, Typography } from '@mui/material';
import React, { useState, useEffect, useContext } from 'react';
import { Alert, Button, Col, Row, Container, FormGroup, Label, Input } from 'reactstrap';
import Loader from '../../../components/Bridgestone/Loader';
import FormHelper from '../../../helpers/FormHelper';
import TechnicalAssessmentService from '../../../services/TechnicalAssessmentService';
import { UserContext } from '../../../components/Contexts/UserContext';
import TechnicalAssessmentModalRemove from './ModalRemove';

const defaultTechnicalAssessment = {
  id: '',
  clients_id: '',
  pv: '',
  qpa: '',
  presentation: '',
  management: '',
  equipment: '',
  training: '',
  environment: '',
  gqc: '',
  code: '',
  created_at: '',
  client_name: '',
  attachment: ''
};

const TechnicalAssessment = () => {
  const { user } = useContext(UserContext);
  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(undefined);
  const [technicalAssessments, setTechnicalAssessments] = useState([]);
  const [attachment, setAttachment] = useState(undefined);

  const [openedModalRemove, setOpenedModalRemove] = useState(false);
  const [technicalAssessment, setTechnicalAssessment] = useState(defaultTechnicalAssessment);

  useEffect(() => {
    const clientsList = user.clients.sort((a, b) => a.code - b.code);
    setClients(clientsList);

    Promise.all([
      TechnicalAssessmentService.List().then((resp) => {
        setTechnicalAssessments(resp.data);
      })
    ]).finally(() => {
      setLoading(false);
    });
  }, [false]);

  const handleChangeTechnicalAssessment = (name, value) => {
    setTechnicalAssessment({ ...technicalAssessment, [name]: value });
  };

  const handleRefreshTechnicalAssessments = () => {
    TechnicalAssessmentService.List()
      .then((resp) => {
        setTechnicalAssessments(resp.data);
        setTechnicalAssessment(defaultTechnicalAssessment);
        setAttachment(undefined);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSubmitAction = (e) => {
    e.preventDefault();

    if (
      attachment &&
      !['image/jpg', 'image/jpeg', 'image/png', 'application/pdf'].includes(attachment.type)
    ) {
      setError('O formato do arquivo anexado é inválido');
      setTimeout(() => {
        setError('');
      }, 4000);
      return;
    }

    if (attachment && attachment.size > 1048576 * 5) {
      setError('O tamanho máximo permitido para anexo é de 5MB');
      setTimeout(() => {
        setError('');
      }, 4000);
      return;
    }

    const formData = FormHelper.GenerateFormData(e, null);
    setLoading(true);

    TechnicalAssessmentService.Submit(formData)
      .then((resp) => {
        setError('Questionário enviado com sucesso');
        setTimeout(() => {
          setError('');
        }, 4000);
      })
      .catch((err) => {
        setError('Não foi possível realizar a operação');
        setTimeout(() => {
          setError('');
        }, 4000);
      })
      .finally(() => handleRefreshTechnicalAssessments());
  };

  return (
    <>
      {/* Page content */}
      {loading && <Loader />}
      {error ? (
        <Alert color="danger" style={{ position: 'fixed', top: '30px', zIndex: 9999 }}>
          {error}
        </Alert>
      ) : (
        ''
      )}

      <Container className="mt-2 mt-md-5 mb-2 action-plan-container" fluid>
        <form onSubmit={(e) => handleSubmitAction(e)}>
          <Row>
            <Col className="col-md-6 col-12">
              <Typography variant="subtitle1" className="fontweight-600 mt-5 mb-1">
                Subir pontos do Questionário Técnico Bandag
              </Typography>

              <FormGroup>
                <Label>Selecionar cliente:</Label>
                <Input
                  type="select"
                  label="Selecionar cliente"
                  name="clients_id"
                  value={technicalAssessment.clients_id}
                  onChange={(e) => handleChangeTechnicalAssessment('clients_id', e.target.value)}
                  required={true}
                >
                  <option value="">Selecionar cliente</option>
                  {clients.map((client) => {
                    return (
                      <option value={client.id} key={client.id}>
                        {`${client.code} - ${client.name}`}
                      </option>
                    );
                  })}
                </Input>
              </FormGroup>
            </Col>
          </Row>

          <Row className="mt-1">
            <Col className="col-md-6 col-12">
              <Typography variant="subtitle1" className="fontweight-600 mt-3 mb-1">
                Qual foi a pontuação desta recapadora em:
              </Typography>

              <FormGroup>
                <Label>PV - Auditoria de Controle de Processo (até 1000 pts):</Label>
                <Input
                  type="number"
                  label="PV - Auditoria de Controle de Processo (até 1000 pts)"
                  name="pv"
                  max="1000"
                  min="0"
                  value={technicalAssessment.pv}
                  onChange={(e) => handleChangeTechnicalAssessment('pv', e.target.value)}
                  required={true}
                />
              </FormGroup>

              <FormGroup>
                <Label>QPA - Auditoria de Controle de Processo (até 250 pts):</Label>
                <Input
                  type="number"
                  label="QPA - Auditoria de Controle de Processo (até 250 pts)"
                  name="qpa"
                  max="250"
                  min="0"
                  value={technicalAssessment.qpa}
                  onChange={(e) => handleChangeTechnicalAssessment('qpa', e.target.value)}
                  required={true}
                />
              </FormGroup>

              <FormGroup>
                <Label>Apresentação da Planta (até 250 pts):</Label>
                <Input
                  type="number"
                  label="Apresentação da Planta (até 250 pts)"
                  name="presentation"
                  max="250"
                  min="0"
                  value={technicalAssessment.presentation}
                  onChange={(e) => handleChangeTechnicalAssessment('presentation', e.target.value)}
                  required={true}
                />
              </FormGroup>

              <FormGroup>
                <Label>Gestão e TI (até 125 pts):</Label>
                <Input
                  type="number"
                  label="Gestão e TI (até 125 pts)"
                  name="management"
                  max="125"
                  min="0"
                  value={technicalAssessment.management}
                  onChange={(e) => handleChangeTechnicalAssessment('management', e.target.value)}
                  required={true}
                />
              </FormGroup>

              <FormGroup>
                <Label>Equipamentos (até 125 pts):</Label>
                <Input
                  type="number"
                  label="Equipamentos (até 125 pts)"
                  name="equipment"
                  max="125"
                  min="0"
                  value={technicalAssessment.equipment}
                  onChange={(e) => handleChangeTechnicalAssessment('equipment', e.target.value)}
                  required={true}
                />
              </FormGroup>

              <FormGroup>
                <Label>Treinamento e Melhoria Contínua (até 125 pts):</Label>
                <Input
                  type="number"
                  label="Treinamento e Melhoria Contínua (até 125 pts)"
                  name="training"
                  max="125"
                  min="0"
                  value={technicalAssessment.training}
                  onChange={(e) => handleChangeTechnicalAssessment('training', e.target.value)}
                  required={true}
                />
              </FormGroup>

              <FormGroup>
                <Label>Meio Ambiente (até 125 pts):</Label>
                <Input
                  type="number"
                  label="Meio Ambiente (até 125 pts)"
                  name="environment"
                  max="125"
                  min="0"
                  value={technicalAssessment.environment}
                  onChange={(e) => handleChangeTechnicalAssessment('environment', e.target.value)}
                  required={true}
                />
              </FormGroup>

              <FormGroup>
                <Label>GQC (até 250 pts):</Label>
                <Input
                  type="number"
                  label="GQC (até 250 pts)"
                  name="gqc"
                  max="250"
                  min="0"
                  value={technicalAssessment.gqc}
                  onChange={(e) => handleChangeTechnicalAssessment('gqc', e.target.value)}
                  required={true}
                />
              </FormGroup>

              <FormGroup>
                <Label for="attachment">Anexar folha de rosto:</Label>
                <Input
                  type="file"
                  id="attachment"
                  name="attachment"
                  label={(attachment && attachment.name) || 'Escolha um arquivo'}
                  onChange={(e) => setAttachment(e.target.files[0])}
                  required={technicalAssessment.attachment ? false : true}
                  accept="image/*,application/pdf"
                />

                {technicalAssessment.attachment && (
                  <Label
                    style={{
                      display: 'block',
                      width: '100%',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      fontSize: '12px',
                      marginTop: '8px'
                    }}
                  >
                    Atual:
                    <a
                      href={technicalAssessment.attachment}
                      target="_blank"
                      className="ml-1"
                      rel="noreferrer"
                    >
                      {technicalAssessment.attachment}
                    </a>
                  </Label>
                )}
              </FormGroup>

              <Typography variant="body" className="mt-5">
                <b>Normas e Licenças (250 pts)</b>: Instrua seus clientes a enviarem para sua
                validação TODAS as normas e licenças indicadas no dashboard do PEB para ganhar estes
                pontos
              </Typography>
              <br />
              <br />

              <Button type="submit" className="background-secondary color-white">
                Enviar
              </Button>
            </Col>
          </Row>
        </form>

        <Typography variant="subtitle1" className="fontweight-600 mt-5 mb-1">
          Questionários enviados
        </Typography>

        <Row>
          <Col className="col-12">
            <div className="card">
              <div className="card-content table-responsive">
                <table className="table align-items-center">
                  <thead>
                    <tr>
                      <th scope="col">Cliente</th>
                      <th scope="col">Total Pontos</th>
                      <th scope="col">Enviado em</th>
                      <th scope="col">Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    {technicalAssessments.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td
                            className="py-1"
                            style={{
                              wordWrap: 'break-word',
                              whiteSpace: 'normal'
                            }}
                          >
                            {`${item.code} - ${item.client_name}`}
                          </td>
                          <td
                            className="py-1"
                            style={{
                              wordWrap: 'break-word',
                              whiteSpace: 'normal'
                            }}
                          >
                            {item.points}
                          </td>
                          <td className="py-1">
                            {new Date(item.created_at).toLocaleDateString('pt-BR')}
                          </td>
                          <td className="py-1">
                            <IconButton
                              size="small"
                              className="background-green color-white mr-1"
                              onClick={() => setTechnicalAssessment(item)}
                            >
                              <Edit fontSize="inherit" />
                            </IconButton>
                            <IconButton
                              size="small"
                              className="background-red color-white"
                              onClick={() => {
                                setTechnicalAssessment(item);
                                setOpenedModalRemove(true);
                              }}
                            >
                              <Delete fontSize="inherit" />
                            </IconButton>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </Col>
        </Row>
      </Container>

      <TechnicalAssessmentModalRemove
        assessment={technicalAssessment}
        isOpen={openedModalRemove}
        handleClose={(_) => {
          setTechnicalAssessment(defaultTechnicalAssessment);
          setAttachment(undefined);
          setOpenedModalRemove(false);
        }}
        handleRefresh={handleRefreshTechnicalAssessments}
        setLoading={setLoading}
        setError={() => {}}
      />
    </>
  );
};

export default TechnicalAssessment;
