const taskAuditStatusList = [
  {
    title: 'Aprovado',
    id: 'A'
  },
  {
    title: 'Reprovados',
    id: 'R'
  }
];

export { taskAuditStatusList };
