import './style.scss';
import React, { useContext } from 'react';

import { Avatar, Chip, IconButton } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { StoreContext } from '../Contexts/StoreContext';
import { UserContext } from '../Contexts/UserContext';

export default function StoreContainerTrigger({ onHeader = false }) {
  const { user } = useContext(UserContext);
  const { setStoreContainerOpened, storeAvatar, storeName } = useContext(StoreContext);

  const getClassNames = () => {
    let classNames = '';

    if (onHeader) {
      classNames += 'displaying-header';
    }

    if (user.profileId != 6) {
      classNames += ' is-not-store';
    }

    return classNames;
  };

  const getStoreInitialLetters = (name) => {
    const initialLetters = name.split(' ').reduce((acc, curr, index) => {
      return index < 2 ? acc + curr[0] : acc;
    }, '');
    return initialLetters;
  };

  return (
    <>
      {user.profileId != 6 && (
        <Chip
          icon={<ExpandMoreIcon className="color-white" />}
          label="Selecione o cliente"
          onClick={() => setStoreContainerOpened(true)}
          className={`iconbutton-storecontainertrigger  ${getClassNames()}`}
          size="small"
        />
      )}

      {user.profileId == 6 && (
        <IconButton
          onClick={() => setStoreContainerOpened(true)}
          className={`iconbutton-storecontainertrigger ${getClassNames()}`}>
          <Avatar src={storeAvatar} alt={storeName} className="default-avatar">
            {getStoreInitialLetters(storeName)}
          </Avatar>
        </IconButton>
      )}
    </>
  );
}
