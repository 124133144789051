import Axios from 'axios';
import BaseUrl from '../variables/api';

const List = async () => {
  return Axios.get(`${BaseUrl}/peb/categories`);
};

const CategoriesService = {
  List
};

export default CategoriesService;
