import React, { useRef } from 'react';
import {
  BarChart as BarChartRecharts,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  LabelList
} from 'recharts';
import PercentageChart from '../../../../components/Statistics/PercentageChart';

export default function BarChart({ data, className = '', height = '224px' }) {
  if (!data) {
    data = {
      maxvalue: 0,
      values: []
    };
  } else if (!data.values) {
    data.values = [];
  } else if (!data.maxvalue) {
    const values = data.values.map((x) => x.value) || [0];
    data.maxvalue = Math.max(0, ...values);
  }

  if (window.innerWidth < 600) {
    return (
      <>
        {data.values.map((d) => {
          return <PercentageChart data={d} title={d.name} />;
        })}
      </>
    );
  }

  return (
    <div
      className={`chart-results dashboard-canvas ${className}`}
      style={{
        height: height,
        minHeight: '224px'
      }}>
      <ResponsiveContainer width="100%" height="100%">
        <BarChartRecharts
          data={data.values}
          margin={{
            top: 20,
            right: 20,
            left: 0
          }}>
          <CartesianGrid strokeDasharray="5 5" />

          <XAxis
            dataKey="name"
            fontSize={10}
            height={60}
            interval={0}
            tick={{ width: 60 }}
            allowDataOverflow={true}
          />
          <YAxis domain={[0, data.maxvalue]} fontSize={11} />

          <Tooltip
            separator=" "
            labelStyle={{ fontSize: 14 }}
            formatter={(value, name, props) => [`(${props.payload.percentage}%)`, `${value}`]}
          />

          <Bar dataKey="value" fill="#f7911b" barSize={40} radius={[6, 6, 0, 0]}>
            <LabelList
              dataKey="percentage"
              position="center"
              fontSize={11}
              fill="#fff"
              formatter={(value) => `${value}%`}
            />
          </Bar>
        </BarChartRecharts>
      </ResponsiveContainer>
    </div>
  );
}
