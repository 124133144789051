import React from 'react';
import { Card, CardBody, CardHeader, Row } from 'reactstrap';
import PercentageChart from '../../../../../components/Statistics/PercentageChart';

export default function MobileFirstChart({ data }) {
  const { progress, chart } = data;

  return (
    <Card className="shadow borderradius-default">
      <CardHeader className="bg-transparent">
        <Row className="align-items-center">
          <div className="col">
            <h6 className="text-uppercase text-light ls-1 mb-1"> Business </h6>
            <h2 className="mb-0">Mobile first</h2>
          </div>
        </Row>
      </CardHeader>

      <CardBody className="p-0 pb-3">
        <PercentageChart
          data={progress && progress.adaptedCellphone}
          title="Adaptado para uso pelo celular"
        />
      </CardBody>
    </Card>
  );
}
