import React, { useEffect, useState } from 'react';
import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from 'reactstrap';
import axios from 'axios';
import baseURL from '../../variables/api';
import FormHelper from '../../helpers/FormHelper';

const SocialMediasModalAddEdit = ({
  socialMedia,
  categoriesList,
  isOpen,
  handleClose,
  handleRefresh,
  setLoading,
  setError
}) => {
  const [nome, setNome] = useState('');
  const [capa, setCapa] = useState(undefined);
  const [arquivo, setArquivo] = useState(undefined);
  const [categoria, setCategoria] = useState(0);
  const [categorias, setCategorias] = useState([]);

  useEffect(() => {
    if (isOpen) {
      setNome(socialMedia ? socialMedia.name : '');
      setCapa(undefined);
      setArquivo(undefined);
      setCategorias(socialMedia ? socialMedia.categories.map((c) => c.id) : []);
    }
  }, [isOpen]);

  const handleAddEditAction = (e) => {
    e.preventDefault();

    let formData = FormHelper.GenerateFormData(e, null);
    formData = FormHelper.IncludeKeyValue(formData, `categories`, JSON.stringify(categorias));

    setLoading(true);

    let method = 'post';
    let url = `${baseURL}/uptown/social-medias`;

    if (socialMedia) {
      method = 'put';
      url = `${baseURL}/uptown/social-medias/${socialMedia.id}`;
    }

    axios[method](url, formData, {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('banana')
      }
    })
      .then((resp) => {
        setError(
          socialMedia ? 'Alteração realizada com sucesso' : 'Inclusão realizada com sucesso',
          'success'
        );
        handleClose();
        handleRefresh();
      })
      .catch((err) => {
        setError('Ocorreu um erro ao tentar realizar a operação');
        console.log(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <Modal isOpen={isOpen} toggle={handleClose}>
        <form onSubmit={(e) => handleAddEditAction(e)}>
          <ModalHeader>
            <h3>{socialMedia ? 'Editar' : 'Adicionar'}</h3>
          </ModalHeader>

          <ModalBody className="pt-0">
            <Row>
              <Col className="col-12">
                <FormGroup>
                  <Label for="name">Nome:</Label>
                  <Input
                    type="text"
                    id="name"
                    name="name"
                    required
                    value={nome}
                    onChange={(e) => setNome(e.target.value)}
                  />
                </FormGroup>
              </Col>

              <Col className="col-12">
                <FormGroup>
                  <Label for="categories">Categorias:</Label>
                  <Input
                    type="select"
                    id="categories"
                    name="categories"
                    required
                    multiple
                    value={categorias}
                    onChange={(e) =>
                      setCategorias(Array.from(e.target.selectedOptions, (option) => +option.value))
                    }
                  >
                    {categoriesList.map((category, index) => {
                      return (
                        <option value={category.id} key={index}>
                          {category.descr_pt}
                        </option>
                      );
                    })}
                  </Input>
                </FormGroup>
              </Col>

              <Col className="col-12">
                <FormGroup>
                  <Label for="cover">Imagem de Capa:</Label>
                  <Input
                    type="file"
                    id="cover"
                    name="cover"
                    label={(capa && capa.name) || 'Escolha um arquivo'}
                    onChange={(e) => setCapa(e.target.files[0])}
                    required={!socialMedia}
                    accept="image/*"
                  />

                  {socialMedia && (
                    <Label
                      style={{
                        display: 'block',
                        width: '100%',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        fontSize: '12px',
                        marginTop: '8px'
                      }}
                    >
                      Atual:
                      <a href={socialMedia.cover} target="_blank" className="ml-1" rel="noreferrer">
                        {socialMedia.cover}
                      </a>
                    </Label>
                  )}
                </FormGroup>
              </Col>

              <Col className="col-12">
                <FormGroup>
                  <Label for="file">Arquivo:</Label>
                  <Input
                    type="file"
                    id="file"
                    name="file"
                    label={(arquivo && arquivo.name) || 'Escolha um arquivo'}
                    onChange={(e) => setArquivo(e.target.files[0])}
                    required={!socialMedia}
                  />

                  {socialMedia && (
                    <Label
                      style={{
                        display: 'block',
                        width: '100%',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        fontSize: '12px',
                        marginTop: '8px'
                      }}
                    >
                      Atual:
                      <a href={socialMedia.file} target="_blank" className="ml-1" rel="noreferrer">
                        {socialMedia.file}
                      </a>
                    </Label>
                  )}
                </FormGroup>
              </Col>
            </Row>
          </ModalBody>

          <ModalFooter
            style={{
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <Button className="background-primary color-white" onClick={handleClose}>
              Voltar
            </Button>

            <Button type="submit" className="background-secondary color-white">
              Salvar
            </Button>
          </ModalFooter>
        </form>
      </Modal>
    </>
  );
};

export default SocialMediasModalAddEdit;
